import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const { t } = useTranslation();

    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[0];
    const mainPathSplit = mainPath.split("/");

    // console.log('//////////---pathArray-----//// : ', pathArray)

    // const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
    const [openKeys, setOpenKeys] = useState(!topMenu ? [`${mainPathSplit.length > 1 || mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`] : []);
    const [selectedKeys, setSelectedKeys] = useState(!topMenu ? [`${pathArray[1] === "" || pathArray[2] === "" ? "dashboard" : pathArray[1]}`] : []);

    useEffect(() => {
        if (pathArray[1] === "" || pathArray[2] === "") {
            setSelectedKeys("dashboard");
        } else if (pathArray[1] === "users_list" || pathArray[2] === "users_list") {
            setSelectedKeys("users_list");
        } else if (pathArray[1] === "advertisement_list" || pathArray[2] === "advertisement_list") {
            setSelectedKeys("advertisement_list");
        } else if (pathArray[1] === "general_advertisement_list" || pathArray[2] === "general_advertisement_list") {
            setSelectedKeys("GeneralAdvertisement_list");
        } else if (pathArray[1] === "notification_list" || pathArray[2] === "notification_list") {
            setSelectedKeys("Notification-list");
        } else if (pathArray[1] === "event" || pathArray[2] === "event") {
            setSelectedKeys("Event");
        } else if (pathArray[1] === "occupation_master" || pathArray[2] === "occupation_master") {
            setSelectedKeys("Occupation-Master");
        } else if (pathArray[1] === "gotras" || pathArray[2] === "gotras") {
            setSelectedKeys("gotras");
        } else if (pathArray[1] === "blood_groups" || pathArray[2] === "blood_groups") {
            setSelectedKeys("blood-groups");
        } else if (pathArray[1] === "marital_status" || pathArray[2] === "marital_status") {
            setSelectedKeys("marital-status");
        } else if (pathArray[1] === "relationship" || pathArray[2] === "relationship") {
            setSelectedKeys("relationship");
        } else if (pathArray[1] === "prefix" || pathArray[2] === "prefix") {
            setSelectedKeys("prefix");
        } else if (pathArray[1] === "gender" || pathArray[2] === "gender") {
            setSelectedKeys("gender");
        } else if (pathArray[1] === "contact_us" || pathArray[2] === "contact_us") {
            setSelectedKeys("contact_us");
        } else if (pathArray[1] === "faq_list" || pathArray[2] === "faq_list") {
            setSelectedKeys("faq-list");
        } else if (pathArray[1] === "education" || pathArray[2] === "education") {
            setSelectedKeys("education");
        } else {
        }
    }, [location]);

    const onOpenChange = (keys) => {
        setOpenKeys([keys.length && keys[keys.length - 1]]);
    };
    const onClick = (item) => {
        setSelectedKeys(item.key);
        // if (item.keyPath.length === 1) setOpenKeys([]);
    };
    return (
        <Menu
            onOpenChange={onOpenChange}
            onClick={onClick}
            mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
            theme={darkMode && "dark"}
            // // eslint-disable-next-line no-nested-ternary
            defaultSelectedKeys={!topMenu ? [`${mainPathSplit.length === 1 ? "home" : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]}`] : []}
            defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`] : []}
            overflowedIndicator={<FeatherIcon icon="more-vertical" />}
            openKeys={openKeys}
            selectedKeys={selectedKeys}
        >
            <Menu.Item icon={!topMenu && <FeatherIcon icon="activity" color="blue" />} key="dashboard" onClick={() => history.push({ pathname: `${path}` })}>
                <NavLink onClick={toggleCollapsed} to={`${path}`}>
                    {t("Dashboard")}
                </NavLink>
            </Menu.Item>

            <Menu.Item
                className="active"
                key="users_list"
                icon={!topMenu && <FeatherIcon icon="users" color="green" />}
                onClick={() => history.push({ pathname: `${path}users_list` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}users_list`}>
                    {t("User_List")}
                </NavLink>
            </Menu.Item>

            <Menu.Item
                className="active"
                key="advertisement_list"
                icon={!topMenu && <FeatherIcon icon="tv" color="#f529cf" />}
                onClick={() => history.push({ pathname: `${path}advertisement_list` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}advertisement_list`}>
                    {t("Advertisement_List")}
                </NavLink>
            </Menu.Item>
            <Menu.Item
                className="active"
                key="GeneralAdvertisement_list"
                icon={!topMenu && <FeatherIcon icon="box" color="#05b3a7" />}
                onClick={() => history.push({ pathname: `${path}general_advertisement_list` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}general_advertisement_list`}>
                    {t("General_Advertisement")}
                </NavLink>
            </Menu.Item>

            <Menu.Item
                className="active"
                key="Event"
                icon={!topMenu && <FeatherIcon icon="gift" color="purple" />}
                onClick={() => history.push({ pathname: `${path}event` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}event`}>
                    {t("Event")}
                </NavLink>
            </Menu.Item>

            <Menu.Item
                className="active"
                key="gallery"
                icon={!topMenu && <FeatherIcon icon="image" color="blue" />}
                onClick={() => history.push({ pathname: `${path}gallery` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}gallery`}>
                    {t("Gallery")}
                </NavLink>
            </Menu.Item>

            <Menu.Item
                className="active"
                key="subscription"
                icon={!topMenu && <FeatherIcon icon="dollar-sign" color="#f529cf" />}
                onClick={() => history.push({ pathname: `${path}subscription` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}subscription`}>
                    {t("Subscription")}
                </NavLink>
            </Menu.Item>

            <Menu.SubMenu title={t("Master_Page")} icon={!topMenu && <FeatherIcon icon="bookmark" color="orange" />}>
                <Menu.Item key="gotras">
                    <NavLink onClick={toggleCollapsed} to={`${path}gotras`}>
                        {t("Gotras")}
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="blood-groups">
                    <NavLink onClick={toggleCollapsed} to={`${path}blood_groups`}>
                        {t("Blood_Group")}
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="marital-status">
                    <NavLink onClick={toggleCollapsed} to={`${path}marital_status`}>
                        {t("Marital_Status")}
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="relationship">
                    <NavLink onClick={toggleCollapsed} to={`${path}relationship`}>
                        {t("Relationship")}
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="relationship_combination">
                    <NavLink onClick={toggleCollapsed} to={`${path}relationship_combination`}>
                        {t("Relation_Combination")}
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="prefix">
                    <NavLink onClick={toggleCollapsed} to={`${path}prefix`}>
                        {t("Prefix")}
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="gender">
                    <NavLink onClick={toggleCollapsed} to={`${path}gender`}>
                        {t("Gender")}
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="education">
                    <NavLink onClick={toggleCollapsed} to={`${path}education`}>
                        {t("Education")}
                    </NavLink>
                </Menu.Item>

                <Menu.Item key="Occupation-Master">
                    <NavLink onClick={toggleCollapsed} to={`${path}occupation_master`}>
                        {t("Occupation_Master")}
                    </NavLink>
                </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item
                className="active"
                key="Notification-list"
                icon={!topMenu && <FeatherIcon icon="bell" color="red" />}
                onClick={() => history.push({ pathname: `${path}notification_list` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}notification_list`}>
                    {t("Notification_List")}
                </NavLink>
            </Menu.Item>

            <Menu.Item
                className="active"
                key="faq-list"
                icon={!topMenu && <FeatherIcon icon="help-circle" color="rgb(35, 216, 19)" />}
                onClick={() => history.push({ pathname: `${path}faq_list` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}faq_list`}>
                    {t("Faq_Master")}
                </NavLink>
            </Menu.Item>
            <Menu.Item
                key="contact_us"
                className="active"
                icon={!topMenu && <FeatherIcon icon="phone-call" color="#f529cf" />}
                onClick={() => history.push({ pathname: `${path}contact_us` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}contact_us`}>
                    {t("contact_us")}
                </NavLink>
            </Menu.Item>
            <Menu.Item
                key="message_status"
                className="active"
                icon={!topMenu && <FeatherIcon icon="mail" color="blue" />}
                onClick={() => history.push({ pathname: `${path}broadcast` })}
            >
                <NavLink onClick={toggleCollapsed} to={`${path}broadcast`}>
                    {t("Broadcast")}
                </NavLink>
            </Menu.Item>
        </Menu>
    );
};

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
    events: propTypes.object,
};

export default MenuItems;
