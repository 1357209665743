import Cookies from 'js-cookie';
import actions from './actions';
const { GET_EVENT, ADD_EVENT, UPDATE_EVENT, DELETE_EVENT } = actions;

const initState = {
    eventData: {},
    AddEvent: {}
};
const EventReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_EVENT:
            return {
                ...state,
                eventData: data,
            };
        case ADD_EVENT:
            return {
                ...state,
                AddEvent: data,
            };
        case UPDATE_EVENT:
            return {
                ...state,
                AddEvent: data,
            };
        case DELETE_EVENT:
            return {
                ...state,
                eventData: data,
            };

        default:
            return state;
    }
};
export default EventReducer;
