import Cookies from 'js-cookie';
import actions from './actions';

const { GLOBAL_API } = actions;

const initState = {
  global_api: true,
};

 export const GlobalReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    
    case GLOBAL_API:
      return {
        ...state,
        global_api: data,
      };
    default:
      return state;
  }
};
