import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Divider, Avatar, Row, Descriptions, Spin, Col, PageHeader, Empty } from "antd";
import { getDateFormate, getFileType } from "../../utility/commonFunction";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { get_Advertisement_details } from "../../redux/advertisementList/actionCreator";
import i18n from "i18next";
import FeatherIcon from "feather-icons-react";

function ViewGeneralAdvertisement() {
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const Push = (targetVal) => {
        history.push({
            pathname: `${path}/user_detail`,
            state: {
                id: targetVal,
            },
        });
    };

    const AdvertisementData = useSelector((state) => state.AdvertisementReducer.advertisement_detail);

    useEffect(() => {
        if (location && location.state && location.state.id !== undefined) GeneralAdvertisement(location.state.id);
    }, []);

    const GeneralAdvertisement = (id) => {
        dispatch(get_Advertisement_details(location.state.id));
    };

    const contentStyle = {
        height: "auto",
        width: "auto",
        color: "#fff",
        lineHeight: "160px",
        textAlign: "center",
    };


    return (
        <>
            {GeneralAdvertisement && GeneralAdvertisement.length > 0 ? (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="GeneralAdvViewPage">
                            <div className="viewAdvertisement">
                                <PageHeader
                                    ghost={false}
                                    className="site-page-header"
                                    title={(
                                        <div className="adv_pageTitle">
                                            <FeatherIcon
                                                icon="arrow-left-circle"
                                                size="28px"
                                                onClick={() => window.history.back()}
                                                style={{ cursor: "pointer", marginRight: "10px", }}
                                            />
                                            <span style={{ fontSize: "20px" }}>{t("View_Advertisement")}</span>
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="description_Container">
                                <Descriptions bordered column={1} labelStyle={{ width: "300px" }}>
                                    <Descriptions.Item label={t("UserName")}>
                                        {
                                            AdvertisementData?.static_info?.en?.name === "" ? (
                                                AdvertisementData && AdvertisementData.user_id !== null ? (
                                                    <p
                                                        className="userNameImg"
                                                        onClick={() => Push(AdvertisementData && AdvertisementData.user_id && AdvertisementData.user_id._id)}
                                                    >
                                                        {/* <Tooltip placement="top" title={t('view_profile')}> */}
                                                        {AdvertisementData && AdvertisementData.user_id && AdvertisementData.user_id.profile_photo !== "" ? (
                                                            <img
                                                                className="advUserAvatar"
                                                                height={35}
                                                                width={35}
                                                                crossOrigin="anonymous"
                                                                alt=""
                                                                src={`${AdvertisementData && AdvertisementData.user_id && AdvertisementData.user_id.profile_photo}`}
                                                                style={{
                                                                    borderRadius: 50,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                icon={<UserOutlined />}
                                                                style={{ backgroundColor: "rgb(184, 184, 177)" }}
                                                                className="advUserAvatar"
                                                            />
                                                        )}
                                                        {/* </Tooltip> */}

                                                        {AdvertisementData &&
                                                            AdvertisementData.user_id &&
                                                            AdvertisementData.user_id[i18n.language] &&
                                                            AdvertisementData.user_id[i18n.language].name + " " + AdvertisementData.user_id[i18n.language].surname}
                                                    </p>
                                                ) : (
                                                    "-"
                                                )
                                            ) : (
                                                "-"
                                            )}
                                    </Descriptions.Item>

                                    <Descriptions.Item label={t("Outside_User")}>
                                        {(AdvertisementData &&
                                            AdvertisementData.static_info &&
                                            AdvertisementData.static_info[i18n.language] &&
                                            AdvertisementData.static_info[i18n.language].name) === undefined
                                            ? "-"
                                            : AdvertisementData &&
                                            AdvertisementData.static_info &&
                                            AdvertisementData.static_info[i18n.language] &&
                                            AdvertisementData.static_info[i18n.language].name}
                                    </Descriptions.Item>

                                    <Descriptions.Item label={t("Advertisement_Description")}>
                                        {AdvertisementData && AdvertisementData[i18n.language] && AdvertisementData[i18n.language].description}
                                    </Descriptions.Item>

                                    <Descriptions.Item label={t("isPayment_Done")}>
                                        {AdvertisementData && AdvertisementData.isPaymentDone === true ? (
                                            <span
                                                style={{
                                                    borderWidth: "1px",
                                                    borderColor: "green",
                                                    borderStyle: "solid",
                                                    paddingRight: "16px",
                                                    paddingLeft: "16px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                    backgroundColor: "green",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    width: "fit-content",
                                                    // margin: 'auto'
                                                }}
                                                className="sentStyle"
                                            >
                                                {" "}
                                                {t("Yes")}{" "}
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    borderWidth: "1px",
                                                    borderColor: "red",
                                                    borderStyle: "solid",
                                                    paddingRight: "16px",
                                                    paddingLeft: "16px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                    backgroundColor: "red",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    width: "fit-content",
                                                    // margin: 'auto'
                                                }}
                                            >
                                                {" "}
                                                {t("No")}{" "}
                                            </span>
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Payment_Done_By")}>
                                        {(AdvertisementData &&
                                            AdvertisementData[i18n.language] &&
                                            AdvertisementData[i18n.language].paymentDoneBy === undefined) ||
                                            ""
                                            ? "-"
                                            : AdvertisementData && AdvertisementData[i18n.language] && AdvertisementData[i18n.language].paymentDoneBy}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Payment_Amount")} span={2}>
                                        {AdvertisementData && AdvertisementData.paymentAmount === "undefined"
                                            ? "-"
                                            : AdvertisementData && AdvertisementData.paymentAmount}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("advertisement_created_at")}>
                                        {AdvertisementData && getDateFormate(AdvertisementData.created_at)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Last_Updated_At")}>
                                        {AdvertisementData && getDateFormate(AdvertisementData.updated_at)}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>

                            <div className="advertisementBanner">
                                <Divider orientation="left">
                                    <h3>{t("Advertisement_Image")} </h3>
                                </Divider>

                                {AdvertisementData?.advertisementBanner != "" || AdvertisementData?.smallAdvertisementBanner != "" ? (
                                    getFileType(AdvertisementData?.advertisementBanner) === "png" ||
                                        getFileType(AdvertisementData?.smallAdvertisementBanner) === "png" ||
                                        getFileType(AdvertisementData?.advertisementBanner) === "jpg" ||
                                        getFileType(AdvertisementData?.advertisementBanner) === "jpeg" ||
                                        getFileType(AdvertisementData?.advertisementBanner) === "gif" ? (
                                        <>
                                            <div style={contentStyle}>
                                                {/* <h3 > */}
                                                {AdvertisementData &&
                                                    AdvertisementData?.advertisementBanner &&
                                                    AdvertisementData &&
                                                    AdvertisementData?.smallAdvertisementBanner ? (
                                                    <>
                                                        <img
                                                            src={AdvertisementData?.advertisementBanner}
                                                            className="advBannerImag"
                                                            crossOrigin="anonymous"
                                                        />
                                                        <img
                                                            src={AdvertisementData?.smallAdvertisementBanner}
                                                            className="advBannerImag"
                                                            crossOrigin="anonymous"
                                                        />
                                                    </>
                                                ) : AdvertisementData && AdvertisementData?.advertisementBanner ? (
                                                    <img
                                                        src={AdvertisementData && AdvertisementData?.advertisementBanner}
                                                        className="advBannerImag"
                                                        crossOrigin="anonymous"
                                                    />
                                                ) : (
                                                    <img
                                                        src={AdvertisementData && AdvertisementData?.smallAdvertisementBanner}
                                                        className="advBannerImag"
                                                        crossOrigin="anonymous"
                                                    />
                                                )}
                                                {/* </h3> */}
                                            </div>
                                        </>
                                    ) : getFileType(AdvertisementData?.advertisementBanner) === "pdf" ? (
                                        <>
                                            <div>
                                                <h3 style={contentStyle}>
                                                    {/* <a href={AdvertisementData && AdvertisementData?.advertisementBanner} className='advBannerImag'> open pdf to view </a> */}
                                                    <object
                                                        data={AdvertisementData && AdvertisementData?.advertisementBanner}
                                                        type="application/pdf"
                                                        width="80%"
                                                        height="700px"
                                                    ></object>
                                                </h3>
                                            </div>
                                        </>
                                    ) : getFileType(AdvertisementData?.advertisementBanner) === "mp3" ||
                                        getFileType(AdvertisementData?.advertisementBanner) === "mp4" ? (
                                        <>
                                            <div>
                                                <h3 style={contentStyle}>
                                                    <video
                                                        src={AdvertisementData && AdvertisementData?.advertisementBanner}
                                                        className="advBannerImag"
                                                        crossOrigin="anonymous"
                                                        controls
                                                    />
                                                </h3>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )
                                ) : (
                                    <div className="Advertisement_table">
                                        <Empty />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="spinnerName">
                            <Spin size="large"></Spin>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
}

export default ViewGeneralAdvertisement;
