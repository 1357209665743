import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getContact, addContact } = actions;

export const contact = () => {
    return async (dispatch) => {
        const getData = await DataService.get(API.contact.adminContactList);
        if (!getData.data.error) {
            dispatch(getContact(getData?.data));
            return true;
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};
export const Add_Contact = (payload) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.contact.addContact, payload);
        if (!resp.data.error) {
            return dispatch(addContact(resp.data.data));
            return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};
export const sendContactReply = (payloads) => {
    return async (dispatch) => {
        try {
            const response = await DataService.post(API.contact.sendMessageReply, payloads);
            if (!response.data.error) {
                return true;
            } else {
                message.error(response.data.message);
                return false;
            }
        } catch (err) {
            message.error(err);
            return false;
        }
    };
};
