import React, { useState, useEffect } from "react";
import "./style.css";
import { PageHeader, Input, Button, Row, Col, Popover, Table, Spin, Modal, Form, Divider, Pagination, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";
import i18n from "i18next";
import { getOccupationList, addNewOccupation, updateOccupationData, deleteOccupationData } from "../../redux/OccupationMaster/actionCreator";

const { TextArea } = Input;

function OccupationMaster() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState("Add New Occupation");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [visible, setVisible] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [occupationId, setOccupationId] = useState("");

    const occupationMasterListData = useSelector((state) => state.OccupationMasterReducer.OccupationMaster);
    const occupationMasterList = occupationMasterListData && occupationMasterListData?.data?.list;
    const occupationPagination = occupationMasterListData && occupationMasterListData?.data;

    useEffect(() => {
        OccupationMasterData(currentPage);
    }, [dataPerPage]);

    const OccupationMasterData = (page, pageNumber) => {
        let params = {
            page: page,
            limit: dataPerPage,
        };
        dispatch(getOccupationList(params));
    };

    const handlePagination = (page, pageNumber) => {
        OccupationMasterData(page, pageNumber);
        setCurrentPage(page);
        setDataPerPage(pageNumber);
    };

    const deleteOccupationMaster = async (values) => {
        let a = await dispatch(deleteOccupationData(values));
        if (a) {
            OccupationMasterData();
        }
        setOpenModal(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
        setModalTitle(t("Add_new_occupation"));
    };
    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const showEditModal = (e) => {
        console.log("occupation edit modal**-->:", e);
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Occupation"));
        form.setFieldsValue({
            hn_occupation: e.hn.occupation,
            en_occupation: e.en.occupation,
        });
    };

    const handleSubmit = async (val, i) => {
        setIsModalOpen(false);
        form.resetFields();

        const obj = {
            en: {},
            hn: {},
        };
        obj.en.occupation = val.en_occupation;
        obj.hn.occupation = val.hn_occupation;

        if (updateIdRef.current === "") {
            const AddFaq_Data = await dispatch(addNewOccupation(obj));
            if (AddFaq_Data) {
                OccupationMasterData();
                form.resetFields();
            }
        } else {
            const updateFaq_Data = await dispatch(updateOccupationData(obj, updateIdRef.current));
            if (updateFaq_Data) {
                OccupationMasterData(currentPage, dataPerPage);
                setupdateID("");
                form.resetFields();
            }
        }
    };

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    const columns = [
        {
            title: t("occupation_Name"),
            dataIndex: "occupation",
            align: "center",
            sorter: (a, b) => {
                return a.en.occupation.localeCompare(b.en.occupation);
            },
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].occupation}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, target) => {
                return (
                    <>
                        <Popover
                            placement="right"
                            content={
                                <>
                                    <FeatherIcon icon="edit" style={{ cursor: "pointer" }} size="20px" color="#5e6ff8" onClick={() => showEditModal(target)} />
                                    <Divider type="vertical" />

                                    <FeatherIcon
                                        style={{ cursor: "pointer" }}
                                        icon="trash"
                                        size="20px"
                                        color="red"
                                        onClick={() => {
                                            setOccupationId(target._id);
                                            setOpenModal(true);
                                        }}
                                    />

                                    <Divider type="vertical" />
                                </>
                            }
                        // trigger="click"
                        >
                            <FeatherIcon icon="more-vertical" size="20px" color="blue" onClick={() => setVisible()} />
                        </Popover>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)} occupationId={occupationId}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={require("./images.png")} style={{ height: "120px" }} />
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: "26px",
                            margin: "10px 0 -10px",
                        }}
                    >
                        {t("Are_you_sure?")}
                    </p>
                    <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                        <p style={{ marginBottom: "1rem" }}>{t("Do_you_really_want_to_delete_these_records_This_process_cannot_be_undone")}</p>
                    </div>
                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#60c7c1",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            {t("Cancel")}
                        </button>
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#f15e5e",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                deleteOccupationMaster(occupationId);
                            }}
                        >
                            {t("Delete")}
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Occupation_Master")}
                    extra={[
                        <>
                            <Button key="1" type="primary" onClick={showModal}>
                                <PlusOutlined />
                                {t("Add_new_occupation")}
                            </Button>
                        </>,
                    ]}
                />
            </div>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {occupationMasterList && occupationMasterList.length > 0 ? (
                        <>
                            <div className="occupation_table">
                                <Table
                                    showSorterTooltip={false}
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    columns={columns}
                                    dataSource={occupationMasterList}
                                    pagination={false}
                                />
                            </div>
                            <Row className="pagination_Layout" justify="center">
                                <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                    {occupationPagination && occupationPagination !== undefined ? (
                                        <Pagination
                                            total={occupationPagination && occupationPagination.total_counts}
                                            currentPage={currentPage}
                                            onChange={handlePagination}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            pageSize={dataPerPage}
                                            showSizeChanger
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </Col>
                            </Row>
                        </>
                    ) : Object.keys(occupationMasterListData)?.length == 0 || occupationMasterList?.length === 0 ? (
                        <div className="master_table">
                            <Table
                                scroll={{
                                    x: "3vh",
                                }}
                                locale={locale}
                                columns={columns}
                                dataSource={occupationMasterList}
                                pagination={false}
                            />
                        </div>
                    ) : (
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="spinnerName">
                                    <Spin size="large"></Spin>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>

            <div>
                <Modal
                    open={isModalOpen}
                    okText={t("Submit")}
                    width={550}
                    onOk={form.submit}
                    cancelText={t("Cancel")}
                    onCancel={handleCancel}
                    title={modalTitle}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} className="occupationMasterForm">
                                <h3>{t("occupation_Name")} :</h3>
                                <Form.Item
                                    label={t("English")}
                                    name="en_occupation"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[a-zA-Z 0-9./{}()-]+$/i),
                                            message: "This field Only accept English string value*!",
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_occupation_name")} size="small" />
                                </Form.Item>

                                <Form.Item
                                    label={t("Hindi")}
                                    name="hn_occupation"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[\u0900-\u097F ./{}()-]+$/i),
                                            message: "This field Only accept Hindi string value*!",
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_occupation_name")} size="small" />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            </div>
        </>
    );
}

export default OccupationMaster;
