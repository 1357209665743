import Cookies from 'js-cookie';
import actions from './actions';
const { GET_BLOODGROUP, ADD_BLOODGROUP, UPDATE_BLOODGROUP, DELETE_BLOODGROUP } = actions;

const initState = {
    bloodGroup: {},
    AddBloodgroup: {}
};
const BloodGroupReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_BLOODGROUP:
            return {
                ...state,
                bloodGroup: data,
            };
        case ADD_BLOODGROUP:
            return {
                ...state,
                AddBloodgroup: data,
            };
        case UPDATE_BLOODGROUP:
            return {
                ...state,
                AddBloodgroup: data,
            };
        case DELETE_BLOODGROUP:
            return {
                ...state,
                bloodGroup: data,
            };

        default:
            return state;
    }
};
export default BloodGroupReducer;
