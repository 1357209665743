import React from "react";
import "./style.css";
import useStateRef from "react-usestateref";
import { message, PageHeader } from "antd";
import { Table, Divider, Row, Col, Avatar, Pagination, Popover, Button, Select, Modal, Form, Input, Image, Radio, Spin, Empty, Switch } from "antd";
import { CloseOutlined, ExceptionOutlined, QuestionCircleOutlined, UserOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { generalAdvertisementListData, Add_Advertisement, Update_Advertisement, Delete_Advertisement } from "../../redux/advertisementList/actionCreator";
import { getUserStatusData } from "../../redux/AdminPannel/actionCreator";
import useDownloader from "react-use-downloader";
import { getFileType } from "../../utility/commonFunction";

const { Search } = Input;
const { TextArea } = Input;

const AdvertisementList = () => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState("Add General Advertisement");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [radioBtn, setRadioBtn] = useState("true");
    const [LargeSize, setLargeSize] = useState(null);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [visible, setVisible] = useState(false);
    const [previewMedia, setPreviewMedia] = useState([]);
    const [previewMediaSmall, setPreviewMediaSmall] = useState("");
    const [img, setImg] = useState([]);
    const [smallImg, setsmallImg] = useState("");
    const [radio_value, setRadio_value] = useState("general");
    const [showFields, setShowFields] = useState(false);
    const [largeHeight, setLargeHeight, updateLargeHeight] = useStateRef("");
    const [largewidth, setLargeWidth, updateLargeWidth] = useStateRef("");
    const [smallHeight, setSmallHeight, updateSmallHeight] = useStateRef("");
    const [smallWidth, setSmallWidth, updateSmallWidth] = useStateRef("");
    const [checked, setChecked] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [advertisementId, setAdvertisementId] = useState("");
    const [fileValue, setFileValue] = useState('');
    console.log("🚀 ~ file: GeneralAdvertisement.js:50 ~ AdvertisementList ~ fileValue:", fileValue)
    const { size, elapsed, percentage, download, cancel, error, isInProgress } = useDownloader();

    const Push = (targetId) => {
        history.push({
            pathname: `${path}/view_general_advertisement`,
            state: {
                id: targetId,
            },
        });
    };

    const GeneralAdvertisementListRes = useSelector((state) => state.AdvertisementReducer.generalAdvList);
    const GeneralAdvertisement = GeneralAdvertisementListRes && GeneralAdvertisementListRes.data;
    // console.log(" GeneralAdvertisement::", GeneralAdvertisement);
    const GeneralPagination = GeneralAdvertisementListRes && GeneralAdvertisementListRes.pagination;
    const userDetailsListData = useSelector((state) => state?.UsersStatusReducer?.userStatus?.data?.list);

    useEffect(() => {
        get_GeneralAdvertisementData(currentPage, searchValue);
        getAll_users_Data();
    }, [dataPerPage, checked]);

    const get_GeneralAdvertisementData = (page, pageNumber, search_Val) => {
        if (checked == true) {
            const search = search_Val === undefined ? "" : search_Val;
            let params = {
                english: search.match(/^[a-zA-Z ]+$/) ? search : "",
                hindi: search.match(/^[\u0900-\u097F ]+$/) ? search : "",
                page: page,
                type: "adv",
                limit: dataPerPage,
            };
            dispatch(generalAdvertisementListData(params));
        } else {
            const search = search_Val === undefined ? "" : search_Val;
            let params = {
                english: search.match(/^[a-zA-Z ]+$/) ? search : "",
                hindi: search.match(/^[\u0900-\u097F ]+$/) ? search : "",
                page: page,
                type: "user",
                limit: dataPerPage,
            };
            dispatch(generalAdvertisementListData(params));
        }
    };
    const getAll_users_Data = () => {
        let params = {
            english: "",
            hindi: "",
            page: "",
            limit: "",
            status: "",
        };
        dispatch(getUserStatusData(params));
    };
    const deleteGeneralADv = async (values) => {
        console.log("values", values);
        let a = await dispatch(Delete_Advertisement(values));
        if (a) {
            get_GeneralAdvertisementData();
        }
        setOpenModal(false);
    };

    // search advertisement by user name
    const onSearch = async (value) => {
        console.log("SearchValue", value.trim());
        if (value) {
            setSearchValue(value.trim());
            get_GeneralAdvertisementData("", "", value.trim());
        }
    };

    const searchClear = () => {
        get_GeneralAdvertisementData("");
        setSearchValue("");
    };

    // pagination
    const handlePagination = (page, pageNumber) => {
        get_GeneralAdvertisementData(page, pageNumber, "");
        setCurrentPage(page);
        setDataPerPage(pageNumber);
    };

    let images = [];
    const changeHandler = (e) => {
        setLargeSize(e.target.files[0].size);
        setImg(e.target.files[0]);
        setFileValue(e.target.files[0].name)

        if (
            (e.target.files.length !== 0 && e.target.files[0].type == "image/jpeg") ||
            e.target.files[0].type == "image/png" ||
            e.target.files[0].type == "image/jpg"
        ) {
            setPreviewMedia(
                new Array(e.target.files.length).fill(e.target.files).map((imgFile, i) => {
                    return <img src={URL.createObjectURL(imgFile[i])} width="70" height="70" className="addImg" name="advertisementBanner" />;
                })
            );
        }
    };

    let image = [];
    const changeHandlerSmallImg = (e) => {
        setsmallImg(e.target.files[0]);
        setLargeSize(e.target.files[0].size);
        setImg(e.target.files[0]);
        setFileValue(e.target.files[0].name)

        if (
            (e.target.files.length !== 0 && e.target.files[0].type == "image/jpeg") ||
            e.target.files[0].type == "image/png" ||
            e.target.files[0].type == "image/jpg"
        ) {
            setPreviewMediaSmall(
                new Array(e.target.files.length).fill(e.target.files).map((imgFile, i) => {
                    return <img src={URL.createObjectURL(imgFile[i])} width="70" height="70" className="addImg" name="smallAdvertisementBanner" />;
                })
            );
        }
    };

    const removeImg = () => {
        setPreviewMedia([]);
        setPreviewMediaSmall([]);
        setImg([]);
        setsmallImg([]);
        setFileValue('');
        form.resetFields(["smallImg"]);
    };

    const showModal = () => {
        setImg([]);
        setFileValue('')
        setsmallImg([]);
        setIsModalOpen(true);
        form.resetFields();
        setModalTitle(t("Add_General_Advertisement"));
        setPreviewMedia([]);
        setPreviewMediaSmall([]);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
        setPreviewMedia([]);
        setPreviewMediaSmall([]);
        setImg([]);
        setFileValue('')
        setsmallImg([]);
    };

    const showEditModal = (e) => {
        setupdateID(e._id);
        setIsModalOpen(true);
        setImg(e.advertisementBanner);
        setModalTitle(t("Edit_General_Advertisement"));
        var theSplit = e.advertisementBanner.split('/');
        const chooseImg = theSplit !== 0 && theSplit !== undefined && theSplit !== null ? theSplit && theSplit[5] : ''
        setFileValue(chooseImg);

        form.setFieldsValue({
            advertisementBanner:
                getFileType(e.advertisementBanner) == "png" || getFileType(e.advertisementBanner) == "jpg" || getFileType(e.advertisementBanner) == "jpeg"
                    ? e.advertisementBanner
                    : "",
            userName_Id: e.user_id !== undefined ? e.user_id._id : e && e.static_info && e.static_info[i18n.language] && e.static_info[i18n.language].name,
            en_title: e.en.title,
            hn_title: e.hn.title,
            en_description: e.en.description,
            hn_description: e.hn.description,
            en_paymentDoneBy: e.en.paymentDoneBy,
            hn_paymentDoneBy: e.hn.paymentDoneBy,
            paymentAmount: e.paymentAmount,
            type: e && e.type,
            isPaymentDone: e.isPaymentDone === true ? "true" : "false",
            smallImg: chooseImg,
        });

        e.advertisementBanner !== "" && getFileType(e.advertisementBanner) != "mp4" && getFileType(e.advertisementBanner) != "pdf"
            ? setPreviewMedia(<img src={e.advertisementBanner} width="70" height="70" className="addImg" name="advertisementBanner" />)
            : "-";
    };

    // console.log('defaultpayment.....>', defaultPayment)
    const handleSubmit = async (val, i) => {
        delete val.advertisementBanner;
        setIsModalOpen(false);

        form.resetFields();
        const form_data = new FormData();

        const type = "general";
        const obj = {
            en: {},
            hn: {},
        };
        (obj.en.title = val.en_title),
            (obj.hn.title = val.hn_title),
            (obj.en.description = val.en_description),
            (obj.hn.description = val.hn_description),
            (obj.en.paymentDoneBy = val.en_paymentDoneBy),
            (obj.hn.paymentDoneBy = val.hn_paymentDoneBy),
            (obj.paymentAmount = val.paymentAmount),
            (obj.isPaymentDone = val.isPaymentDone),
            (obj.type = type),
            (obj.advertisementBanner = val.advertisementBanner);
        console.log("(add/edit obj) ", obj);

        form_data.append("en", JSON.stringify(obj.en));
        form_data.append("hn", JSON.stringify(obj.hn));
        form_data.append("isPaymentDone", val.isPaymentDone);
        form_data.append("paymentAmount", val.paymentAmount);
        form_data.append("type", type);
        form_data.append("user_id", val.userName_Id !== undefined ? val.userName_Id : null);
        form_data.append("advertisementBanner", img);

        if (updateIdRef.current === "") {
            const AddGeneralAdv_Data = await dispatch(Add_Advertisement(form_data));
            if (AddGeneralAdv_Data) {
                get_GeneralAdvertisementData();
                form.resetFields();
                setImg([]);
            }
        } else {
            const updateGEneralAdv_Data = await dispatch(Update_Advertisement(form_data, updateIdRef.current));
            if (updateGEneralAdv_Data) {
                get_GeneralAdvertisementData(currentPage, dataPerPage);
                setupdateID("");
                form.resetFields();
                setImg([]);
            }
        }
    };

    const columns = [
        {
            title: t("Image"),
            dataIndex: "advertisementBanner",
            align: "center",
            render: (index, value) => {
                {
                    if (
                        value.advertisementBanner === "" ||
                        getFileType(value.advertisementBanner) == "pdf" ||
                        getFileType(value.advertisementBanner) == "mp4" ||
                        getFileType(value.advertisementBanner) == "mp3"
                    ) {
                        return (
                            <>
                                <Avatar
                                    icon={<ExceptionOutlined style={{ color: "#08c" }} />}
                                    style={{ backgroundColor: "rgb(235, 238, 241)" }}
                                    size="large"
                                    shape="square"
                                />
                            </>
                        );
                    } else {
                        return (
                            <Image
                                height={45}
                                width={45}
                                crossOrigin="anonymous"
                                alt=""
                                style={{
                                    borderRadius: 50,
                                }}
                                src={`${value.advertisementBanner}`}
                            />
                        );
                    }
                }
            },
        },

        {
            title: t("Name"),
            dataIndex: "name",
            align: "center",
            render: (icon, value) => {
                if ("user_id" in value) {
                    return (
                        value && value.user_id && value.user_id[i18n.language] && value.user_id[i18n.language].name + " " + value.user_id[i18n.language].surname
                    );
                } else {
                    return "-";
                }
            },
        },

        {
            title: t("Outside_User"),
            dataIndex: "name",
            align: "center",
            render: (icon, value) => {
                return value?.user_id?._id == ""
                    ? (value && value && value.static_info && value.static_info[i18n.language] && value.static_info[i18n.language].name) == undefined
                        ? "-"
                        : value && value && value.static_info && value.static_info[i18n.language] && value.static_info[i18n.language].name
                    : "-";
            },
        },
        {
            title: t("Advertisement_title"),
            dataIndex: "title",
            align: "center",
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].title}</>;
            },
        },

        {
            title: t("Payment"),
            dataIndex: "isPaymentDone",
            align: "center",
            render: (icon, v) => {
                // return v.isPaymentDone === true ? t("Yes") : t("No");
                return {
                    children: (
                        <span>
                            {v.isPaymentDone === true ? (
                                <span
                                    style={{
                                        borderWidth: "1px",
                                        borderColor: "green",
                                        borderStyle: "solid",
                                        paddingRight: "16px",
                                        paddingLeft: "16px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        backgroundColor: "green",
                                        borderRadius: "5px",
                                        color: "white",
                                        width: "fit-content",
                                        margin: 'auto'
                                    }}
                                    className="sentStyle"
                                >
                                    {" "}
                                    Yes{" "}
                                </span>
                            ) : (
                                <span
                                    style={{
                                        borderWidth: "1px",
                                        borderColor: "red",
                                        borderStyle: "solid",
                                        paddingRight: "16px",
                                        paddingLeft: "16px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        backgroundColor: "red",
                                        borderRadius: "5px",
                                        color: "white",
                                        width: "fit-content",
                                        margin: 'auto'
                                    }}
                                >
                                    {" "}
                                    No{" "}
                                </span>
                            )}
                        </span>
                    ),
                };
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, target) => {
                return (
                    <>
                        <div className="actionContentBox">
                            <FeatherIcon icon="more-vertical" size="20px" color="blue" onClick={() => setVisible()} style={{ cursor: "pointer" }} />
                            <div className="Adv_tableActionContent">
                                <FeatherIcon icon="edit" style={{ cursor: "pointer" }} size="20px" color="#5e6ff8" onClick={() => showEditModal(target)} />
                                <Divider type="vertical" />
                                <FeatherIcon
                                    style={{ cursor: "pointer" }}
                                    icon="trash"
                                    size="20px"
                                    color="red"
                                    onClick={() => {
                                        setAdvertisementId(target._id);
                                        setOpenModal(true);
                                    }}
                                />
                                <Divider type="vertical" />
                                <FeatherIcon style={{ cursor: "pointer" }} icon="eye" size="20px" color="orange" onClick={() => Push(target._id)} />
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    // type radio button

    const options = [
        {
            label: "General Advertisement",
            value: "general",
        },
        {
            label: "Personal Advertisement",
            value: "personal_ads",
        },
    ];

    const typeOnChange = ({ target: { value } }) => {
        setRadio_value(value);
    };

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    const paymentDone = (value) => {
        setRadioBtn(value.target.value);
    };

    const userSelector = (value) => {
        console.log("valueinselector", value);
    };

    return (
        <>
            <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)} advertisementId={advertisementId}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={require("./images.png")} style={{ height: "120px" }} />
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: "26px",
                            margin: "10px 0 -10px",
                        }}
                    >
                        Are you sure?
                    </p>
                    <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                        <p style={{ marginBottom: "1rem" }}>Do you really want to delete these records? This process cannot be undone.</p>
                    </div>
                    <div
                        style={{
                            border: "none",
                            justifyContent: "center!important",
                            textAlign: "center",
                            borderRadius: " 5px",
                            fontSize: "13px",
                            padding: " 10px 15px 25px",
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#60c7c1",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            style={{
                                cursor: " pointer",
                                background: "#c1c1c1",
                                color: "#fff",
                                borderRadius: " 4px",
                                background: "#f15e5e",
                                textDecoration: " none",
                                transition: "all 0.4s",
                                lineHeight: " normal",
                                minWidth: " 120px",
                                border: " none",
                                minHeight: "40px",
                                borderRadius: "3px",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                console.log("****");
                                deleteGeneralADv(advertisementId);
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    title={t("General_Advertisement")}
                    className="userlist_pageHeader"
                    extra={[
                        <>
                            <Search
                                size="small"
                                className="custom-search"
                                placeholder={t("Search")}
                                enterButton
                                onSearch={(e) => onSearch(e)}
                                allowClear={{
                                    clearIcon: <CloseOutlined onClick={searchClear} />,
                                }}
                            />
                            <div className="headerBtns">
                                <Switch
                                    className="advertisement_switch"
                                    checkedChildren="Adv"
                                    unCheckedChildren="User"
                                    checked={checked}
                                    onChange={setChecked}
                                    style={{ backgroundColor: "#5F63F2" }}
                                />
                                <Button key="1" type="primary" onClick={showModal}>
                                    <PlusOutlined />
                                    {t("Add_Advertisement")}
                                </Button>
                            </div>

                        </>,
                    ]}
                />
            </div>
            <div>
                <Modal
                    open={isModalOpen}
                    okText={t("Submit")}
                    width={550}
                    onOk={form.submit}
                    cancelText={t("Cancel")}
                    onCancel={handleCancel}
                    title={modalTitle}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} className="generalAddForm">
                                {/* <h4>{t('Image')}:</h4> */}
                                <Form.Item label={t("Upload")} valuePropName="fileList" name="smallImg">
                                    <Input
                                        size="small"
                                        type="file"
                                        title=" "
                                        onChange={changeHandler}
                                        id="advertisementImage"
                                        suffix={
                                            <CloseOutlined
                                                onClick={() => { removeImg(); }}
                                            />
                                        }
                                    />
                                    {previewMedia}{' '}{fileValue !== undefined && fileValue !== null &&
                                        fileValue !== '' ? (fileValue.length > 40 ? fileValue.slice(0, 40) + '...' + fileValue.split('.').pop() : fileValue) : ''}
                                </Form.Item>

                                {/* <Form.Item
                  label={t('Small_Size_Image_size_800_x_480')}
                  valuePropName="file"
                  name='smallAdvertisementBanner'
                >
                  <Input
                    size="small"
                    type="file"
                    title=""
                    accept=".png,.jpg.jpeg,.PNG,.JPG,.JPEG"
                    placeholder="choose image"
                    onChange={changeHandlerSmallImg}
                    suffix={<CloseOutlined onClick={removeImg} />}
                  />
                  {previewMediaSmall}
                </Form.Item> */}

                                {/* <Form.Item
                  label={t("User_Name")}
                  name="userName_Id" 
                >
                  <Select
                    mode="tag"
                    maxTagCount={1}
                    placeholder="Search and Select User by name"
                    name="_id"
                    showSearch
                    allowClear
                    placement="bottomRight"
                    style={{ width: "100%" }}
                    notFoundContent="This User is Not Exist"
                    filterOption={(input, option,value) => {
                      console.log("value in selector",value);
                      console.log('input result:', option);
                      return (
                        option.props.children[1]  
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {userDetailsListData && userDetailsListData.length > 0
                      ? userDetailsListData.map((data) => {
                        return (
                          <Select.Option key={data._id} value={data._id}>
                            {data.profile_photo !== "" ? (
                              <img
                                className="advUserAvatar"
                                height={30}
                                width={30}
                                crossOrigin="anonymous"
                                alt=""
                                src={data.profile_photo}
                                style={{
                                  borderRadius: 50,
                                }}
                              />
                            ) : (
                              <Avatar
                                icon={<UserOutlined />}
                                style={{
                                  backgroundColor: "rgb(184, 184, 177)",
                                }}
                                className="advUserAvatar"
                              />
                            )}
                            {`${data[i18n.language].name +
                              " " + 
                              data[i18n.language].surname}`}
                          </Select.Option>
                        );
                      })
                      : "-"}
                  </Select>
                </Form.Item> */}

                                <Form.Item label={t("UserName")} name="userName_Id">
                                    <Select
                                        mode="tags"
                                        placeholder="Search and Select User by name"
                                        name="_id"
                                        showSearch
                                        allowClear
                                        // filterOption={false}
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        onChange={(value, option) => {
                                            if (value.includes("custom")) {
                                                value = ["custom"];
                                            } else {
                                                if (value.length > 1) {
                                                    value = [value[0]];
                                                }
                                            }
                                            form.setFieldsValue({ userName_Id: value });
                                        }}
                                    >
                                        {/* {console.log("userlistdata>>>", userDetailsListData)} */}
                                        {userDetailsListData && userDetailsListData.length > 0
                                            ? userDetailsListData.map((data) => {
                                                return (
                                                    <Select.Option key={data._id} value={data._id}>
                                                        {data.profile_photo !== "" ? (
                                                            <img
                                                                className="advUserAvatar"
                                                                height={30}
                                                                width={30}
                                                                crossOrigin="anonymous"
                                                                alt=""
                                                                src={data.profile_photo}
                                                                style={{
                                                                    borderRadius: 50,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                icon={<UserOutlined />}
                                                                style={{
                                                                    backgroundColor: "rgb(184, 184, 177)",
                                                                }}
                                                                className="advUserAvatar"
                                                            />
                                                        )}
                                                        {`${data[i18n.language].name + " " + data[i18n.language].surname}`}
                                                    </Select.Option>
                                                );
                                            })
                                            : null}
                                        <Select.Option value="custom">{t("Custom")}</Select.Option>
                                    </Select>
                                </Form.Item>

                                {/* <Form.Item label={t("Advertisement_type")} name="type">
                  <Radio.Group
                    options={options}
                    onChange={typeOnChange}
                    value={radio_value}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item> */}

                                <h4>{t("Advertisement_Title")} :</h4>
                                <Form.Item>
                                    <Input.Group>
                                        <Form.Item
                                            label={t("english")}
                                            name="en_title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter Advertisement Title",
                                                },
                                                {
                                                    pattern: new RegExp(/^(?!\s)[a-zA-Z0-9 .,_?/{}()-]+$/i),
                                                    message: "Please enter valid title.",
                                                },
                                            ]}
                                        >
                                            <TextArea size="small" placeholder={t("Enter_Advertisement_title")} />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("hindi")}
                                            name="hn_title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter Advertisement Description",
                                                },
                                                {
                                                    pattern: new RegExp(/^(?!\s)[\u0900-\u097F0-9 .,_?/{}()-]+$/i),
                                                    message: "Please enter valid title.",
                                                },
                                            ]}
                                        >
                                            <TextArea rows={2} size="small" placeholder={t("Enter_Advertisement_title")} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>

                                <h4>{t("Advertisement_Description")} :</h4>
                                <Form.Item>
                                    <Input.Group>
                                        <Form.Item
                                            label={t("english")}
                                            name="en_description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter Advertisement Description",
                                                },
                                                {
                                                    pattern: new RegExp(/^(?!\s)[a-zA-Z0-9 .,_?/{}()-]+$/i),
                                                    message: "Please enter valid description.",
                                                },
                                            ]}
                                        >
                                            <TextArea size="small" placeholder={t("Enter_Advertisement_Description")} />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("hindi")}
                                            name="hn_description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter Advertisement Description",
                                                },
                                                {
                                                    pattern: new RegExp(/^(?!\s)[\u0900-\u097F0-9 .,_?/{}()-]+$/i),
                                                    message: "Please enter valid description.",
                                                },
                                            ]}
                                        >
                                            <TextArea rows={2} size="small" placeholder={t("Enter_Advertisement_Description")} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>

                                <Form.Item
                                    label={t("isPayment_Done")}
                                    name="isPaymentDone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Choose Any Option*!",
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={paymentDone}>
                                        <Radio value="true"> {t("Yes")} </Radio>
                                        <Radio value="false"> {t("No")} </Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {radioBtn == "true" ? (
                                    <>
                                        <h4>{t("Payment_Done_By")} :</h4>

                                        <Form.Item>
                                            <Input.Group>
                                                <Form.Item
                                                    label={t("english")}
                                                    name="en_paymentDoneBy"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please Enter Name*",
                                                //   },
                                                //   {
                                                //     pattern: new RegExp(/^[^\s].*([A-Za-z]+\s)*[A-Za-z]+$/),
                                                //     message: "Enter Name in English(should be More than 1 character)!",
                                                //   },
                                                // ]}
                                                >
                                                    <Input placeholder={t("Enter_Name")} size="small" />
                                                </Form.Item>

                                                <Form.Item
                                                    label={t("hindi")}
                                                    name="hn_paymentDoneBy"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please Enter Name*",
                                                //   },
                                                //   {
                                                //     pattern: new RegExp(/^[^\s].*([\u0900-\u097F]+\s)*[\u0900-\u097F]+$/),
                                                //     message: "Enter Name in Hindi(should be More than 1 character)!",
                                                //   },
                                                // ]}
                                                >
                                                    <Input placeholder={t("Enter_Name")} size="small" />
                                                </Form.Item>
                                            </Input.Group>
                                        </Form.Item>

                                        <Form.Item
                                            label={t("Payment_Amount")}
                                            name="paymentAmount"
                                        // rules={[
                                        //   {
                                        //     required: true,
                                        //     message: "Please Enter Payment Amount*!",
                                        //   },
                                        //   {
                                        //     pattern: new RegExp(/^[0-9,]+$/),
                                        //     message: "field only accept numbers(No space allowed)!!*)",
                                        //   },
                                        // ]}
                                        >
                                            <Input size="small" />
                                        </Form.Item>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {GeneralAdvertisement && GeneralAdvertisement.length > 0 ? (
                            <>
                                <div className="Advertisement_table">
                                    <Table
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={GeneralAdvertisement}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="paginationLayout" justify="center">
                                    <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                        {GeneralPagination && GeneralPagination !== undefined ? (
                                            <Pagination
                                                total={GeneralPagination && GeneralPagination.total_counts}
                                                currentPage={currentPage}
                                                onChange={handlePagination}
                                                pageSizeOptions={[10, 20, 50, 100]}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                pageSize={dataPerPage}
                                                showSizeChanger
                                            />
                                        ) : (
                                            "-"
                                        )}
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(GeneralAdvertisementListRes).length == 0 ||
                            (GeneralAdvertisement && GeneralAdvertisement.length === undefined) ||
                            GeneralAdvertisement == "" ? (
                            <div className="Advertisement_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={GeneralAdvertisement}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AdvertisementList;
