import React, { useState, useEffect } from "react";
import "./style.css";
import { PageHeader, Input, Button, Row, Col, Collapse, Popover, Modal, Form, Divider, Pagination } from "antd";
import { PlusOutlined, CaretRightOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";
import i18n from "i18next";
import { getFaqList, addNewFaq, updateFaqData, deleteFaqData } from "../../redux/FaqMaster/actionCreator";

const { TextArea } = Input;
const { Panel } = Collapse;

function FAQModule() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState("Add New FAQ");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [faqId, setFaqId] = useState("");

    const FaqList = useSelector((state) => state.FAQReducer.Faqs);
    const allFaqList = FaqList && FaqList.data?.list;


    const faqPagination = FaqList && FaqList.data && FaqList.data.total_counts;


    useEffect(() => {
        getFaqData(currentPage);
    }, [dataPerPage]);

    const getFaqData = (page, pageNumber) => {
        let params = {
            page: page,
            limit: dataPerPage,
        };
        dispatch(getFaqList(params));
    };

    const handlePagination = (page, pageNumber) => {
        getFaqData(page, pageNumber);
        setCurrentPage(page);
        setDataPerPage(pageNumber);
    };

    const deleteFaq = async (values) => {
        let a = await dispatch(deleteFaqData(values));
        if (a) {
            getFaqData();
        }
        setOpenModal(false);
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        form.resetFields();
        setModalTitle(t("Add_new_Faq"));
    };
    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const showEditModal = (e) => {
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Faq"));
        form.setFieldsValue({
            en_faq_question: e.en.faq_question,
            hn_faq_question: e.hn.faq_question,
            en_faq_answer: e.en.faq_answer,
            hn_faq_answer: e.hn.faq_answer,
        });
    };

    const handleSubmit = async (val, i) => {

        setIsModalOpen(false);
        form.resetFields();

        const obj = {
            en: {},
            hn: {},
        };
        obj.en.faq_answer = val.en_faq_answer;
        obj.hn.faq_answer = val.hn_faq_answer;
        obj.en.faq_question = val.en_faq_question;
        obj.hn.faq_question = val.hn_faq_question;

        if (updateIdRef.current === "") {
            const AddFaq_Data = await dispatch(addNewFaq(obj));
            if (AddFaq_Data) {
                getFaqData();
            }
        } else {
            const updateFaq_Data = await dispatch(updateFaqData(obj, updateIdRef.current));
            if (updateFaq_Data) {
                getFaqData();
                setupdateID("");
            }
        }
    };
    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Faq_Master")}
                    extra={[
                        <>
                            <Button key="1" type="primary" onClick={showModal}>
                                <PlusOutlined />
                                {t("Add_new_Faq")}
                            </Button>
                        </>,
                    ]}
                />
            </div>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="allFaqs">
                        {allFaqList &&
                            allFaqList.map((data, i) => {
                                return (
                                    <>
                                        <Collapse
                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            className="site-collapse-custom-collapse"
                                        >
                                            <Panel
                                                header={data && data[i18n.language] && data[i18n.language].faq_question}
                                                key="1"
                                                className="site-collapse-custom-panel"
                                                extra={
                                                    <Popover
                                                        placement="right"
                                                        content={
                                                            <>
                                                                <FeatherIcon
                                                                    icon="edit"
                                                                    style={{ cursor: "pointer" }}
                                                                    size="20px"
                                                                    color="#5e6ff8"
                                                                    onClick={() => showEditModal(data)}
                                                                />
                                                                <Divider type="vertical" />
                                                                <FeatherIcon
                                                                    style={{ cursor: "pointer" }}
                                                                    icon="trash"
                                                                    size="20px"
                                                                    color="red"
                                                                    onClick={() => {
                                                                        setFaqId(data._id);
                                                                        setOpenModal(true);
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                    // trigger="click"
                                                    >
                                                        <FeatherIcon icon="more-vertical" size="20px" color="blue" />
                                                    </Popover>
                                                }
                                            >
                                                <p>{data && data[i18n.language] && data[i18n.language].faq_answer}</p>
                                            </Panel>
                                        </Collapse>
                                    </>
                                );
                            })}
                    </div>
                </Col>
            </Row>
            <Row className="paginationLayout" justify="center">
                <Col xs={24} sm={24} md={24}>
                    {allFaqList && allFaqList.length > 0 ? (
                        <Pagination
                            total={faqPagination}
                            currentPage={currentPage}
                            onChange={handlePagination}
                            pageSizeOptions={[10, 20, 50, 100]}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            pageSize={dataPerPage}
                            showSizeChanger
                        />
                    ) : (
                        ""
                    )}
                </Col>
            </Row>
            <div>
                <Modal
                    open={isModalOpen}
                    okText={t("Submit")}
                    width={550}
                    onOk={form.submit}
                    cancelText={t("Cancel")}
                    onCancel={handleCancel}
                    title={modalTitle}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} className="FAQForm">
                                <h3>{t("Question")}:</h3>
                                <Form.Item
                                    label={t("English")}
                                    name="en_faq_question"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Please_Enter_Question_In_English"),
                                        },
                                        {
                                            pattern: new RegExp(/^[^\s].*([A-Za-z]+\s)*[A-Za-z ?]+$/),
                                            message: t("Please_Enter_Question_In_English"),
                                        },
                                    ]}
                                >
                                    <Input size="small" placeholder={t("Enter_Question")} />
                                </Form.Item>
                                <Form.Item
                                    label={t("Hindi")}
                                    name="hn_faq_question"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Please_Enter_Question_In_Hindi"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[\u0900-\u097F .?,|/{}()-]+$/i),
                                            message: t("Please_Enter_Question_In_Hindi"),
                                        },
                                    ]}
                                >
                                    <Input size="small" placeholder={t("Enter_Question")} />
                                </Form.Item>

                                <h3>{t("Answer")}:</h3>
                                <Form.Item
                                    label={t("English")}
                                    name="en_faq_answer"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Please_Enter_Answer_In_English"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[a-zA-Z .,/{}()-]+$/i),
                                            message: t("Please_Enter_Answer_In_English"),
                                        },
                                    ]}
                                >
                                    <TextArea size="middle" placeholder={t("Enter_Answer")} />
                                </Form.Item>
                                <Form.Item
                                    label={t("Hindi")}
                                    name="hn_faq_answer"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Please_Enter_Answer_In_Hindi"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[\u0900-\u097F .,|/{}()-]+$/i),
                                            message: t("Please_Enter_Answer_In_Hindi"),
                                        },
                                    ]}
                                >
                                    <TextArea size="middle" placeholder={t("Enter_Answer")} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
                <Modal footer={false} centered open={openModal} onCancel={() => setOpenModal(false)} faqId={faqId}>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <img src={require("./images.png")} style={{ height: "120px" }} />
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: "26px",
                                margin: "10px 0 -10px",
                            }}
                        >
                            {t("Are_you_sure?")}
                        </p>
                        <div style={{ color: "#999", padding: "1rem", marginTop: "8px" }}>
                            <p style={{ marginBottom: "1rem" }}>{t("Do_you_really_want_to_delete_these_records_This_process_cannot_be_undone")}</p>
                        </div>
                        <div
                            style={{
                                border: "none",
                                justifyContent: "center!important",
                                textAlign: "center",
                                borderRadius: " 5px",
                                fontSize: "13px",
                                padding: " 10px 15px 25px",
                            }}
                        >
                            <button
                                type="button"
                                style={{
                                    cursor: " pointer",
                                    background: "#c1c1c1",
                                    color: "#fff",
                                    borderRadius: " 4px",
                                    background: "#60c7c1",
                                    textDecoration: " none",
                                    transition: "all 0.4s",
                                    lineHeight: " normal",
                                    minWidth: " 120px",
                                    border: " none",
                                    minHeight: "40px",
                                    borderRadius: "3px",
                                    margin: "0 5px",
                                }}
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            >
                                {t("Cancel")}
                            </button>
                            <button
                                type="button"
                                style={{
                                    cursor: " pointer",
                                    background: "#c1c1c1",
                                    color: "#fff",
                                    borderRadius: " 4px",
                                    background: "#f15e5e",
                                    textDecoration: " none",
                                    transition: "all 0.4s",
                                    lineHeight: " normal",
                                    minWidth: " 120px",
                                    border: " none",
                                    minHeight: "40px",
                                    borderRadius: "3px",
                                    margin: "0 5px",
                                }}
                                onClick={() => {
                                    deleteFaq(faqId);
                                }}
                            >
                                {t("Delete")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default FAQModule;
