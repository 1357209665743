import React, { useEffect, useState } from "react";
import { PageHeader } from "antd";
import { useLocation } from "react-router-dom";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Row, Col, Avatar, List, Empty, Modal, Input, Checkbox, Pagination, Spin } from "antd";
import "./style.css";
import { UserOutlined, QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { getRandomColor, time_ago } from "../../utility/commonFunction";
import { getNotificationListData, deleteNotificationData, removeAllNotifications } from "../../redux/Notifications/actionCreator";
import { useDispatch, useSelector } from "react-redux";

const { Search } = Input;
const NotificationList = () => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [hideCheckbox, setHideCheckbox] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [check, setCheck] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const NotificationMasterList = useSelector((state) => state.NotificationReducer.notification);
    const NotificationData = NotificationMasterList && NotificationMasterList.data;
    const NotificationPagination = NotificationMasterList && NotificationMasterList.pagination && NotificationMasterList.pagination;

    useEffect(() => {
        allNotifications(searchValue, currentPage);
    }, [dataPerPage, searchValue]);

    const allNotifications = async (search, page, pageNumbe) => {
        if (searchValue == "") {
            let params = {
                search: searchValue,
                page: page,
                limit: dataPerPage,
            };
            dispatch(getNotificationListData(params));
        } else {
            let params = {
                search: searchValue,
                page: "",
                limit: "",
            };
            dispatch(getNotificationListData(params));
        }
    };
    const searchHandler = (search) => {
        setSearchData(search);
        allNotifications(search, "", "");
    };

    const handlePagination = (page, pageNumber) => {
        allNotifications("", page, pageNumber);
        setCurrentPage(page);
        setDataPerPage(pageNumber);
    };
    const confirmRemoveNotification = async (val) => {
        await Modal.confirm({
            title: "Are you sure you wants to delete this Notification ?",
            icon: <QuestionCircleOutlined style={{ fontSize: "30px", color: "#db4408" }} />,
            okText: "Yes",
            okType: "danger",
            onOk: () => {
                deleteNotification(val);
            },
        });
    };
    const deleteNotification = async (values) => {
        let a = await dispatch(deleteNotificationData(values));
        if (a) {
            allNotifications("", currentPage, dataPerPage);
        }
    };

    var notificationIdArr = [];
    NotificationData &&
        NotificationData.map((id) => {
            notificationIdArr.push(id._id);
        });

    const onCheckAllChange = (e) => {
        setHideCheckbox(true);
        setCheckAll(e.target.checked);
        setCheck(e.target.checked);
    };

    const confirmRemoveAll = async (ids) => {
        await Modal.confirm({
            title: "Are you sure you wants to Remove All Notifications of this Page?",
            icon: <QuestionCircleOutlined style={{ fontSize: "30px", color: "#db4408" }} />,
            okText: "Yes",
            okType: "danger",
            onOk: () => {
                removeAllNotificationData(ids);
            },
            onCancel: () => {
                setCheckAll(false);
                setCheck(false);
                setHideCheckbox(false);
            },
        });
    };
    const removeAllNotificationData = async (idsArr) => {
        let obj = {};
        obj.deletedArray = idsArr;
        let a = await dispatch(removeAllNotifications(obj));
        if (a) {
            allNotifications();
            setCheckAll(false);
            setCheck(false);
            setHideCheckbox(false);
        }
    };

    const onSearch = async (value) => {
        if (value) {
            setSearchValue(value.trim());
            getAdvertisementdata("", "", value.trim());
        }
    };

    const searchClear = () => {
        getAdvertisementdata("");
        setSearchValue();
    };

    let locale = {
        emptyText: (
            <>
                <Empty />;
            </>
        ),
    };

    const Push = (targetVal) => {
        history.push({
            pathname: `${path}/user_detail`,
            state: {
                id: targetVal,
            },
        });
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Notification")}
                    extra={[
                        <>
                            <Search
                                className="searchBox"
                                placeholder={t("search_by_title")}
                                enterButton
                                size="small"
                                onSearch={(e) => onSearch(e)}
                                onChange={(e) => setSearchValue(e.target.value)}
                                allowClear={{
                                    clearIcon: <CloseOutlined onClick={searchClear} />,
                                }}
                            />
                        </>,
                    ]}
                />
            </div>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    {NotificationData && NotificationData.length > 0 ? (
                        <>
                            <div className="removeCheckbox" style={{ textAlign: "right" }}>
                                <Checkbox
                                    className="checkboxBtn"
                                    onChange={onCheckAllChange}
                                    checked={checkAll}
                                    onClick={() => (check == false ? confirmRemoveAll(notificationIdArr) : "")}
                                >
                                    {t("Remove_all_Notifications")}
                                </Checkbox>
                            </div>
                            <List
                                className="ListContainer"
                                bordered={true}
                                itemLayout="horizontal"
                                dataSource={NotificationData}
                                size="small"
                                renderItem={(item) => {
                                    return (
                                        <List.Item
                                            className="listItems"
                                            actions={[
                                                <a key="list-Remove" onClick={() => confirmRemoveNotification(item._id)}>
                                                    Remove
                                                </a>,
                                                hideCheckbox == true ? <Checkbox checked={check}></Checkbox> : "",
                                            ]}
                                        >
                                            <List.Item.Meta
                                                className="listItemContent"
                                                avatar={<Avatar icon={<UserOutlined />} style={{ backgroundColor: getRandomColor() }} />}
                                                title={
                                                    <div className="listTitle">
                                                        <a>
                                                            <p onClick={() => Push(item && item.user_id && item.user_id._id)}>
                                                                {item &&
                                                                    item.user_id &&
                                                                    item.user_id[i18n.language] &&
                                                                    item.user_id[i18n.language].name + " " + item.user_id[i18n.language].surname}
                                                            </p>
                                                        </a>
                                                        <p>
                                                            <small>{item && time_ago(item.created_at)}</small>
                                                        </p>
                                                    </div>
                                                }
                                                description={
                                                    <div className="listDescription">
                                                        <h4>{item && item[i18n.language] && item[i18n.language].title}</h4>
                                                        <p>{item && item[i18n.language] && item[i18n.language].description}</p>
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    );
                                }}
                            />
                            <Row className="pagination_Layout" justify="center">
                                <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                    <Pagination
                                        total={NotificationPagination.total_counts}
                                        currentPage={currentPage}
                                        onChange={handlePagination}
                                        pageSizeOptions={[10, 20, 50, 100]}
                                        showTotal={(total, range) =>
                                            `${range[0]}-${range[1]} of ${total} items`
                                        }
                                        pageSize={dataPerPage}
                                        showSizeChanger
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : Object.keys(NotificationMasterList).length == 0 ||
                        (NotificationData && NotificationData.length === undefined) ||
                        (NotificationData && NotificationData == "") ? (
                        <List className="ListContainer" bordered={true} itemLayout="horizontal" dataSource={NotificationData} size="small" />
                    ) : (
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="spinnerName">
                                    <Spin size="large"></Spin>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default NotificationList;
