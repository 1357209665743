import React, { useEffect } from "react";
import i18n from "i18next";
import { Form, Select } from "antd";
import "./style.css";
import { setItem, getItem } from "../../../../utility/localStorageControl";

const LanguageSelection = () => {
    const LanguageOption = getItem("apiLanguageSelection");
    var data = {};

    useEffect(() => {
        handleMenuClick(LanguageOption);
        if (LanguageOption == "en") {
            (data.value = "en"), (data.label = "English");
        } else {
            (data.value = "hn"), (data.label = "Hindi");
        }
    }, []);

    const handleMenuClick = (e) => {
        setItem("apiLanguageSelection", e);
        i18n.changeLanguage(e);
    };

    return (
        <>
            <Form className="selectionBox" disabled={window.location.href.split("/").pop() == "contact_us" ? true : false}>
                <Select
                    showArrow
                    defaultValue={data}
                    style={{
                        width: 100,
                    }}
                    onChange={handleMenuClick}
                    options={[
                        {
                            value: "en",
                            label: "English",
                        },
                        {
                            value: "hn",
                            label: "Hindi",
                        },
                    ]}
                />
            </Form>
        </>
    );
};

export default LanguageSelection;
