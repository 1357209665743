import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getBroadcastList, getBroadcastDetail } = actions;

export const Get_Broadcast_List_Data = (params) => {
    let queMark = "?";
    let equalString = "=";
    let ampersand = "&";
    Object.keys(params).map((a) => {
        queMark += a + equalString + params[a] + ampersand;
    });

    return async (dispatch) => {
        const getData = await DataService.get(API.broadcast.getBroadcastList + queMark);
        if (!getData.data.error) {
            dispatch(getBroadcastList(getData?.data));
            return true;
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};
export const get_Broadcast_details = (payload, messagePayload) => {
    return async (dispatch) => {
        console.log("payload", payload);
        const broadcastDetailsResp = await DataService.post(API.broadcast.getBroadcastMessageDetails, { chatId: payload, message_id: messagePayload });
        console.log("broadcastDetailsResp", broadcastDetailsResp);
        if (!broadcastDetailsResp.data.error) {
            return dispatch(getBroadcastDetail(broadcastDetailsResp?.data?.data));
            return true;
        } else {
            message.error(broadcastDetailsResp.data.error);
            return false;
        }
    };
};
