import actions from './actions';
import { message } from 'antd';
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getRealtionship, addRealtionship, updateRealtionship, deleteRealtionship } = actions;


export const relationship = (params) => {
    let queMark = "?";
    let equalString = "=";

    Object.keys(params).map((a) => {
        queMark += a + equalString + params[a] + '&'
    })

    return async (dispatch) => {
        const getData = await DataService.get(API.relationShip.getRelationship + queMark);
        if (!getData.data.error) {
            dispatch(getRealtionship(getData?.data))
            return true
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};
export const add_Relationship = (payload) => {
    return async dispatch => {
        const resp = await DataService.post(API.relationShip.addRelationship, payload);
        if (!resp.data.error) {

            return dispatch(addRealtionship(resp.data.data))
            return true

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const update_Relationship = (payload, id) => {
    return async dispatch => {
        const resp = await DataService.put(API.relationShip.updateRelationship + id, payload);
        if (!resp.data.error) {

            return dispatch(updateRealtionship(resp.data.data))

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Delete_Relationship = (id) => {
    return async dispatch => {
        const resp = await DataService.delete(API.relationShip.deleteRelationship + id);
        if (!resp.data.error) {

            return dispatch(deleteRealtionship(true))

        } else {
            message.error(resp.data.data);
            return false;
        }

    };
};