import React, { useRef } from "react";
import useStateRef from "react-usestateref";
import { Table, Divider, Row, Col, Popover, Pagination, Button, Modal, Form, Input, Select, Image, PageHeader, Empty, Spin, Radio, Avatar, Switch } from "antd";
import { PlusOutlined, CloseOutlined, ExceptionOutlined, UserOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useRouteMatch, useHistory } from "react-router-dom";
import { advertisement, Add_Advertisement, Update_Advertisement, Delete_Advertisement } from "../../redux/advertisementList/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { getUserStatusData } from "../../redux/AdminPannel/actionCreator";
import { Add_Subscription_User_Data, Get_Subscribe_User_Data, Update_Subscription_User_Data } from "../../redux/subscription/actionCreator";
import { getDateFormate } from "../../utility/commonFunction";

const { Search } = Input;
const { TextArea } = Input;

const Subscription = () => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [img, setImg] = useState("");
    const [smallImg, setsmallImg] = useState("");
    const [modalTitle, setModalTitle] = useState("Add Subscribe User");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewMedia, setPreviewMedia] = useState("");
    const [previewMediaSmall, setPreviewMediaSmall] = useState("");
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [radioBtn, setRadioBtn] = useState("true");
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);

    const Push = (targetValue) => {
        history.push({
            pathname: `${path}/view_subscriptionDetails`,
            state: {
                id: targetValue,
            },
        });
    };

    useEffect(
        () => {
            getAll_users_Data();
            getSubscriptionUser(searchValue, currentPage);
        },
        [dataPerPage],
        []
    );
    const getSubscriptionUser = (searchKey, currentPage, pageNumber) => {
        const search_Val = searchKey === undefined || "" || null ? "" : searchKey;
        let params = {
            search: String(search_Val) ? search_Val : "",
            page: currentPage,
            limit: dataPerPage,
        };
        dispatch(Get_Subscribe_User_Data(params));
    };

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getSubscriptionUser(page);
        setDataPerPage(pageNumber);
    };

    const getSubscriptionUserData = useSelector((state) => state.SubscriptionReducer.subscriptionData);
    const userDetailsListData = useSelector((state) => state?.UsersStatusReducer?.userStatus?.data?.list);
    const getSubscriptionData = getSubscriptionUserData && getSubscriptionUserData.data?.list;
    const paginationData = getSubscriptionUserData && getSubscriptionUserData.data;

    // console.log("getSubscriptionData******", getSubscriptionData && getSubscriptionData[0] && getSubscriptionData[0].data);

    const getAll_users_Data = () => {
        let params = {
            english: "",
            hindi: "",
            page: "",
            limit: "",
        };
        dispatch(getUserStatusData(params));
    };

    // search data
    const onSearch = async (value) => {
        if (value) {
            setSearchValue(value.trim());
            getSubscriptionUser(value.trim(), "", "");
        }
    };

    const searchClear = () => {
        setSearchValue();
    };

    // table pagination

    const aRef = useRef(null);
    const resetInput = () => {
        aRef.current.value = null;
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        setPreviewMedia();
        setModalTitle(t("Add_Subscribe_User"));
        setPreviewMediaSmall();
    };

    const showEditModal = (e) => {
        e?.isPaymentDone == true ? setRadioBtn("true") : setRadioBtn("false");
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Subscribe_User"));
        form.setFieldsValue({
            _id: e?.user_id?._id,
            isPaymentDone: e?.isPaymentDone == true ? "true" : "false",
            paymentAmount: e?.subscriptionAmount,
            paymentDate: e?.paymentDate,
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const handleSubmit = async (value) => {
        const form_data = new FormData();
        setIsModalOpen(false);
        form.resetFields();

        form_data.append("isPaymentDone", value.isPaymentDone);
        if (radioBtn === "false") {
            form_data.append("paymentDate", "-");
            form_data.append("subscriptionAmount", "0");
        } else {
            form_data.append("paymentDate", value.paymentDate);
            form_data.append("subscriptionAmount", value.paymentAmount);
        }
        form_data.append("user_id", value._id);

        if (updateIdRef.current === "") {
            const SubscriptionData = await dispatch(Add_Subscription_User_Data(form_data));
            if (SubscriptionData) {
                getSubscriptionUser();
            }
        } else {
            const updateSubscriptionData = await dispatch(Update_Subscription_User_Data(form_data, updateIdRef.current));
            if (updateSubscriptionData) {
                getSubscriptionUser();
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("UserName"),
            dataIndex: ["user_id", "name"],
            align: "center",
            render: (icon, value) => {
                if ("user_id" in value) {
                    return value && value.user_id && value.user_id[i18n.language].name + " " + value.user_id[i18n.language].surname;
                } else {
                    return "-";
                }
            },
        },
        {
            title: t("Payment_date"),
            dataIndex: "payment_date",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.paymentDate == "-" ? "-" : getDateFormate(value.paymentDate)}</>;
            },
        },
        {
            title: t("Subscription_amount"),
            dataIndex: "subscription_amount",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.subscriptionAmount ? value.subscriptionAmount : "-"}</>;
            },
        },
        {
            title: t("isPayment_Done"),
            dataIndex: "isPaymentDone",
            align: "center",
            render: (icon, v) => {
                return {
                    children: (
                        <span>
                            {v.isPaymentDone === true ? (
                                <span
                                    style={{
                                        borderWidth: "1px",
                                        borderColor: "green",
                                        borderStyle: "solid",
                                        paddingRight: "16px",
                                        paddingLeft: "16px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        backgroundColor: "green",
                                        borderRadius: "5px",
                                        color: "white",
                                        width: "fit-content",
                                        margin: 'auto'
                                    }}
                                    className="sentStyle"
                                >
                                    {" "}
                                    Yes{" "}
                                </span>
                            ) : (
                                <span
                                    style={{
                                        borderWidth: "1px",
                                        borderColor: "red",
                                        borderStyle: "solid",
                                        paddingRight: "16px",
                                        paddingLeft: "16px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        backgroundColor: "red",
                                        borderRadius: "5px",
                                        color: "white",
                                        width: "fit-content",
                                        margin: 'auto'
                                    }}
                                >
                                    {" "}
                                    No{" "}
                                </span>
                            )}
                        </span>
                    ),
                };
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, tareget) => {
                return (
                    <>
                        <div className="actionContentBox">
                            <FeatherIcon icon="more-vertical" style={{ cursor: "pointer" }} size="20px" color="blue" onClick={() => setVisible()} />
                            <div className="subscription_tableAction">

                                <FeatherIcon
                                    style={{ cursor: "pointer" }}
                                    icon="edit"
                                    size="20px"
                                    color="#5e6ff8"
                                    onClick={() => {
                                        showEditModal(tareget), setIsModalOpen(true);
                                    }}
                                />
                                <Divider type="vertical" />
                                <FeatherIcon style={{ cursor: "pointer" }} icon="eye" size="20px" color="orange" onClick={() => Push(tareget._id)} />
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };
    const paymentAmount = (value) => {
        setRadioBtn(value.target.value);
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Subscription")}
                    extra={[
                        <>
                            <Search
                                className="searchBox"
                                placeholder={t("Search_by_User_name_or_date")}
                                enterButton
                                size="small"
                                onSearch={(e) => onSearch(e)}
                                allowClear={{
                                    clearIcon: <CloseOutlined onClick={searchClear} />,
                                }}
                            />
                            <Button key="1" type="primary" onClick={showModal}>
                                <PlusOutlined />
                                {t("Add")}
                            </Button>
                        </>,
                    ]}
                />
            </div>
            <div>
                <Modal
                    open={isModalOpen}
                    okText={t("Submit")}
                    width={550}
                    onOk={form.submit}
                    cancelText={t("Cancel")}
                    onCancel={handleCancel}
                    title={modalTitle}
                    className="advertisement-modal"
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} className="AddForm">
                                <Form.Item
                                    label={t("UserName")}
                                    name="_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Select User*!",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Search and Select User by name"
                                        name="_id"
                                        showSearch
                                        allowClear
                                        placement="bottomRight"
                                        style={{ width: "100%" }}
                                        notFoundContent="This User is Not Exist"
                                        filterOption={(input, option) => {
                                            return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                    >
                                        {userDetailsListData && userDetailsListData.length > 0
                                            ? userDetailsListData.map((data) => {
                                                return (
                                                    <Select.Option key={data._id} value={data._id}>
                                                        {data.profile_photo !== "" ? (
                                                            <img
                                                                className="advUserAvatar"
                                                                height={30}
                                                                width={30}
                                                                crossOrigin="anonymous"
                                                                alt=""
                                                                src={data.profile_photo}
                                                                style={{
                                                                    borderRadius: 50,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                icon={<UserOutlined />}
                                                                style={{
                                                                    backgroundColor: "rgb(184, 184, 177)",
                                                                }}
                                                                className="advUserAvatar"
                                                            />
                                                        )}
                                                        {`${data[i18n.language].name + " " + data[i18n.language].surname}`}
                                                    </Select.Option>
                                                );
                                            })
                                            : "-"}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={t("isPayment_Done")}
                                    name="isPaymentDone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Choose Any Option*!",
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={paymentAmount}>
                                        <Radio value="true"> {t("Yes")} </Radio>
                                        <Radio value="false"> {t("No")} </Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {radioBtn === "true" ? (
                                    <>
                                        <Form.Item
                                            label={t("Payment_Amount")}
                                            name="paymentAmount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter Payment Amount*!",
                                                },
                                                {
                                                    pattern: new RegExp(/^[0-9]+$/),
                                                    message: "field only accept numbers(No space allowed)!!*)",
                                                },
                                            ]}
                                        >
                                            <Input size="small" placeholder="Enter a payment amount" />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("Payment_date")}
                                            name="paymentDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Enter a subscription date",
                                                },
                                            ]}
                                        >
                                            <Input size="small" type="date" />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <Form.Item
                                            label={t("Payment_Amount")}
                                            name="paymentAmount"
                                            rules={[
                                                {
                                                    pattern: new RegExp(/^[0-9]+$/),
                                                    message: "field only accept numbers(No space allowed)!!*)",
                                                },
                                            ]}
                                        >
                                            <Input size="small" placeholder="Enter a payment amount" disabled />
                                        </Form.Item>
                                        <Form.Item label={t("Payment_date")} name="paymentDate">
                                            <Input size="small" type="date" disabled />
                                        </Form.Item>
                                    </>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getSubscriptionData && getSubscriptionData[0] && getSubscriptionData[0].data && getSubscriptionData[0].data.length > 0 ? (
                            <>
                                <div className="Advertisement_table">
                                    <Table
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getSubscriptionData && getSubscriptionData[0] && getSubscriptionData[0].data}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                        {console.log()}
                                        {paginationData && paginationData !== undefined ? (
                                            <Pagination
                                                total={paginationData && paginationData.total_counts}
                                                currentPage={currentPage}
                                                onChange={handlePagination}
                                                pageSizeOptions={[5, 10, 20, 50, 100]}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                pageSize={dataPerPage}
                                                showSizeChanger
                                            />
                                        ) : (
                                            "-"
                                        )}
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getSubscriptionUserData).length == 0 ||
                            (getSubscriptionData && getSubscriptionData[0] && getSubscriptionData[0].data && getSubscriptionData[0].data.length === undefined) ||
                            getSubscriptionData && getSubscriptionData[0] && getSubscriptionData[0].data == "" ? (
                            <div className="Advertisement_table">
                                {/* {console.log("getSubscriptionData", getSubscriptionData)} */}
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getSubscriptionData}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Subscription;
