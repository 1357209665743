import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Divider, Row, Descriptions, Col, Carousel, PageHeader, Image } from "antd";
import { getDateFormate } from "../../utility/commonFunction";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import FeatherIcon from "feather-icons-react";
import { get_Subscription_details } from "../../redux/subscription/actionCreator";

const ViewSubscriptionDetails = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const SubscriptionData = useSelector((state) => state.SubscriptionReducer.subscriptionData);

    useEffect(() => {
        if (location && location.state && location.state.id !== undefined) Subscription(location.state.id);
    }, []);

    const Subscription = (id) => {
        dispatch(get_Subscription_details(location.state.id));
    };

    return (
        <>
            {Subscription && Subscription.length > 0 ? (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="GeneralAdvViewPage">
                            <div className="viewAdvertisement">

                                <PageHeader
                                    ghost={false}
                                    className="site-page-header"
                                    title={(
                                        <div className="adv_pageTitle">
                                            <FeatherIcon
                                                icon="arrow-left-circle"
                                                size="28px"
                                                onClick={() => window.history.back()}
                                                style={{ cursor: "pointer", marginRight: "10px", }}
                                            />
                                            <span style={{ fontSize: "20px" }}>{t("Subscription_Details")}</span>
                                        </div>
                                    )}
                                />

                            </div>
                            <div className="description_Container">
                                <Descriptions bordered column={1} labelStyle={{ width: "300px" }}>
                                    <Descriptions.Item label={t("UserName")}>
                                        {SubscriptionData &&
                                            SubscriptionData.user_id &&
                                            SubscriptionData.user_id[i18n.language].name + " " + SubscriptionData.user_id[i18n.language].surname}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Subscription_amount")}>
                                        {SubscriptionData && SubscriptionData.subscriptionAmount}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Payment_date")}>
                                        {SubscriptionData && SubscriptionData.paymentDate == "-" ? "-" : getDateFormate(SubscriptionData.paymentDate)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("isPayment_Done")}>
                                        {SubscriptionData && SubscriptionData.isPaymentDone === true ? (
                                            <span
                                                style={{
                                                    borderWidth: "1px",
                                                    borderColor: "green",
                                                    borderStyle: "solid",
                                                    paddingRight: "16px",
                                                    paddingLeft: "16px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                    backgroundColor: "green",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    width: "fit-content",
                                                }}
                                                className="sentStyle"
                                            >
                                                {" "}
                                                {t("Yes")}{" "}
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    borderWidth: "1px",
                                                    borderColor: "red",
                                                    borderStyle: "solid",
                                                    paddingRight: "16px",
                                                    paddingLeft: "16px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                    backgroundColor: "red",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    width: "fit-content",

                                                }}
                                            >
                                                {" "}
                                                {t("No")}{" "}
                                            </span>
                                        )}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="spinnerName">
                            <Spin size="large"></Spin>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ViewSubscriptionDetails;
