import Cookies from 'js-cookie';
import actions from './actions';
const { GET_CONTACT, ADD_CONTACT } = actions;

const initState = {
    contact: [],
    AddContact: {}
};
const ContactReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_CONTACT:
            return {
                ...state,
                contact: data,
            };
        case ADD_CONTACT:
            return {
                ...state,
                AddContact: data,
            };
        default:
            return state;
    }
};
export default ContactReducer;