import Cookies from 'js-cookie';
import actions from './actions';
const { GET_NOTIFICATION_LIST,
    DELETE_NOTIFICATION ,DELETE_ALL_NOTIFICATIONS} = actions;

const initState = {
    notification: {},
    UpdatedList: {}
};
const NotificationReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_NOTIFICATION_LIST:
            return {
                ...state,
                notification: data,
            };
        case DELETE_NOTIFICATION:
            return {
                ...state,
                UpdatedList: data,
            };
            case DELETE_ALL_NOTIFICATIONS:
                return {
                    ...state,
                    UpdatedList: data,
                };
        default:
            return state;
    }
};
export default NotificationReducer;