import React from "react";
import useStateRef from "react-usestateref";
import { PageHeader } from "antd";
import { Table, Row, Col, Modal, Form, Input, Tooltip, Pagination, Empty, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./style.css";
import FeatherIcon from "feather-icons-react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { prefix, Add_Prefix, Update_Prefix, Delete_Prefix } from "../../redux/prefix/acrionCreator";
import { Get_Gender_Data } from "../../redux/gender/actionCreator";

const Prefix = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [modalTitle, setModalTitle] = useState("Add Prefix");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);

    const getDataPrefixList = useSelector((state) => state.PrefixReducer.prefix);
    const getdataPrefix = getDataPrefixList && getDataPrefixList?.data?.list;
    const PrefixPagination = getDataPrefixList && getDataPrefixList?.data;

    const getDataGenderList = useSelector((state) => state.GenderReducer.genderData.data);
    const getdataGender = getDataGenderList && getDataGenderList && getDataGenderList.list;

    useEffect(
        () => {
            getPrefixdata(currentPage);
            getGenderdata();
        },
        [dataPerPage],
        []
    );

    const getGenderdata = () => {
        let res = dispatch(Get_Gender_Data());
        if (res) {
            // console.log("res", res)
        }
    };

    const getPrefixdata = (currentPage, pageNumber) => {
        let params = {
            search: "",
            page: currentPage,
            limit: dataPerPage,
        };
        dispatch(prefix(params));
    };

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getPrefixdata(page);
        setDataPerPage(pageNumber);
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        setModalTitle(t("Add_Prefix"));
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };
    const showEditModal = (e) => {
        console.log("e>>>>", e);
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Prefix"));
        form.setFieldsValue({
            en_preFix: e.en.name,
            hn_preFix: e.hn.name,
            gender: e.gender_id ? e.gender_id._id : "",
        });
    };

    const deletePrefixData = async (values) => {
        let a = await dispatch(Delete_Prefix(values));
        console.log("values", values);
        if (a) {
            getPrefixdata();
        }
    };

    const handleSubmit = async (value) => {
        console.log("valueprefix", value);
        setIsModalOpen(false);
        form.resetFields();

        console.log("data", value);
        const obj = {
            en: {},
            hn: {},
        };

        obj.en.name = value.en_preFix;
        obj.hn.name = value.hn_preFix;
        obj.gender_id = value.gender;

        console.log("updateIDREFFFFFfffFf", updateIdRef.current);
        console.log("value.en_prefix : ", obj);
        if (updateIdRef.current === "") {
            const AddPrefixData = await dispatch(Add_Prefix(obj));
            if (AddPrefixData) {
                getPrefixdata();
            }
        } else {
            const obj = {
                en: {},
                hn: {},
            };
            obj.en.name = value.en_preFix;
            obj.hn.name = value.hn_preFix;
            obj.gender_id = value.gender;
            const updatePrefixData = await dispatch(Update_Prefix(obj, updateIdRef.current));
            if (updatePrefixData) {
                getPrefixdata(currentPage, dataPerPage);
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("Prefix"),
            dataIndex: "name",
            align: "center",
            sorter: (a, b) => {
                return a.en.name.localeCompare(b.en.name);
            },
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].name}</>;
            },
        },

        {
            title: t("Gender"),
            dataIndex: "gender",
            align: "center",
            render: (icon, value) => {
                return <>{value && value.gender_id && value.gender_id[i18n.language] && value.gender_id[i18n.language].name}</>;
            },
        },

        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, t) => {
                return (
                    <>
                        <Tooltip placement="right">
                            <FeatherIcon
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(t), setIsModalOpen(true);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Prefix")}
                    extra={
                        [
                            <Button key="1" type="primary" onClick={showModal}>
                                <PlusOutlined /> {t('Add_Prefix')}
                            </Button>,
                        ]
                    }
                />
            </div>
            <div>
                <Modal
                    open={isModalOpen}
                    okText={t("Submit")}
                    //  onOk={handleOk}
                    cancelText={t("Cancel")}
                    onCancel={handleCancel}
                    title={modalTitle}
                    onOk={form.submit}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                                <Form.Item
                                    label={t("Enter_Prefix")}
                                    name="en_preFix"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[a-zA-Z ./{}()-]+$/i),
                                            message: t("Field_Accept_Only_English_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Prefix")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("Enter_Prefix")}
                                    name="hn_preFix"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[\u0900-\u097F ./{}()-]+$/i),
                                            message: t("Field_Accept_Only_Hindi_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Prefix")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("Gender")}
                                    name="gender"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Please_Select_Gender"),
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Search and Select Gender"
                                        name="gender_id"
                                        showSearch
                                        allowClear
                                        placement="bottomRight"
                                        style={{ width: "100%" }}
                                        notFoundContent="This User is Not Exist"
                                        filterOption={(input, option) => {
                                            return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                    >
                                        {getdataGender && getdataGender.length > 0
                                            ? getdataGender.map((data) => {
                                                return (
                                                    <Select.Option key={data._id} value={data._id}>
                                                        {data[i18n.language].name !== "" ? "" : "--"}
                                                        {`${data[i18n.language].name}`}
                                                    </Select.Option>
                                                );
                                            })
                                            : "-"}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getdataPrefix && getdataPrefix.length > 0 ? (
                            <>
                                <div className="master_table">
                                    <Table
                                        showSorterTooltip={false}
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getdataPrefix}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24}>
                                        <Pagination
                                            total={PrefixPagination.total_counts}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            pageSize={dataPerPage}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            showSizeChanger
                                            currentPage={currentPage}
                                            onChange={handlePagination}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getDataPrefixList)?.length == 0 || getdataPrefix?.length === 0 ?
                            (
                                <div className="master_table">
                                    <Table
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        locale={locale}
                                        columns={columns}
                                        dataSource={getdataPrefix}
                                        pagination={false}
                                    />
                                </div>
                            ) : (
                                <div className="master_table">
                                    <Table
                                        showSorterTooltip={false}
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        locale={locale}
                                        columns={columns}
                                        dataSource={getdataPrefix}
                                        pagination={false}
                                    />
                                </div>
                            )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Prefix;
