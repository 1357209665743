import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Avatar, PageHeader, } from 'antd';
import { get_users_details } from '../../../redux/AdminPannel/actionCreator';
import { putUserDetailsUpdate } from '../../../redux/AdminPannel/actionCreator';
import { getItem } from '../../../utility/localStorageControl';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';

const Profile = () => {
  const adminId = getItem("user_details");
  const language = getItem("apiLanguageSelection");

  const { t } = useTranslation();
  const [img, setImg] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm(); // Use the useForm hook to get the form instance

  const adminData = useSelector((state) => state?.UsersStatusReducer?.getUserDetail);

  useEffect(() => {
    AdminDetailsList(adminId?.id);
  }, []);

  const AdminDetailsList = async (id) => {
    await dispatch(get_users_details(adminId?._id));
  }

  useEffect(() => {
    if (adminData) {
      // Use setFieldsValue instead of setFieldValue
      form.setFieldsValue({
        profile_photo: adminData?.profile_photo,
        enName: adminData?.en?.name,
        hnName: adminData?.hn?.name,
        email: adminData?.email
      });
    }
  }, [adminData, form]);

  const handleSubmit = async (value) => {
    // console.log("valueinProfile", value);
    // console.log("valuePresentInObject",value.hasOwnProperty('enName'));
    delete value.image;
    // console.log("img-------------->>>>", img);

    const form_data = new FormData();
    // setIsModalOpen(false);
    // form.resetFields();
    // console.log("value>>>>>", value);

    const obj = {
      en: {},
      hn: {},
    };
    (value.hasOwnProperty('enName') == true) ?
      (obj.en.name = value.enName) :
      (obj.hn.name = value.hnName)
    console.log("obj", obj);

    (value.hasOwnProperty('enName') == true) ?
      form_data.append("en", JSON.stringify(obj.en)) :
      form_data.append("hn", JSON.stringify(obj.hn));
    form_data.append("email", value.email)


    if (img !== '') {
      form_data.append('profile_photo', img)
    }

    for (const pair of form_data.entries()) {
      // console.log("pair for form_data : ", pair);
    }
    console.log("obj: ", obj);

    const updateAdminData = await dispatch(putUserDetailsUpdate(form_data, adminId?._id));
    // if (updateAdminData) {
    //   getEventData();
    //   setupdateID("");
    // }

  }

  const changeHandler = (e) => {
    console.log("sdfsdfs", e.target.files[0]);
    // console.log("e.target.files/////", e.target.files);
    // console.log("e image object", e.target.files[0].type );
    setImg(e.target.files[0]);

    if (e.target.files.length !== 0 && e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/jpg') {
      setPreviewMedia(
        new Array(e.target.files.length)
          .fill(e.target.files)
          .map((imgFile, i) => {
            return (
              <img
                src={URL.createObjectURL(imgFile[i])}
                width="70"
                height="70"
                className="addImg"
                name="profile_photo"
              />
            );
          })
      );
    }
  };

  const removeImg = () => {
    setPreviewMedia([]);
    setImg([]);
  };

  return (
    <div>
      <div className='site-page-header-ghost-wrapper'>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          className="site-page-header"
          title={t('Admin_Details')}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", backgroundColor: "white", padding: '20px', marginRight: '20px', marginLeft: '20px', marginBottom: '20px' }}>
        <Form
          onFinish={handleSubmit}
          form={form}
          name="basic"
          autoComplete="off"
          requiredMark={"optional"}
          style={{ width: "40%" }}
          layout="vertical"
        >

          <Form.Item name="profile_photo">
            <p style={{ fontSize: "15px", fontWeight: "700" }}>
              {t('Click_On_Image_To_Upload')}
            </p>

            <div
              className="profile-pic"
              style={{
                justifyContent: "center",
                display: "flex",
                width: "auto",
              }}
            >
              <label htmlFor="input-file" >
                {(
                  <>
                    {" "}
                    {("#") &
                      ("#") ? (
                      <Avatar
                        size={100}
                        icon={
                          <img
                            src={"#"}
                            style={{
                              height: "auto",
                              paddingTop: "13px",
                            }}
                          />
                        }
                      />
                    ) : (
                      <Avatar
                        size={100}
                        icon={
                          <img
                            src={adminData?.profile_photo}
                            crossOrigin="anonymous"
                          />
                        }
                      ></Avatar>
                    )}
                  </>

                )}
              </label>
              <Input
                style={{ display: "none" }}
                type="file"
                onChange={changeHandler}
                accept="image/jpeg,image/png,"
                id="input-file"
                name='profile_photo'
                aria-label="Upload File"
              />
            </div>
          </Form.Item>

          {
            (language == 'en') ?
              <>
                <Form.Item
                  name="enName"
                  label={t('Name')}
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ]}

                >
                  <Input
                    style={{ background: "white", fontWeight: "700" }}
                  />
                </Form.Item>
              </>
              :

              <>
                <Form.Item
                  name="hnName"
                  label={t("Name")}
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ]}

                >
                  <Input
                    style={{ background: "white", fontWeight: "700" }}
                  // value={name}
                  />
                </Form.Item>
              </>

          }



          <Form.Item
            name="email"
            label={t("Email")}
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}

          >
            <Input
              style={{ background: "white", fontWeight: "700" }}
            // value={name} 
            />
          </Form.Item>



          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "40px" }}>
              {t('Submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
