import actions from './actions';
import { message } from 'antd';
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const { getGotra, addGotra, updateGotra, deleteGotra } = actions;


export const Get_Gotra_Data = (params) => {
    let queMark = "?";
    let equalString = "=";
    let ampersand = "&";
    Object.keys(params).map((a) => {
        queMark += a + equalString + params[a] + ampersand
    })

    return async (dispatch) => {
        const getData = await DataService.get(API.Gotra.Get_Gotra + queMark);
        if (!getData.data.error) {
            dispatch(getGotra(getData?.data))
            return true
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};

export const Add_Gotra_Data = (payload) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.Gotra.Add_Gotra, payload);
        if (!resp.data.error) {
            dispatch(addGotra(resp.data.data))
            return true

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Update_Gotra_data = (payload, id) => {
    return async (dispatch) => {
        const resp = await DataService.put(API.Gotra.Update_Gotra + id, payload);
        if (!resp.data.error) {
            dispatch(updateGotra(resp.data.data))
            return true

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Delete_Gotra_data = (id) => {
    return async dispatch => {
        const resp = await DataService.delete(API.Gotra.Delete_Gotra + id);
        if (!resp.data.error) {
            return dispatch(deleteGotra(true))
        } else {
            message.error(resp.data.data);
            return false;
        }

    };
};

