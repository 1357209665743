import actions from './actions';
import { message } from 'antd';
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getBloodGroup, addBloodGroup, updateBloodGroup, deleteBloodGroup } = actions;


export const bloodGroup = (params) => {
    let queMark = "?";
    let equalString = "=";
    let ampersand = "&";

    Object.keys(params).map((a) => {
        queMark += a + equalString + params[a] + ampersand
    })
    return async (dispatch) => {
        const getData = await DataService.get(API.bloodGroup.getBloodGroup + queMark);
        if (!getData.data.error) {
            dispatch(getBloodGroup(getData?.data))
            return true
        } else {
            message.error(getData.data.message);
            return false;
        }

    };
};
export const Add_Blood_Group = (payload) => {
    return async dispatch => {
        const resp = await DataService.post(API.bloodGroup.addBloodGroup, payload);
        if (!resp.data.error) {

            return dispatch(addBloodGroup(resp.data.data))
            return true

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Update_Blood_Group = (payload, id) => {
    return async dispatch => {
        const resp = await DataService.put(API.bloodGroup.updateBloodGroup + id, payload);
        if (!resp.data.error) {

            return dispatch(updateBloodGroup(resp.data.data))
            return true

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Delete_Blood_Group = (id) => {
    return async dispatch => {
        const resp = await DataService.delete(API.bloodGroup.deleteBloodGroup + id);
        if (!resp.data.error) {

            return dispatch(deleteBloodGroup(true))

        } else {
            message.error(resp.data.data);
            return false;
        }

    };
};

