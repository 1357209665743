import React, { useState, useEffect } from "react";
import "./style.css";
import FeatherIcon from "feather-icons-react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Switch, Divider, Avatar, Row, Descriptions, Image, Table, Col, Checkbox, Form, Input, Select, Carousel, PageHeader, Tabs, Modal } from "antd";
import { getRandomColor, getDateFormate } from "../../utility/commonFunction";
import { SearchOutlined, UserOutlined, CaretDownFilled, CaretUpFilled, FileUnknownTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { get_users_details } from "../../redux/AdminPannel/actionCreator";
import i18n from "i18next";
import { useLocation } from "react-router-dom";
import { setItem } from "../../utility/localStorageControl";

function UserDetail(prop) {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [left_form] = Form.useForm();
    const [showMore, setShowMore] = useState(100);
    const [arrowIcon, setArrowIcon] = useState(true);

    const User_data1 = useSelector((state) => state?.UsersStatusReducer?.getUserDetail);
    const userOccupation = User_data1.occupation;
    const userStatus = prop?.location?.state?.status;
    const [imgModalOpen, setImgModalOpen] = useState(false);
    const [previewMedia, setPreviewMedia] = useState([]);

    useEffect(() => {
        if (location && location.state && location.state.id !== undefined) {
            UserDetailsList(location.state.id);
        }
    }, []);

    const UserDetailsList = async (id) => {
        await dispatch(get_users_details(id));
    };

    const [windowWidth, setWindowWidth] = useState(
        window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );

    useEffect(() => {
        setWindowWidth(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);
    }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth])

    const moreArrowFunction = () => {
        setArrowIcon(!arrowIcon);
    };

    const onBack = () => {
        history.push({ pathname: `/users_list` });
        setItem("User_status", userStatus);
    };
    const onChange = (key) => {
        console.log(key);
    };


    const showImagePreview = (img) => {
        setImgModalOpen(true);

        User_data1.profile_photo !== "" && User_data1.profile_photo !== undefined && User_data1.profile_photo !== null
            ? setPreviewMedia(
                <div className="userModalImag">
                    <img
                        crossOrigin="anonymous"
                        alt=""
                        src={User_data1.profile_photo}
                        style={{
                            objectFit: 'cover',
                            height: windowWidth > 992 ? '450px' : windowWidth < 992 && windowWidth > 575 ? '350px' : windowWidth < 575 && windowWidth > 399 ? '300px' : windowWidth < 399 ? '270px' : '100%',
                            width: windowWidth > 992 ? '450px' : windowWidth < 992 && windowWidth > 575 ? '350px' : windowWidth < 575 && windowWidth > 399 ? '300px' : windowWidth < 399 ? '270px' : '450px'
                        }}
                    />
                </div>
            )
            : "No Image";

    };


    const items = [
        {
            key: "1",
            label: t("Personal_Details"),
            children: (
                <>
                    <div>
                        <Row style={{ marginLeft: "29px " }}>
                            <Col span={12}>
                                <div>
                                    <label>{t("Name")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {User_data1 && User_data1[i18n.language] && User_data1[i18n.language].name == ""
                                            ? "-"
                                            : User_data1 &&
                                            User_data1[i18n.language] &&
                                            User_data1[i18n.language].name + " " + User_data1[i18n.language].surname}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Gender")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {User_data1 &&
                                            User_data1.gender_id &&
                                            User_data1.gender_id[i18n.language] &&
                                            User_data1.gender_id[i18n.language].name != ""
                                            ? User_data1.gender_id[i18n.language].name
                                            : "-"}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Birth_Date")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {(User_data1 && User_data1.date_of_birth == undefined) || ""
                                            ? "-"
                                            : getDateFormate(User_data1 && User_data1.date_of_birth)}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Marital_Status")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {User_data1 &&
                                            User_data1.marital_status_id &&
                                            User_data1.marital_status_id[i18n.language] &&
                                            User_data1.marital_status_id[i18n.language].marital_status == "undefined"
                                            ? ""
                                            : User_data1 &&
                                            User_data1.marital_status_id &&
                                            User_data1.marital_status_id[i18n.language] &&
                                            User_data1.marital_status_id[i18n.language].marital_status}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Blood_Group")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {User_data1 &&
                                            User_data1.blood_group_id &&
                                            User_data1.blood_group_id[i18n.language] &&
                                            User_data1.blood_group_id[i18n.language].blood_group == "undefined"
                                            ? ""
                                            : User_data1 &&
                                            User_data1.blood_group_id &&
                                            User_data1.blood_group_id[i18n.language] &&
                                            User_data1.blood_group_id[i18n.language].blood_group}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Gotras")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {User_data1 &&
                                            User_data1.gotra_master_id &&
                                            User_data1.gotra_master_id[i18n.language] &&
                                            User_data1.gotra_master_id[i18n.language].name == "undefined"
                                            ? ""
                                            : User_data1 &&
                                            User_data1.gotra_master_id &&
                                            User_data1.gotra_master_id[i18n.language] &&
                                            User_data1.gotra_master_id[i18n.language].name}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Hobbies")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p>-</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>
            ),
        },
        {
            key: "2",
            label: t("Relation"),
            children: (
                <>
                    <div>
                        <div>
                            {User_data1 && User_data1.userRelationData && User_data1.userRelationData.length > 0 ? (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Descriptions style={{ marginLeft: "29px" }}>
                                                {new Array(User_data1 && User_data1.userRelationData && User_data1.userRelationData.length)
                                                    .fill(User_data1 && User_data1.userRelationData)
                                                    .map((val, index) => {
                                                        if (Object.keys(val[index]).includes("relation_user_id") === true) {
                                                            return (
                                                                // <Descriptions.Item
                                                                //   label={
                                                                //     val[index].relation_master_id[i18n.language]
                                                                //       .name +
                                                                //     " " +
                                                                //     t("Name")
                                                                //   }
                                                                //   style={{
                                                                //     backgroundColor: "white",
                                                                //     border: "none",
                                                                //     borderBottom: "white",
                                                                //   }}
                                                                // >

                                                                //   <p style={{ color: "blue" }}>
                                                                //     {val &&
                                                                //     val[index] &&
                                                                //     val[index] &&
                                                                //     val[index].relation_user_id &&
                                                                //     val[index].relation_user_id[
                                                                //       i18n.language
                                                                //     ] &&
                                                                //     val[index].relation_user_id[i18n.language]
                                                                //       .name !== ""
                                                                //       ? val &&
                                                                //         val[index] &&
                                                                //         val[index].prefix_id &&
                                                                //         val[index].prefix_id[i18n.language] &&
                                                                //         val[index].prefix_id[i18n.language]
                                                                //           .name +
                                                                //           "  " +
                                                                //           val[index].relation_user_id[
                                                                //             i18n.language
                                                                //           ].name +
                                                                //           " " +
                                                                //           val[index].relation_user_id[
                                                                //             i18n.language
                                                                //           ].surname
                                                                //       : "-"}
                                                                //   </p>

                                                                // </Descriptions.Item>
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Row span={24} style={{ width: "100%" }}>
                                                                        <Col span={12}>
                                                                            <p>{val[index].relation_master_id[i18n.language].name + " " + t("Name")}</p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <p style={{ color: "blue", float: "center" }}>
                                                                                {val &&
                                                                                    val[index] &&
                                                                                    val[index] &&
                                                                                    val[index].relation_user_id &&
                                                                                    val[index].relation_user_id[i18n.language] &&
                                                                                    val[index].relation_user_id[i18n.language].name !== ""
                                                                                    ? val &&
                                                                                    val[index] &&
                                                                                    val[index].prefix_id &&
                                                                                    val[index].prefix_id[i18n.language] &&
                                                                                    val[index].prefix_id[i18n.language].name +
                                                                                    "  " +
                                                                                    val[index].relation_user_id[i18n.language].name +
                                                                                    " " +
                                                                                    val[index].relation_user_id[i18n.language].surname
                                                                                    : "-"}
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                // <Descriptions.Item
                                                                //   label={
                                                                //     val[index] &&
                                                                //     val[index].relation_master_id &&
                                                                //     val[index].relation_master_id[i18n.language]
                                                                //       .name +
                                                                //       " " +
                                                                //       t("Name")
                                                                //   }
                                                                //   style={{
                                                                //     backgroundColor: "white",
                                                                //     border: "none",
                                                                //     borderBottom: "white",
                                                                //   }}
                                                                // >
                                                                //   <p style={{ color: "blue" }}>
                                                                //     {" "}
                                                                //     {(val &&
                                                                //       val[index] &&
                                                                //       val[index].static_info &&
                                                                //       val[index].static_info[i18n.language] &&
                                                                //       val[index].static_info[i18n.language]
                                                                //         .name !== null) ||
                                                                //     "" ||
                                                                //     undefined
                                                                //       ? val &&
                                                                //         val[index] &&
                                                                //         val[index].static_info &&
                                                                //         val[index].static_info[i18n.language] &&
                                                                //         val[index].static_info[i18n.language]
                                                                //           .name
                                                                //       : "-"}
                                                                //   </p>
                                                                //   <br></br>
                                                                // </Descriptions.Item>
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Row span={24} style={{ width: "100%" }}>
                                                                        <Col span={12}>
                                                                            <p>
                                                                                {val[index] &&
                                                                                    val[index].relation_master_id &&
                                                                                    val[index].relation_master_id[i18n.language].name + " " + t("Name")}
                                                                            </p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <p style={{ color: "blue", float: "center" }}>
                                                                                {(val &&
                                                                                    val[index] &&
                                                                                    val[index].static_info &&
                                                                                    val[index].static_info[i18n.language] &&
                                                                                    val[index].static_info[i18n.language].name !== null) ||
                                                                                    "" ||
                                                                                    undefined
                                                                                    ? val &&
                                                                                    val[index] &&
                                                                                    val[index].static_info &&
                                                                                    val[index].static_info[i18n.language] &&
                                                                                    val[index].static_info[i18n.language].name
                                                                                    : "-"}
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                ""
                            )}
                        </div>

                        {/* <Row style={{marginLeft:"29px"}}>
        <Col span={12}><div><label>{t('Education')}</label></div></Col>
      <Col span={12}><div><p> {(User_data1 && User_data1.education_id && User_data1.education_id[i18n.language] && User_data1.education_id[i18n.language].education == 'undefined') ? '-' : User_data1 && User_data1.education_id && User_data1.education_id[i18n.language] && User_data1.education_id[i18n.language].education}</p></div></Col>
      <Col span={12}><div><label>{t('Occupation')}</label></div></Col>
      <Col span={12}><div><p>  {userOccupation && userOccupation.map((value) => {
                      
                      return value && value[i18n.language].occupation + " , "
                    })}</p></div></Col>

<Col span={12}><div><label>{t('Working_At')}</label></div></Col>
      <Col span={12}><div><p>   {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language].current_working_at) == "" ? '-' : User_data1 && User_data1[i18n.language] && User_data1[i18n.language].current_working_at}</p></div></Col>




        </Row> */}
                    </div>
                </>
            ),
        },
        {
            key: "3",
            label: t("ContactDetail"),
            children: (
                <>
                    <div>
                        <Row style={{ marginLeft: "29px" }}>
                            <Col span={12}>
                                <div>
                                    <label>{t("Email")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue", wordBreak: "break-word" }}> {(User_data1 && User_data1.email) == "" ? "-" : User_data1 && User_data1.email}</p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Contact_Number")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {(User_data1 && User_data1.phone_number) == "" ? "-" : User_data1 && User_data1.phone_number}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Current_Address")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language].currentAddress) == ""
                                            ? "-"
                                            : User_data1 && User_data1[i18n.language] && User_data1[i18n.language].currentAddress}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Current_location")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language].currentLocation) == ""
                                            ? "-"
                                            : User_data1 && User_data1[i18n.language] && User_data1[i18n.language].currentLocation}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Native_location")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language].nativeLocation) == ""
                                            ? "-"
                                            : User_data1 && User_data1[i18n.language] && User_data1[i18n.language].nativeLocation}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("Postal_Address")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language].postal_address) == ""
                                            ? "-"
                                            : User_data1 && User_data1[i18n.language] && User_data1[i18n.language].postal_address}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("sasural_Address")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {console.log("sasural_Address", User_data1 && User_data1[i18n.language] && User_data1[i18n.language]?.sasuralAddress)}
                                        {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language]?.sasuralAddress) == "" || "undefined"
                                            ? "-"
                                            : User_data1 && User_data1[i18n.language] && User_data1[i18n.language]?.sasuralAddress}
                                    </p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <label>{t("nanihal_Address")}</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p style={{ color: "blue" }}>
                                        {" "}
                                        {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language]?.nanihalAddress) == "" || "undefined"
                                            ? "-"
                                            : User_data1 && User_data1[i18n.language] && User_data1[i18n.language]?.nanihalAddress}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="viewUserDetail">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={(
                        <div className="adv_pageTitle">
                            <FeatherIcon
                                icon="arrow-left-circle"
                                size="28px"
                                onClick={() => window.history.back()}
                                style={{ cursor: "pointer", marginRight: "10px", }}
                            />
                            <span style={{ fontSize: "20px" }}>{t("User_Details")}</span>
                        </div>
                    )}
                />
            </div>
            {/* </div> */}

            <Modal
                open={imgModalOpen} onCancel={() => setImgModalOpen(false)}
                className="imageModal"
                footer={null}
                maskClosable={false}
                centered
            >
                {previewMedia}
            </Modal>

            <div className="container">
                <form>
                    <div className="row">
                        <Row>
                            <Col lg={8} xs={24} sm={24}>
                                {" "}
                                <div className="profile-img">
                                    {User_data1.profile_photo === "" ? (
                                        <Avatar
                                            className="user_img"
                                            icon={<UserOutlined style={{ marginTop: "40px", fontSize: "40px" }} />}
                                            style={{ backgroundColor: getRandomColor() }}
                                        />
                                    ) : (
                                        <img
                                            height={200}
                                            width={200}
                                            className="user_img"
                                            crossOrigin="anonymous"
                                            alt=""
                                            src={`${User_data1.profile_photo}`}
                                            onClick={() => showImagePreview(User_data1.profile_photo)}
                                        />
                                    )}
                                </div>
                            </Col>

                            <Col lg={12} xs={24} sm={24}>
                                <div className="profile-head" style={{ marginTop: "10px", marginLeft: "5%" }}>
                                    <h5 style={{ fontSize: "20px" }}>
                                        {User_data1 && User_data1[i18n.language] && User_data1[i18n.language].name == ""
                                            ? "-"
                                            : User_data1 &&
                                            User_data1[i18n.language] &&
                                            User_data1[i18n.language].name + " " + User_data1[i18n.language].surname}
                                    </h5>
                                    <h6 style={{ fontSize: "15px", display: "flex" }}>
                                        <div>
                                            <label style={{ color: "#6c757d" }}>
                                                <b> {t("About_Me")}</b>
                                                <span style={{ marginLeft: "10px" }}>:-</span>
                                            </label>
                                        </div>

                                        <div>
                                            <p style={{ marginLeft: "10px" }}>
                                                {" "}
                                                {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language].about_me) == ""
                                                    ? "-"
                                                    : User_data1 && User_data1[i18n.language] && User_data1[i18n.language].about_me}
                                            </p>
                                        </div>
                                    </h6>
                                    <br />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={8} xs={24} sm={24} style={{ marginTop: "28px" }}>
                                <div>
                                    <p orientation="left" orientationMargin={50} style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                fontSize: "15px",
                                                marginRight: "19%",
                                                color: "#6c757d",
                                            }}
                                        >
                                            <b> {t("Education_and_Professional_Details")}</b>
                                        </p>
                                    </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Col span={12}>
                                        <div>
                                            <label>{t("Education")}</label>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>
                                            <p style={{ color: "blue" }}>
                                                {" "}
                                                {User_data1 &&
                                                    User_data1.education_id &&
                                                    User_data1.education_id[i18n.language] &&
                                                    User_data1.education_id[i18n.language].education == "undefined"
                                                    ? "-"
                                                    : User_data1 &&
                                                    User_data1.education_id &&
                                                    User_data1.education_id[i18n.language] &&
                                                    User_data1.education_id[i18n.language].education}
                                            </p>
                                        </div>
                                    </Col>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Col span={12}>
                                        <div>
                                            <label>{t("Occupation")}</label>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>
                                            <p style={{ color: "blue" }}>
                                                {" "}
                                                {userOccupation &&
                                                    userOccupation.map((value) => {
                                                        return value && value[i18n.language].occupation
                                                    }).join(" | ")}
                                            </p>
                                        </div>
                                    </Col>
                                </div>

                                <div style={{ display: "flex" }}>
                                    <Col span={12}>
                                        <div>
                                            <label>{t("Working_At")}</label>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>
                                            <p style={{ color: "blue" }}>
                                                {" "}
                                                {(User_data1 && User_data1[i18n.language] && User_data1[i18n.language].current_working_at) == ""
                                                    ? "-"
                                                    : User_data1 && User_data1[i18n.language] && User_data1[i18n.language].current_working_at}
                                            </p>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                            <Col lg={12} xs={24} sm={24}>
                                {" "}
                                <div style={{ marginTop: "-12px" }}>
                                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <div>
                                    {User_data1 && User_data1.advertisementData && User_data1.advertisementData.length !== 0 ? (
                                        <div className="advertisementDetails" style={{ marginTop: "3%" }}>
                                            <p orientation="left" orientationMargin={50}>
                                                <b>
                                                    {" "}
                                                    <p style={{ color: "rgb(108, 117, 125)" }}>{t("advertisement_details")}</p>
                                                </b>
                                            </p>
                                            {User_data1 &&
                                                User_data1.advertisementData &&
                                                User_data1.advertisementData.map((adv, i) => {
                                                    return (
                                                        <>
                                                            <Row>
                                                                <Col lg={8} xs={24} sm={24}>
                                                                    <div className="advimg" style={{ width: "200px" }}>
                                                                        <img src={adv.advertisementBanner} className="BannerImag" />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={12} xs={24} sm={24}>
                                                                    <br />
                                                                    <div style={{ display: "flex" }}>
                                                                        <Col span={12}>
                                                                            <p>{t("Advertisement_title")}</p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <p style={{ color: "blue" }}>{adv[i18n.language].title}</p>
                                                                        </Col>
                                                                    </div>

                                                                    <div style={{ display: "flex" }}>
                                                                        <Col span={12}>
                                                                            <p>{t("advertisement_description")}</p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            {" "}
                                                                            <p style={{ color: "blue" }}>{adv[i18n.language].description}</p>
                                                                        </Col>
                                                                    </div>

                                                                    <div style={{ display: "flex" }}>
                                                                        <Col span={12}>
                                                                            <p>{t("Advertisement_type")}</p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            {" "}
                                                                            <p style={{ color: "blue" }}>
                                                                                {" "}
                                                                                {adv && adv.type === "personal_ads"
                                                                                    ? t("personal_Advertisement")
                                                                                    : t("General_Advertisement")}
                                                                            </p>
                                                                        </Col>
                                                                    </div>

                                                                    <div style={{ display: "flex" }}>
                                                                        <Col span={12}>
                                                                            <p>{t("isPayment_Done")}</p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            {" "}
                                                                            <p style={{ color: "blue" }}>
                                                                                {" "}
                                                                                {adv && adv.isPaymentDone === true ? t("Yes") : t("No")}
                                                                            </p>
                                                                        </Col>
                                                                    </div>

                                                                    <div style={{ display: "flex" }}>
                                                                        <Col span={12}>
                                                                            <p>{t("payment_Amount")}</p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            {" "}
                                                                            <p style={{ color: "blue" }}>
                                                                                {" "}
                                                                                {adv.paymentAmount == "undefined" ? "-" : adv.paymentAmount}
                                                                            </p>
                                                                        </Col>
                                                                    </div>

                                                                    <div style={{ display: "flex" }}>
                                                                        <Col span={12}>
                                                                            <p>{t("payment_DoneBy")}</p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            {" "}
                                                                            <p style={{ color: "blue" }}> {adv[i18n.language].paymentDoneBy}</p>
                                                                        </Col>
                                                                    </div>

                                                                    <div style={{ display: "flex" }}>
                                                                        <Col span={12}>
                                                                            <p>{t("advertisement_created_at")}</p>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            {" "}
                                                                            <p style={{ color: "blue" }}> {getDateFormate(adv.created_at)}</p>
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                })}
                                        </div>
                                    ) : (
                                        " "
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </form>
            </div>
        </>
    );
}

export default UserDetail;
