import Cookies from 'js-cookie';
import actions from './actions';
const { GET_OCCUPATION_LIST,
    ADD_OCCUPATION,
    EDIT_OCCUPATION,
    DELETE_OCCUPATION } = actions;

const initState = {
    OccupationMaster: {},
    updateOccupation: {}
};
const OccupationMasterReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_OCCUPATION_LIST:
            return {
                ...state,
                OccupationMaster: data,
            };
        case ADD_OCCUPATION:
            return {
                ...state,
                updateOccupation: data,
            };
        case EDIT_OCCUPATION:
            return {
                ...state,
                updateOccupation: data,
            };
        case DELETE_OCCUPATION:
            return {
                ...state,
                updateOccupation: data,
            };
        default:
            return state;
    }
};
export default OccupationMasterReducer;