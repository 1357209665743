import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
    Button,
    Card,
    Switch,
    Divider,
    Avatar,
    Row,
    Descriptions,
    Image,
    Table,
    Col,
    Checkbox,
    Form,
    Input,
    Select,
    Carousel,
    PageHeader,
    Tooltip,
} from "antd";
import { getRandomColor, getDateFormate } from "../../utility/commonFunction";
import { SearchOutlined, UserOutlined, EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { get_Advertisement_details } from "../../redux/advertisementList/actionCreator";
import i18n from "i18next";

import FeatherIcon from "feather-icons-react";

const contentStyle = {
    height: "400px",
    width: "auto",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    marginLeft: "15%",
};

const ViewAdvertisement = () => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const Push = (targetVal) => {
        // console.log('user detail Id ----->', targetVal);
        history.push({
            pathname: `${path}/user_detail`,
            state: {
                id: targetVal,
            },
        });
    };

    const AdvertisementData = useSelector((state) => state.AdvertisementReducer.advertisement_detail);
    // console.log("view Advertisement data~~~~~::::", AdvertisementData);

    useEffect(() => {
        if (location && location.state && location.state.id !== undefined) Advertisement(location.state.id);
    }, []);
    const Advertisement = (id) => {
        dispatch(get_Advertisement_details(location.state.id));
    };

    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    return (
        <>
            {Advertisement && Advertisement.length > 0 ? (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="GeneralAdvViewPage">
                            <div className="viewAdvertisement">
                                <PageHeader
                                    ghost={false}
                                    className="site-page-header"
                                    title={(
                                        <div className="adv_pageTitle">
                                            <FeatherIcon
                                                icon="arrow-left-circle"
                                                size="28px"
                                                onClick={() => window.history.back()}
                                                style={{ cursor: "pointer", marginRight: "10px" }}
                                            />
                                            <span style={{ fontSize: "20px" }}>{t("View_Advertisement")}</span>
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="description_Container">
                                <Descriptions bordered column={1} labelStyle={{ width: "300px" }}>
                                    <Descriptions.Item label={t("UserName")} style={{ cursor: "pointer", }}>
                                        {console.log("advertisement data", AdvertisementData && AdvertisementData.user_id && AdvertisementData.user_id._id)}
                                        <p
                                            className="userNameImg"
                                            onClick={() => Push(AdvertisementData && AdvertisementData.user_id && AdvertisementData.user_id._id)}
                                        >
                                            {/* <Tooltip placement="top" title={t('view_profile')}> */}
                                            {AdvertisementData && AdvertisementData.user_id && AdvertisementData.user_id.profile_photo !== "" ? (
                                                <img
                                                    className="advUserAvatar"
                                                    height={35}
                                                    width={35}
                                                    crossOrigin="anonymous"
                                                    alt=""
                                                    src={`${AdvertisementData && AdvertisementData.user_id && AdvertisementData.user_id.profile_photo}`}
                                                    style={{
                                                        borderRadius: 50,
                                                    }}
                                                />
                                            ) : (
                                                <Avatar icon={<UserOutlined />} style={{ backgroundColor: "rgb(184, 184, 177)" }} className="advUserAvatar" />
                                            )}
                                            {/* </Tooltip> */}
                                            {AdvertisementData &&
                                                AdvertisementData.user_id &&
                                                AdvertisementData.user_id[i18n.language] &&
                                                AdvertisementData.user_id[i18n.language].name + " " + AdvertisementData.user_id[i18n.language].surname}
                                        </p>
                                    </Descriptions.Item>

                                    <Descriptions.Item label={t("Advertisement_Description")}>
                                        {AdvertisementData && AdvertisementData[i18n.language] && AdvertisementData[i18n.language].description}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("isPayment_Done")}>
                                        {AdvertisementData && AdvertisementData.isPaymentDone === true ? (
                                            <span
                                                style={{
                                                    borderWidth: "1px",
                                                    borderColor: "green",
                                                    borderStyle: "solid",
                                                    paddingRight: "16px",
                                                    paddingLeft: "16px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                    backgroundColor: "green",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    wordBreak: "keep-all",
                                                    width: "fit-content",
                                                    // margin: 'auto'
                                                }}
                                                className="sentStyle"
                                            >
                                                {" "}
                                                {t("Yes")}{" "}
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    borderWidth: "1px",
                                                    borderColor: "red",
                                                    borderStyle: "solid",
                                                    paddingRight: "16px",
                                                    paddingLeft: "16px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                    backgroundColor: "red",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    wordBreak: "keep-all",
                                                    width: "fit-content",
                                                    // margin: 'auto'
                                                }}
                                            >
                                                {" "}
                                                {t("No")}{" "}
                                            </span>
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Payment_Done_By")}>
                                        {(AdvertisementData &&
                                            AdvertisementData[i18n.language] &&
                                            AdvertisementData[i18n.language].paymentDoneBy == undefined) ||
                                            ""
                                            ? "--"
                                            : AdvertisementData && AdvertisementData[i18n.language] && AdvertisementData[i18n.language].paymentDoneBy}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Payment_Amount")} span={2}>
                                        {AdvertisementData && AdvertisementData.paymentAmount == "undefined"
                                            ? "--"
                                            : AdvertisementData && AdvertisementData.paymentAmount}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("advertisement_created_at")}>
                                        {AdvertisementData && getDateFormate(AdvertisementData.created_at)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("Last_Updated_At")}>
                                        {AdvertisementData && getDateFormate(AdvertisementData.updated_at)}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>

                            <div className="advertisementBanner">
                                <Divider orientation="left">
                                    <h3>{t("Advertisement_Image")} </h3>
                                </Divider>

                                <Carousel afterChange={onChange}>
                                    <div style={contentStyle}>
                                        <img
                                            src={AdvertisementData && AdvertisementData?.advertisementBanner}
                                            className="advBannerImag"
                                            crossOrigin="anonymous"
                                            style={{ marginLeft: "250px" }}
                                        />

                                    </div>
                                    <div style={contentStyle}>
                                        <img
                                            src={AdvertisementData && AdvertisementData?.smallAdvertisementBanner}
                                            className="advBannerImag"
                                            crossOrigin="anonymous"
                                        />

                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="spinnerName">
                            <Spin size="large"></Spin>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ViewAdvertisement;
