import Cookies from 'js-cookie';
import actions from './actions';
const { GET_FAQ_LIST,
    ADD_FAQ,
    EDIT_FAQ,
    DELETE_FAQ } = actions;

const initState = {
    Faqs: {},
    updateFaq: {}
};
const FAQReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_FAQ_LIST:
            return {
                ...state,
                Faqs: data,
            };
        case ADD_FAQ:
            return {
                ...state,
                updateFaq: data,
            };
        case EDIT_FAQ:
            return {
                ...state,
                updateFaq: data,
            };
        case DELETE_FAQ:
            return {
                ...state,
                updateFaq: data,
            };
        default:
            return state;
    }
};
export default FAQReducer;