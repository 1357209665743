import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Button, Tooltip, Form, Modal, Avatar, Input, Radio, Select, Cascader, DatePicker, Spin } from "antd";
import useStateRef from "react-usestateref";
import { UserOutlined, ConsoleSqlOutlined, EditTwoTone } from "@ant-design/icons";
import { getRandomColor, getDateFormate, useAutoFocus } from "../../utility/commonFunction";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { putUserDetailsUpdate, updateUserDetailTrack, get_users_details, getUpdateUserDetailList } from "../../redux/AdminPannel/actionCreator";
import { bloodGroup } from "../../redux/bloodGroup/actionCreator";
import { maritalstatus } from "../../redux/maritalStatus/actionCreator";
import { Get_Gotra_Data } from "../../redux/gotra/actionCreator";
import { getOccupationList } from "../../redux/OccupationMaster/actionCreator";
import { Get_Education_Data } from "../../redux/education/actionCreator";
import { Get_Gender_Data } from "../../redux/gender/actionCreator";
import moment from "moment";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

function updateDeatilsModal(prop) {
    // console.log("🚀 ~ file: updateDeatilsModal.js:22 ~ updateDeatilsModal ~ prop:", prop.target)

    let { showUpdateDetailModal, setShowUpdateDetailModal } = prop;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [left_form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId, selectedIdRef] = useStateRef("");
    const [selectedOccupation, setSelectedOccupation] = useState([]);
    const [GetDetailByIdApi, setGetDetailByIdApi] = useState({});

    // const GetDetailByIdApi = useSelector((state) => state.UsersStatusReducer.getUserDetail);
    // console.log("getdetailbyid", GetDetailByIdApi);
    const userUpdateDetails = useSelector((state) => state.UsersStatusReducer.updateDetailTrack.data);
    const getOccupationData = useSelector((state) => state?.OccupationMasterReducer?.OccupationMaster?.data?.list);
    const getBloodGroupData = useSelector((state) => state?.BloodGroupReducer?.bloodGroup?.data?.list);
    const DataOfMaritalStatus = useSelector((state) => state?.MaritalStatusReducer?.maritalStatus?.data?.list);
    // console.log("dataof maritalstatus", DataOfMaritalStatus);
    const dataGotra = useSelector((state) => state?.GotraReducer?.gotraData?.data?.list);
    const getDataGenderList = useSelector((state) => state.GenderReducer.genderData.data);
    const getdataGender = getDataGenderList && getDataGenderList && getDataGenderList.list;
    // console.log("getdataGender verification", getdataGender);
    const getDataEducationList = useSelector((state) => state.EducationReducer.educationData);
    // console.log("getdataEducationlist", getDataEducationList);
    const getdataEducation = getDataEducationList && getDataEducationList?.data?.list;
    // console.log("getdataEducation>>>>>", getdataEducation);

    const updatedTrackInfo = userUpdateDetails && userUpdateDetails.find((x) => (x && x.user_id === prop?.target.user_id ? x : ""));
    const usersRelations = GetDetailByIdApi && GetDetailByIdApi;
    // console.log("userdatainupdate", usersRelations);
    const relationMasterData = usersRelations && usersRelations.userRelationData;
    // console.log("🚀 ~ file: updateDeatilsModal.js:54 ~ updateDeatilsModal ~ relationMasterData:", relationMasterData)
    const educationQualification =
        updatedTrackInfo && updatedTrackInfo.lang_code === "hn"
            ? usersRelations && usersRelations.hn && usersRelations.hn.education_qualification
            : usersRelations && usersRelations.en && usersRelations.en.education_qualification;
    const updatedKeyArr = updatedTrackInfo && updatedTrackInfo.updatedKeys;

    useEffect(() => {
        setLoading(true);
        updateUsersData();
        UserGetDetailsList(prop?.target.user_id);
        updatedDetailsTrack();
        showModal(prop?.target)
    }, []);

    useEffect(() => {
        getBloodGroup_data();
        OccupationMasterData();
        getMaritalStatus_data();
        GotraMasterData();
        getGenderdata();
        getEducationData();
    }, []);

    const getGenderdata = () => {
        dispatch(Get_Gender_Data());
    };

    const getEducationData = () => {
        let params = {
            search: "",
            page: "",
            limit: "",
        };
        dispatch(Get_Education_Data(params));
    };

    const updateUsersData = async () => {
        let params = {
            // english: "",
            // hindi: "",
            search: prop.search,
            page: prop.currentPage,
            limit: prop.pageLimit,
        };
        await dispatch(getUpdateUserDetailList(params));
    };
    const UserGetDetailsList = async (id) => {
        const data = await dispatch(get_users_details(id));
        if (data && data?.data) {
            setGetDetailByIdApi(data?.data);
            setLoading(false)
        }
    };
    const updatedDetailsTrack = async () => {
        dispatch(updateUserDetailTrack());
    };

    const getBloodGroup_data = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(bloodGroup(params));
    };
    const OccupationMasterData = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(getOccupationList(params));
    };

    const getMaritalStatus_data = () => {
        let params = {
            search: "",
            page: "",
            limit: "",
        };
        dispatch(maritalstatus(params));
    };
    const GotraMasterData = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(Get_Gotra_Data(params));
    };

    const onChange = (date, dateString) => {
        // console.log(date, dateString);
    };

    const formItemLayout = {
        labelCol: {
            xl: {
                span: 9,
            },
            lg: {
                span: 9,
            },
        },
        wrapperCol: {
            xl: {
                span: 15,
            },
            lg: {
                span: 15,
            },
        },
    };


    const showModal = (val) => {
        console.log("🚀 ~ file: updateDeatilsModal.js:167 ~ showModal ~ val:", val)
        // console.log("props", prop.target.user_id);
        // setOpen(true);
        setSelectedId(prop && prop.target && prop.target.user_id !== undefined ? prop.target.user_id : "");
        let occupationArray = [];
        if (usersRelations && usersRelations.occupation && usersRelations.occupation.length > 0) {
            usersRelations.occupation.map((ocu) => {
                occupationArray.push(ocu._id);
            });
        }
        setSelectedOccupation(occupationArray);

        // for left side form
        updatedTrackInfo && updatedTrackInfo.lang_code === "hn"
            ? left_form.setFieldsValue({
                profile_photo: val && val.profile_photo,
                username: val && val.hn && val.hn.name,
                surname: val && val.hn && val.hn.surname,
                gender: val && val.gender_id && val.gender_id.hn && val.gender_id.hn.name,
                age: val && val.hn && val.hn.age,
                date_of_birth: val && val && getDateFormate(val.date_of_birth),
                marital_status:
                    val &&
                    val.marital_status_id &&
                    val.marital_status_id.hn &&
                    val.marital_status_id.hn.marital_status,
                blood_group:
                    val &&
                    val &&
                    val.blood_group_id &&
                    val.blood_group_id.hn &&
                    val.blood_group_id.hn.blood_group,
                gotra: val && val.gotra_master_id && val.gotra_master_id.hn && val.gotra_master_id.hn.name,
                education_master: val && val?.education_id?.hn?.education,
                occupation: val && val.occupation && val.occupation.hn && val.occupation.hn.occupation,
                current_working_at: val && val.hn && val.hn.current_working_at,
                hobbies: val && val.hn && val.hn.hobbies,
                phone_number: val && val.phone_number,
                email: val && val.email,
                currentAddress: val && val.hn && val.hn.currentAddress,
                currentLocation: val && val.hn && val.hn.currentLocation,
                nativeAddress: val && val.hn && val.hn.nativeAddress,
                nativeLocation: val && val.hn && val.hn.nativeLocation,
                postal_address: val && val.hn && val.hn.postal_address,
                nanihalAddress: val && val.hn && val.hn.nanihalAddress,
                sasuralAddress: val && val.hn && val.hn.sasuralAddress,
                about_me: val && val.hn && val.hn.about_me,
                degree:
                    val &&
                    val.education_qualification &&
                    val.education_qualification.en &&
                    val.education_qualification.en.degree,
                university:
                    val &&
                    val.education_qualification &&
                    val.education_qualification.en &&
                    val.education_qualification.en.schoolName,
            })
            :
            left_form.setFieldsValue({
                profile_photo: val && val.profile_photo,
                username: val && val.en && val.en.name,
                surname: val && val.en && val.en.surname,
                gender: val && val.gender_id && val.gender_id.en && val.gender_id.en.name,
                age: val && val.en && val.en.age,
                date_of_birth: val && val && getDateFormate(val.date_of_birth),
                marital_status:
                    val &&
                    val.marital_status_id &&
                    val.marital_status_id.en &&
                    val.marital_status_id.en.marital_status,
                blood_group:
                    val &&
                    val &&
                    val.blood_group_id &&
                    val.blood_group_id.en &&
                    val.blood_group_id.en.blood_group,
                gotra: val && val && val.gotra_master_id?.en?.name,
                education_master: val && val?.education_id?.en?.education,
                occupation: val && val.occupation && val.occupation.en && val.occupation.en.occupation,
                current_working_at: val && val.en && val.en.current_working_at,
                hobbies: val && val.en && val.en.hobbies,
                phone_number: val && val && val.phone_number,
                email: val && val.email,
                currentAddress: val && val.en && val?.en?.currentAddress,
                currentLocation: val && val.en && val?.en?.currentLocation,
                nativeAddress: val && val.en && val?.en?.nativeAddress,
                nativeLocation: val && val.en && val?.en?.nativeLocation,
                postal_address: val && val.en && val?.en?.postal_address,
                nanihalAddress: val && val.en && val?.en?.nanihalAddress,
                sasuralAddress: val && val.en && val?.en?.sasuralAddress,
                about_me: val && val.en && val?.en?.about_me,
                degree: val && val.en && val?.en?.education_qualification && val?.en?.education_qualification?.degree,
                university:
                    val && val.en && val.en.education_qualification && val.en.education_qualification.university,
            });

        // for validation form
        updatedTrackInfo && updatedTrackInfo.lang_code !== "hn"
            ? form.setFieldsValue({
                profile_photo: val && val.profile_photo,
                username: val && val.en && val.en.name,
                surname: val && val.en && val.en.surname,
                age: val && val.en && val.en.age,
                marital_status: `${(val && val && val.marital_status_id?.en?.marital_status !== null) || "" || undefined
                    ? val &&
                    val &&
                    val.marital_status_id?.en?.marital_status &&
                    val &&
                    val &&
                    val.marital_status_id?.en?.marital_status
                    : "-"
                    }`,

                blood_group: val && val && val.blood_group_id?.en?.blood_group,
                gotra: val && val && val.gotra_master_id?.en?.name,
                occupation:
                    val &&
                    val.en &&
                    val.en.occupation &&
                    val.en.occupation.en &&
                    val.en.occupation.en.occupation,
                current_working_at: val && val.en && val.en.current_working_at,
                hobbies: val && val.en && val.en.hobbies,
                phone_number: val && val.phone_number,
                email: val && val.email,
                currentAddress: val && val.en && val.en.currentAddress,
                currentLocation: val && val.en && val.en.currentLocation,
                nativeAddress: val && val.en && val.en.nativeAddress,
                nativeLocation: val && val.en && val.en.nativeLocation,
                about_me: val && val.en && val.en.about_me,
                degree: val && val.en && val.en.education_qualification && val.en.education_qualification.degree,
                university:
                    val && val.en && val.en.education_qualification && val.en.education_qualification.university,
            })
            : form.setFieldsValue({
                profile_photo: val && val.profile_photo,
                username: val && val.hn && val.hn.name,
                surname: val && val.hn && val.hn.surname,
                age: val && val.hn && val.hn.age,
                marital_status:
                    val &&
                    val.hn &&
                    val.hn.marital_status_id &&
                    val.hn.marital_status_id.hn &&
                    val.hn.marital_status_id.hn.marital_status,
                blood_group:
                    val &&
                    val.hn &&
                    val.hn.blood_group_id &&
                    val.hn.blood_group_id.hn &&
                    val.hn.blood_group_id.hn.blood_group,
                gotra: val && val.hn && val.hn.gotra_master_id && val.hn.gotra_master_id._id,
                occupation:
                    val &&
                    val.hn &&
                    val.hn.occupation &&
                    val.hn.occupation.hn &&
                    val.hn.occupation.hn.occupation,
                current_working_at: val && val.hn && val.hn.current_working_at,
                hobbies: val && val.hn && val.hn.hobbies,
                phone_number: val && val.phone_number,
                email: val && val.email,
                currentAddress: val && val.hn && val.hn.currentAddress,
                currentLocation: val && val.hn && val.hn.currentLocation,
                nativeAddress: val && val.hn && val.hn.nativeAddress,
                nativeLocation: val && val.hn && val.hn.nativeLocation,
                about_me: val && val.hn && val.hn.about_me,
                degree:
                    val &&
                    val.education_qualification &&
                    val.education_qualification.en &&
                    val.education_qualification.en.degree,
                university:
                    val &&
                    val.education_qualification &&
                    val.education_qualification.en &&
                    val.education_qualification.en.schoolName,
            });
    };


    const handleCancel = () => {
        setShowUpdateDetailModal(false);
        form.resetFields();
    };

    const handleSubmit = async (val) => {
        delete val.profile_photo;
        console.log("update user status val data ++))):", val);
        const form_data = new FormData();

        for (let key in val) {
            form_data.append(key, val[key]);
        }
        for (const pair of form_data.entries()) {
            console.log("{{{{{-pair-}}}}}}}}} : ", pair);
        }

        var z = Object.keys(val)
            .filter(function (k) {
                return k.indexOf("static_info") == 0;
            })
            .reduce(function (newData, k) {
                newData[k] = val[k];
                return newData;
            }, {});

        // replace keys with only id of object
        let replacedItems = Object.keys(z).map((key) => {
            var newKey = key.slice(12) || key;
            return { [newKey]: z[key] };
        });

        replacedItems.map((v, i) => {
            var id = Object.keys(v)[0];
            var value = Object.values(v)[0];
            var RelObj = {
                static_info: {
                    hn: {},
                    en: {},
                },
            };
            const staticRelName = relationMasterData && relationMasterData.find((e) => e._id === id);

            if (updatedTrackInfo && updatedTrackInfo.lang_code !== "hn") {
                RelObj.static_info.hn.name = value;
                RelObj.static_info.en.name = staticRelName && staticRelName.static_info && staticRelName.static_info.en && staticRelName.static_info.en.name;
            } else {
                RelObj.static_info.en.name = value;
                RelObj.static_info.hn.name = staticRelName && staticRelName.static_info && staticRelName.static_info.hn && staticRelName.static_info.hn.name;
            }
            console.log("selectedIdRef.current ", selectedIdRef.current);
            if (selectedIdRef.current !== "") {
                // const updateUserStaticRelInfo = dispatch(
                //   update_User_details_Relationship(RelObj, id)
                // );
            }
        });

        let obj = {};
        obj.blood_group_id = val.blood_group_id;
        obj.gender_id = val.gender_id;
        obj.gotra_master_id = val.gotra_master_id;
        obj.education_id = val.education_master;
        obj.marital_status_id = val.marital_status_id;
        obj.occupation = selectedOccupation;
        obj.date_of_birth = moment(val.date_of_birth).format("YYYY-MM-DD");
        if (updatedTrackInfo && updatedTrackInfo.lang_code !== "hn") {
            obj.hn = val;
            delete obj.hn.blood_group_id;
            delete obj.hn.gender_id;
            delete obj.hn.gotra_master_id;
            delete obj.hn.marital_status_id;
            delete obj.hn.occupation;
            delete obj.hn.date_of_birth;
        } else {
            obj.en = val;
            delete obj.en.blood_group_id;
            delete obj.en.gender_id;
            delete obj.en.gotra_master_id;
            delete obj.en.marital_status_id;
            delete obj.en.occupation;
            delete obj.en.date_of_birth;
        }
        setShowUpdateDetailModal(false);
        form.resetFields();
        if (selectedIdRef.current !== "") {
            const updateUserStatusToVerify = await dispatch(putUserDetailsUpdate(obj, selectedIdRef.current));

            console.log("handleSubmit ~ updateUserStatusToVerify :: ", updateUserStatusToVerify);
            if (updateUserStatusToVerify) {
                updateUsersData();
                setSelectedId("");
            }
        }
    };

    return (
        <>
            <Modal
                open={showUpdateDetailModal}
                title={t("Update_User_Details")}
                okText={t("Submit")}
                onOk={form.submit}
                cancelText={t("Cancel")}
                onCancel={handleCancel}
                width={900}
            >

                {loading ? (
                    <div style={{ height: '50px' }}>
                        <Spin />
                    </div>

                ) :
                    <Row>
                        <Col span={12} xs={12} sm={12} md={12} lg={12}>
                            <div className="Verification_form_left">
                                <Form {...formItemLayout} form={left_form}>
                                    <Form.Item label={t("User_Profile")} valuePropName="fileList" name="profile_photo">
                                        {usersRelations && usersRelations.profile_photo !== "" ? (
                                            <img
                                                src={`${usersRelations && usersRelations.profile_photo}`}
                                                width="60"
                                                height="60"
                                                className="addImg"
                                                style={{ border: "1px solid black" }}
                                                crossOrigin="anonymous"
                                            />
                                        ) : (
                                            <Avatar icon={<UserOutlined />} shape="square" style={{ backgroundColor: getRandomColor() }} size={64} />
                                        )}
                                    </Form.Item>
                                    <Form.Item label={t("Language")} name="lang_code">
                                        <Input size="small" />
                                    </Form.Item>

                                    <Form.Item label={t("UserName")} name="username">
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item label={t("Surname")} name="surname">
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item label={t("Gender")} name="gender">
                                        <Input size="small" />
                                    </Form.Item>

                                    <Form.Item label={t("Birth_Date")} name="date_of_birth">
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item label={t("Marital_Status")} name="marital_status">
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item label={t("Blood_Group")} name="blood_group">
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item label={t("Gotras")} name="gotra">
                                        <Input size="small" />
                                    </Form.Item>

                                    {relationMasterData && relationMasterData.length > 0
                                        ? relationMasterData && relationMasterData.map((relation, index) => {
                                            if (updatedTrackInfo && updatedTrackInfo.lang_code === "hn") {
                                                if (Object.keys(relation).includes("relation_user_id") === true) {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                                // + " " + "Name"
                                                            }
                                                            name={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                            }
                                                        >
                                                            <Input
                                                                disabled
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.hn &&
                                                                    relation.prefix_id.hn.name
                                                                }
                                                                defaultValue={
                                                                    (relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.hn &&
                                                                        relation.relation_user_id.hn.name !== "") ||
                                                                        null
                                                                        ? relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.hn &&
                                                                        relation.relation_user_id.hn.name +
                                                                        " " +
                                                                        relation.relation_user_id.hn.surname
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                } else {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                                //  + " " + "Name"
                                                            }
                                                            name={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                            }
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.hn &&
                                                                    relation.prefix_id.hn.name
                                                                }
                                                                defaultValue={
                                                                    relation && relation.static_info && relation.static_info.hn !== ""
                                                                        ? relation &&
                                                                        relation.static_info &&
                                                                        relation.static_info.hn &&
                                                                        relation.static_info.hn.name
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                }
                                            } else {
                                                if (Object.keys(relation).includes("relation_user_id") === true) {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                                // + " " + "Name"
                                                            }
                                                            name={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                            }
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.en &&
                                                                    relation.prefix_id.en.name
                                                                }
                                                                defaultValue={
                                                                    (relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.en &&
                                                                        relation.relation_user_id.en.name !== "") ||
                                                                        null
                                                                        ? relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.en &&
                                                                        relation.relation_user_id.en.name +
                                                                        " " +
                                                                        relation.relation_user_id.en.surname
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                } else {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                                // + " " + "Name"
                                                            }
                                                            name={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                            }
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.en &&
                                                                    relation.prefix_id.en.name
                                                                }
                                                                defaultValue={
                                                                    (relation &&
                                                                        relation.static_info &&
                                                                        relation.static_info.en &&
                                                                        relation.static_info.en.name !== "") ||
                                                                        null
                                                                        ? relation &&
                                                                        relation.static_info &&
                                                                        relation.static_info.en &&
                                                                        relation.static_info.en.name
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                }
                                            }
                                        })
                                        : " "}

                                    <Form.Item label={t("Occupation")} initialValue={selectedOccupation} name="occupation">
                                        {/* {console.log("selected_occuption", selectedOccupation)} */}
                                        <Select
                                            mode="multiple"
                                            placeholder={t("Select_Occupation")}
                                            showSearch
                                            allowClear
                                            placement="bottomRight"
                                            style={{ width: "100%" }}
                                            notFoundContent={t("This_Occupation_is_Not_Exist")}
                                            optionFilterProp="children"
                                            defaultValue={[selectedOccupation]}
                                            value={selectedOccupation}
                                            onChange={setSelectedOccupation}
                                        >
                                            {getOccupationData && getOccupationData.length > 0
                                                ? getOccupationData.map((data) => {
                                                    return (
                                                        <Select.Option key={data._id} value={data._id} disabled>
                                                            {(data && data && data.hn.occupation !== "") || undefined || null
                                                                ? data && data[i18n.language] && data[i18n.language].occupation
                                                                : "-"}
                                                        </Select.Option>
                                                    );
                                                })
                                                : "-"}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label={t("Working_At")} name="current_working_at">
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item label={t("Hobbies")} name="hobbies">
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item name="phone_number" label={t("Contact_Number")}>
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item label={t("Email")} name="email">
                                        <Input size="small" />
                                    </Form.Item>
                                    <Form.Item name="currentAddress" label={t("Current_Address")}>
                                        <TextArea rows={1} />
                                    </Form.Item>
                                    <Form.Item name="currentLocation" label={t("Current_location")}>
                                        <TextArea rows={1} />
                                    </Form.Item>

                                    <Form.Item name="nativeLocation" label={t("Native_location")}>
                                        <TextArea rows={1} />
                                    </Form.Item>
                                    <Form.Item name="postal_address" label={t("Postal_Address")}>
                                        <TextArea rows={1} />
                                    </Form.Item>
                                    <Form.Item name="nanihalAddress" label={t("nanihal_Address")}>
                                        <TextArea rows={1} />
                                    </Form.Item>
                                    <Form.Item name="sasuralAddress" label={t("sasural_Address")}>
                                        <TextArea rows={1} />
                                    </Form.Item>
                                    <Form.Item label={t("About_Me")} name="about_me">
                                        <TextArea rows={1} />
                                    </Form.Item>
                                    <Form.Item label={t("Education")} name="education_master">
                                        <Input size="small" />
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>

                        <Col span={12} xs={12} sm={12} md={12} lg={12}>
                            <div className="Verification_form">
                                <Form className="Vform" {...formItemLayout} form={form} onFinish={handleSubmit}>
                                    <Form.Item label={t("User_Profile")} valuePropName="fileList" name="profile_photo">
                                        {usersRelations && usersRelations.profile_photo !== "" ? (
                                            <img
                                                src={`${usersRelations && usersRelations.profile_photo}`}
                                                width="60"
                                                height="60"
                                                className="addImg"
                                                style={{ border: "1px solid black" }}
                                                crossOrigin="anonymous"
                                            />
                                        ) : (
                                            <Avatar icon={<UserOutlined />} shape="square" style={{ backgroundColor: getRandomColor() }} size={64} />
                                        )}
                                    </Form.Item>

                                    <Form.Item label={t("Language")} name="lang_code">
                                        <Input
                                            size="small"
                                            defaultValue={usersRelations && usersRelations.lang_code == "" ? "-" : usersRelations?.lang_code}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t("UserName")}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter User Name!",
                                            },
                                            {
                                                pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                message: "field only accept string value(should be More than 1 character)!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("name") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Surname")}
                                        name="surname"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Surname!",
                                            },
                                            {
                                                pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                message: "field only accept string value(should be More than 1 character)!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("surname") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item label={t("Gender")} name="gender_id">
                                        <Select
                                            placeholder="Select Gender"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("gender_id") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        >
                                            {getdataGender &&
                                                getdataGender.map((val, index) => {
                                                    // console.log("genderid", val);
                                                    // console.log("indexGender", index);
                                                    if (updatedTrackInfo && updatedTrackInfo.lang_code !== "hn") {
                                                        return (
                                                            <Select.Option value={val && val._id} key={index}>
                                                                {val && val.hn && val.hn.name}
                                                            </Select.Option>
                                                        );
                                                    } else {
                                                        return (
                                                            <Select.Option value={val && val._id} key={index}>
                                                                {val && val.en && val.en.name}
                                                            </Select.Option>
                                                        );
                                                    }
                                                })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label={t("Birth_Date")}
                                        name="date_of_birth"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please_Pick_Birth_Date"),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            onChange={onChange}
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("date_of_birth") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                        minWidth: "100%",
                                                    }
                                                    : { background: "#fff", minWidth: "100%" }
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item label={t("Marital_Status")} name="marital_status_id">
                                        <Select
                                            placeholder="Select Marital Status"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("marital_status_id") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        >
                                            {new Array(DataOfMaritalStatus && DataOfMaritalStatus.length)
                                                .fill(DataOfMaritalStatus && DataOfMaritalStatus)
                                                .map((val, index) => {
                                                    if (updatedTrackInfo && updatedTrackInfo.lang_code !== "hn") {
                                                        return prop.target.en.marital_status_id !== "" || null || undefined ? (
                                                            <Select.Option value={val && val[index] && val[index]._id}>
                                                                {val && val[index] && val[index].hn && val[index].hn.marital_status}
                                                            </Select.Option>
                                                        ) : (
                                                            "-"
                                                        );
                                                    } else {
                                                        return prop.target.hn.marital_status_id !== "" || null || undefined ? (
                                                            <Select.Option value={val && val[index] && val[index]._id}>
                                                                {val && val[index] && val[index].en && val[index].en.marital_status}
                                                            </Select.Option>
                                                        ) : (
                                                            "-"
                                                        );
                                                    }
                                                })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label={t("Blood_Group")} name="blood_group_id">
                                        <Select
                                            placeholder="Select Blood Group"
                                            mode="single"
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            notFoundContent="This Gotra does Not Exist"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("Blood_Group") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        >
                                            {new Array(getBloodGroupData && getBloodGroupData.length)
                                                .fill(getBloodGroupData && getBloodGroupData)
                                                .map((val, index) => {
                                                    if (updatedTrackInfo && updatedTrackInfo.lang_code !== "hn") {
                                                        return prop.target.en.marital_status_id !== "" || null || undefined ? (
                                                            <Select.Option value={val && val[index] && val[index]._id}>
                                                                {val && val[index] && val[index].hn && val[index].hn.blood_group}
                                                            </Select.Option>
                                                        ) : (
                                                            "-"
                                                        );
                                                    } else {
                                                        return prop.target.en.marital_status_id !== "" || null || undefined ? (
                                                            <Select.Option value={val && val[index] && val[index]._id}>
                                                                {val && val[index] && val[index].en && val[index].en.blood_group}
                                                            </Select.Option>
                                                        ) : (
                                                            "-"
                                                        );
                                                    }
                                                })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label={t("Gotras")} name="gotra_master_id">
                                        <Select
                                            mode="single"
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            notFoundContent="This Gotra does not exist"
                                            placeholder="Select Gotra"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("gotra_master_id") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        >
                                            {new Array(dataGotra && dataGotra.length).fill(dataGotra && dataGotra).map((val, index) => {
                                                if (updatedTrackInfo && updatedTrackInfo.lang_code !== "hn") {
                                                    return (
                                                        <Select.Option
                                                            value={val && val[index] && val[index]._id}
                                                            key={val && val[index] && val[index]._id}
                                                        >
                                                            {val && val[index] && val[index].hn && val[index].hn.name}
                                                        </Select.Option>
                                                    );
                                                } else {
                                                    return (
                                                        <Select.Option
                                                            value={val && val[index] && val[index]._id}
                                                            key={val && val[index] && val[index]._id}
                                                        >
                                                            {val && val[index] && val[index].en && val[index].en.name}
                                                        </Select.Option>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </Form.Item>

                                    {relationMasterData && relationMasterData.length > 0
                                        ? relationMasterData && relationMasterData.map((relation, index) => {

                                            if (updatedTrackInfo && updatedTrackInfo.lang_code === "hn") {
                                                if (Object.keys(relation).includes("relation_user_id") === true) {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                                //  + " " + "Name"
                                                            }
                                                            name={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id.en &&
                                                                relation.relation_master_id.en.name
                                                            }
                                                        >
                                                            <Input
                                                                size="small"
                                                                disabled
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.en &&
                                                                    relation.prefix_id.en.name
                                                                }
                                                                defaultValue={
                                                                    (relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.en &&
                                                                        relation.relation_user_id.en.name !== "") ||
                                                                        null
                                                                        ? relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.en &&
                                                                        relation.relation_user_id.en.name +
                                                                        " " +
                                                                        relation.relation_user_id.en.surname
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                } else {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                                // + " " + "Name"
                                                            }
                                                            name={"static_info_" + `${relation && relation._id}`}
                                                            rules={[
                                                                {
                                                                    pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                                    message: "field only accept string value(should be More than 1 character)!",
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.en &&
                                                                    relation.prefix_id.en.name
                                                                }
                                                                defaultValue={
                                                                    (relation &&
                                                                        relation.static_info &&
                                                                        relation.static_info.en &&
                                                                        relation.static_info.en.name !== "") ||
                                                                        null
                                                                        ? relation &&
                                                                        relation.static_info &&
                                                                        relation.static_info.en &&
                                                                        relation.static_info.en.name
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                }
                                            } else {
                                                if (Object.keys(relation).includes("relation_user_id") === true) {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                                //  + " " + "Name"
                                                            }
                                                            name={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id.en &&
                                                                relation.relation_master_id.en.name
                                                            }
                                                        >
                                                            <Input
                                                                size="small"
                                                                disabled
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.hn &&
                                                                    relation.prefix_id.hn.name
                                                                }
                                                                defaultValue={
                                                                    (relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.hn &&
                                                                        relation.relation_user_id.hn.name !== "") ||
                                                                        null
                                                                        ? relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.hn &&
                                                                        relation.relation_user_id.hn.name +
                                                                        " " +
                                                                        relation.relation_user_id.hn.surname
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                } else {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[i18n.language] &&
                                                                relation.relation_master_id[i18n.language].name
                                                                // + " " + "Name"
                                                            }
                                                            name={"static_info_" + `${relation && relation._id}`}
                                                            rules={[
                                                                {
                                                                    pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                                    message: "field only accept string value(should be More than 1 character)!",
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.hn &&
                                                                    relation.prefix_id.hn.name
                                                                }
                                                                defaultValue={
                                                                    relation &&
                                                                        relation.static_info &&
                                                                        relation.static_info.hn &&
                                                                        relation.static_info.hn.name !== ""
                                                                        ? relation &&
                                                                        relation.static_info &&
                                                                        relation.static_info.hn &&
                                                                        relation.static_info.hn.name
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                }
                                            }
                                        })
                                        : ""}

                                    <Form.Item label={t("Occupation")} name="occupation">
                                        <Select
                                            mode="multiple"
                                            placeholder="Select Occupation"
                                            name="_id"
                                            showSearch
                                            allowClear
                                            placement="bottomRight"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("occupation") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                        width: "100%",
                                                    }
                                                    : { background: "#fff", width: "100%" }
                                            }
                                            notFoundContent="This Occupation is Not Exist"
                                            optionFilterProp="children"
                                        >
                                            {getOccupationData && getOccupationData.length > 0
                                                ? getOccupationData.map((data) => {
                                                    if (updatedTrackInfo && updatedTrackInfo.lang_code !== "hn") {
                                                        return (
                                                            <Select.Option key={data._id} value={data._id}>
                                                                {(data && data.hn && data.hn.occupation !== "") || undefined || null
                                                                    ? data && data.hn && data.hn.occupation
                                                                    : data && data.en && data.en.occupation}
                                                            </Select.Option>
                                                        );
                                                    } else {
                                                        return (
                                                            <Select.Option key={data._id} value={data._id}>
                                                                {(data && data.en && data.en.occupation !== "") || undefined || null
                                                                    ? data && data.en && data.en.occupation
                                                                    : data && data.hn && data.hn.occupation}
                                                            </Select.Option>
                                                        );
                                                    }
                                                })
                                                : "-"}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Working_At")}
                                        name="current_working_at"
                                        rules={
                                            [
                                                // {
                                                //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                //     message: "No space allowed at beginning and end!!*",
                                                // },
                                            ]
                                        }
                                    >
                                        <Input
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("current_working_at") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Hobbies")}
                                        name="hobbies"
                                        rules={
                                            [
                                                // {
                                                //     pattern: new RegExp(/^(?!\s)[\w\s]*[^\s]$/),
                                                //     message: "No space allowed at beginning and end!!*",
                                                // },
                                            ]
                                        }
                                    >
                                        <Input
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("hobbies") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone_number"
                                        label={t("Contact_Number")}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter contact number*!",
                                            },
                                            {
                                                pattern: new RegExp(/^[0-9]+$/),
                                                message: "field only accept numbers (No space allowed!!*)",
                                            },
                                            {
                                                max: 11,
                                                message: "Maximum 10 Numbers are allowed!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("phone_number") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Email")}
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Email ID*!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("email") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="currentAddress"
                                        label={t("Current_Address")}
                                        rules={[
                                            {
                                                message: "Please enter valid Address(No space allowed at beginning and end)!!*",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={1}
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("currentAddress") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="currentLocation"
                                        label={t("Current_location")}
                                        rules={[
                                            {
                                                message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={1}
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("currentLocation") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="nativeLocation"
                                        label={t("Native_location")}
                                        rules={[
                                            {
                                                message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={1}
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("nativeLocation") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="postal_address"
                                        label={t("Postal_Address")}
                                        rules={[
                                            {
                                                message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={1}
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("postal_address") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="nanihalAddress"
                                        label={t("nanihal_Address")}
                                        rules={[
                                            {
                                                message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={1}
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("postal_address") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="sasuralAddress"
                                        label={t("sasural_Address")}
                                        rules={[
                                            {
                                                message: "Please enter valid Location(No space allowed at beginning and end)!!*",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={1}
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("postal_address") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t("About_Me")}
                                        name="about_me"
                                        rules={[
                                            {
                                                pattern: new RegExp(/^\S(?:.*\S)?$/),
                                                message: "No space allowed at beginning and end!!*",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={1}
                                            size="small"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("about_me") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item label={t("Education")} name="education_master">
                                        <Select
                                            mode="single"
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            notFoundContent="This education does not exist"
                                            placeholder="Select Education"
                                            style={
                                                updatedKeyArr && updatedKeyArr.includes("education_master") == true
                                                    ? {
                                                        border: "2px solid #9ddef7",
                                                        background: "#f4ffff",
                                                    }
                                                    : { background: "#fff" }
                                            }
                                        >
                                            {new Array(getdataEducation && getdataEducation.length)
                                                .fill(getdataEducation && getdataEducation)
                                                .map((val, index) => {
                                                    if (updatedTrackInfo && updatedTrackInfo.lang_code !== "hn") {
                                                        return (
                                                            <Select.Option
                                                                value={val && val[index] && val[index]._id}
                                                                key={val && val[index] && val[index]._id}
                                                            >
                                                                {val && val[index] && val[index].hn && val[index].hn.education}
                                                            </Select.Option>
                                                        );
                                                    } else {
                                                        return (
                                                            <Select.Option
                                                                value={val && val[index] && val[index]._id}
                                                                key={val && val[index] && val[index]._id}
                                                            >
                                                                {val && val[index] && val[index].en && val[index].en.education}
                                                            </Select.Option>
                                                        );
                                                    }
                                                })}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                }

            </Modal>

        </>
    );
}

export default updateDeatilsModal;
