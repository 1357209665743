import Cookies from 'js-cookie';
import actions from './actions';
const { GET_MARITALSTATUS,ADD_MARITALSTATUS,UPDATE_MARITALSTATUS,DELETE_MARITALSTATUS} = actions;

const initState = {
 maritalStatus:{},
 addMaritalstatus:{}
};
const MaritalStatusReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) { 
    case GET_MARITALSTATUS:
      return {
        ...state,
        maritalStatus:data,
      };
    case ADD_MARITALSTATUS:
      return {
        ...state,
        addMaritalstatus:data,
      };
    case UPDATE_MARITALSTATUS:
      return {
        ...state,
        addMaritalstatus:data,
      };
    case DELETE_MARITALSTATUS:
      return {
        ...state,
        maritalstatus:data,
      };
   
    default:
      return state;
  }
};
export default MaritalStatusReducer;
