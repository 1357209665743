import React from "react";
import { PageHeader } from "antd";
import { Table, Row, Col, Button, Modal, Form, Input, Tooltip, Pagination, Empty, Spin } from "antd";
import "./style.css";
import { PlusOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Get_Gotra_Data, Add_Gotra_Data, Update_Gotra_data, Delete_Gotra_data } from "../../redux/gotra/actionCreator";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";

const Gotras = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState("Add Gotra");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const dispatch = useDispatch();

    const getGotraListData = useSelector((state) => state.GotraReducer.gotraData);
    const getGotraData = getGotraListData && getGotraListData.data?.list;
    const GotraPagination = getGotraListData && getGotraListData?.data;

    useEffect(
        () => {
            getDataGotra(currentPage);
        },
        [dataPerPage],
        []
    );
    const getDataGotra = (currentPage) => {
        let params = {
            search: "",
            page: currentPage,
            limit: dataPerPage,
        };
        dispatch(Get_Gotra_Data(params));
    };

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getDataGotra(page);
        setDataPerPage(pageNumber);
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        setModalTitle(t("Add_Gotra"));
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };
    const showEditModal = (e) => {
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Gotra"));
        form.setFieldsValue({
            en_gotra: e.en.name,
            hn_gotra: e.hn.name,
        });
    };

    const deleteGotraData = async (values) => {
        let a = await dispatch(Delete_Gotra_data(values));
        if (a) {
            getDataGotra();
        }
    };

    const handleSubmit = async (value) => {
        setIsModalOpen(false);
        form.resetFields();
        const obj = {
            en: {},
            hn: {},
        };

        obj.en.name = value.en_gotra;
        obj.hn.name = value.hn_gotra;

        if (updateIdRef.current === "") {
            const AddGotraData = await dispatch(Add_Gotra_Data(obj));
            if (AddGotraData) {
                getDataGotra();
            }
        } else {
            const obj = {
                en: {},
                hn: {},
            };
            obj.en.name = value.en_gotra;
            obj.hn.name = value.hn_gotra;
            const updateGotraData = await dispatch(Update_Gotra_data(obj, updateIdRef.current));
            if (updateGotraData) {
                getDataGotra(currentPage, dataPerPage);
                setupdateID("");
            }
        }
    };

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    const columns = [
        {
            title: t("Gotra_Name"),
            dataIndex: "name",
            align: "center",
            sorter: (a, b) => {
                return a.en.name.localeCompare(b.en.name);
            },
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].name}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, t) => {
                return (
                    <>
                        <Tooltip placement="right">
                            <FeatherIcon
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(t);
                                    setIsModalOpen(true);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Gotras")}
                    extra={[
                        <Button key="1" type="primary" onClick={showModal}>
                            <PlusOutlined /> {t("Add_Gotra")}
                        </Button>,
                    ]}
                />
            </div>
            <div>
                <Modal open={isModalOpen} okText={t("Submit")} cancelText={t("Cancel")} onCancel={handleCancel} title={modalTitle} onOk={form.submit}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                                <Form.Item
                                    label={t("Enter_Gotra_In_English")}
                                    name="en_gotra"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[a-zA-Z ./{}()-]+$/i),
                                            message: t("Field_Accept_Only_English_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Gotra_Name")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("Enter_Gotra_In_Hindi")}
                                    name="hn_gotra"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[\u0900-\u097F ./{}()-]+$/i),
                                            message: t("Field_Accept_Only_Hindi_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Gotra_Name")} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getGotraData && getGotraData.length > 0 ? (
                            <>
                                <div className="master_table">
                                    <Table showSorterTooltip={false} columns={columns} dataSource={getGotraData} pagination={false} />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                        <Pagination
                                            total={GotraPagination.total_counts}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            pageSize={dataPerPage}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            showSizeChanger
                                            currentPage={currentPage}
                                            onChange={handlePagination}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getGotraListData).length == 0 || (getGotraData && getGotraData.length === undefined) ? (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <div className="master_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getGotraData}
                                    pagination={false}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Gotras;
