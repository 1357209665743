import Cookies from 'js-cookie';
import actions from './actions';
const { GET_RELATIONSHIP_COMBINATION,ADD_RELATIONSHIP_COMBINATION,UPDATE_RELATIONSHIP_COMBINATION,DELETE_RELATIONSHIP_COMBINATION} = actions;

const initState = {
    relationshipList:{},
    addRelationshipList:{}
};
const RelationShipCombinationReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) { 
    case GET_RELATIONSHIP_COMBINATION:
      return {
        ...state,
        relationshipList:data,
      };
    case ADD_RELATIONSHIP_COMBINATION:
      return {
        ...state,
        addRelationshipList:data,
      };
    case UPDATE_RELATIONSHIP_COMBINATION:
      return {
        ...state,
        addRelationshipList:data,
      };
    // case DELETE_RELATIONSHIP_COMBINATIONy:
    //   return {
    //     ...state,
    //     userRelationshipList:data,
    //   };
   
    default:
      return state;
  }
};
export default RelationShipCombinationReducer;
