import React from "react";
import useStateRef from "react-usestateref";
import { PageHeader } from "antd";
import { Table, Row, Col, Button, Modal, Form, Input, Tooltip, Pagination, Empty, Spin } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import "./style.css";
import FeatherIcon from "feather-icons-react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Get_Education_Data, Add_Education_Data, Update_Education_data } from "../../redux/education/actionCreator";

const { Search } = Input;

const Education = () => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [modalTitle, setModalTitle] = useState("Add Education");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");

    const getDataEducationList = useSelector((state) => state.EducationReducer.educationData);
    const getdataEducation = getDataEducationList && getDataEducationList?.data?.list;
    const EducationPagination = getDataEducationList && getDataEducationList?.data;

    useEffect(
        () => {
            getEducationdata(currentPage, searchValue);
        },
        [dataPerPage, searchValue],
        []
    );
    const getEducationdata = (currentPage, pageNumber) => {
        if (searchValue == "") {
            let params = {
                search: searchValue,
                page: currentPage,
                limit: dataPerPage,
            };
            dispatch(Get_Education_Data(params));
        } else {
            let params = {
                search: searchValue,
                page: "",
                limit: "",
            };
            dispatch(Get_Education_Data(params));
        }
    };

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getEducationdata(page);
        setDataPerPage(pageNumber);
    };

    const onSearch = async (value) => {
        if (value) {
            setSearchValue(value.trim());
            getdataEducation("", "", value.trim());
        }
    };

    const searchClear = () => {
        getdataEducation("");
        setSearchValue();
    };

    const showModal = () => {
        setupdateID("");
        setIsModalOpen(true);
        setModalTitle(t("Add_Education"));
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };
    const showEditModal = (e) => {
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Education"));
        form.setFieldsValue({
            en_education: e.en.education,
            hn_education: e.hn.education,
        });
    };

    const handleSubmit = async (value) => {
        setIsModalOpen(false);
        form.resetFields();
        const obj = {
            en: {},
            hn: {},
        };

        obj.en.education = value.en_education;
        obj.hn.education = value.hn_education;

        if (updateIdRef.current === "") {
            const AddEducationData = await dispatch(Add_Education_Data(obj));
            if (AddEducationData) {
                getEducationdata();
            }
        } else {
            const obj = {
                en: {},
                hn: {},
            };
            obj.en.education = value.en_education;
            obj.hn.education = value.hn_education;
            const updateEducationData = await dispatch(Update_Education_data(obj, updateIdRef.current));
            if (updateEducationData) {
                getEducationdata(currentPage, dataPerPage);
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("Education"),
            dataIndex: "education",
            align: "center",
            sorter: (a, b) => {
                return a.en.education.localeCompare(b.en.education);
            },
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].education}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, t) => {
                return (
                    <>
                        <Tooltip placement="right">
                            <FeatherIcon
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(t);
                                    setIsModalOpen(true);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Education")}
                    extra={[
                        <>
                            <Search
                                className="searchBox"
                                placeholder={t("search_by_title")}
                                enterButton
                                size="small"
                                onSearch={(e) => onSearch(e)}
                                onChange={(e) => setSearchValue(e.target.value)}
                                allowClear={{
                                    clearIcon: <CloseOutlined onClick={searchClear} />,
                                }}
                            />
                            <Button key="1" type="primary" onClick={showModal}>
                                <PlusOutlined /> {t("Add_Education")}
                            </Button>
                        </>,
                    ]}
                />
            </div>
            <div>
                <Modal open={isModalOpen} okText={t("Submit")} cancelText={t("Cancel")} onCancel={handleCancel} title={modalTitle} onOk={form.submit}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                                <Form.Item
                                    label={t("Enter_Education_In_English")}
                                    name="en_education"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[a-zA-Z 0-9./{}()-]+$/i),
                                            message: t("Field_Accept_Only_English_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Education")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("Enter_Education_In_Hindi")}
                                    name="hn_education"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[\u0900-\u097F 0-9 ./{}()-]+$/i),
                                            message: t("Field_Accept_Only_Hindi_String_Value"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Education")} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getdataEducation && getdataEducation.length > 0 ? (
                            <>
                                <div className="master_table">
                                    <Table
                                        showSorterTooltip={false}
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getdataEducation}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24}>
                                        <Pagination
                                            total={EducationPagination.total_counts}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            pageSize={dataPerPage}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            showSizeChanger
                                            currentPage={currentPage}
                                            onChange={handlePagination}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getDataEducationList).length == 0 ||
                          (getdataEducation && getdataEducation.length === undefined) ||
                          (getdataEducation && getdataEducation == "") ? (
                            <div className="master_table">
                                <Table
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getdataEducation}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Education;
