import React, { useRef, useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Tooltip,
    Form,
    Modal,
    Avatar,
    Table,
    Divider,
    Input,
    Space,
    Checkbox,
    Carousel,
    Radio,
    Select,
    Cascader,
    DatePicker,
    Spin,
    message
} from "antd";
import useStateRef from "react-usestateref";
import {
    PlusOutlined,
    EyeOutlined,
    SafetyCertificateTwoTone,
    MinusCircleOutlined,
    UserOutlined,
    ConsoleSqlOutlined,
    EditTwoTone,
    LoadingOutlined
} from "@ant-design/icons";
import { getRandomColor, getDateFormate } from "../../utility/commonFunction";
import { SafetyOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    getUserStatusData,
    putUserDetailsUpdate,
    updateUserDetailTrack,
    get_users_details,
} from "../../redux/AdminPannel/actionCreator";
import { bloodGroup } from "../../redux/bloodGroup/actionCreator";
import { maritalstatus } from "../../redux/maritalStatus/actionCreator";
import { prefix } from "../../redux/prefix/acrionCreator";
import { Get_Gotra_Data } from "../../redux/gotra/actionCreator";
import { Get_Education_Data } from "../../redux/education/actionCreator";
import { getOccupationList } from "../../redux/OccupationMaster/actionCreator";
import {
    update_User_Relationship,
    update_User_details_Relationship,
    user_relationship,
} from "../../redux/relationship/actionCreator";
import { Get_Gender_Data } from "../../redux/gender/actionCreator";
import { Left, VerticalAlignTop } from "antd-icons";
import { HorizontalBar } from "react-chartjs-2";
import moment from "moment";
import { log } from "@antv/g2plot/lib/utils";

// const { RangePicker } = DatePicker;
const { TextArea } = Input;


const VerifiedUserEditModal = (prop, ref) => {
    // //console.lo("🚀 ~ file: VerifiedUserEditModal.js:65 ~ VerifiedUserEditModal ~ prop:", prop.target)
    let { showVerificationModal, setShowVerificationModal } = prop;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const myRef = useRef();
    const [left_form] = Form.useForm();
    const [right_form] = Form.useForm();
    const [counter, setCounter] = useState(0);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId, selectedIdRef] = useStateRef("");
    const [selectedOccupation, setSelectedOccupation] = useState([]);
    const [GetDetailByIdApi, setGetDetailByIdApi] = useState({});
    // //console.lo("🚀 ~ file: VerifiedUserEditModal.js:79 ~ VerifiedUserEditModal ~ GetDetailByIdApi:", GetDetailByIdApi)

    // const GetDetailByIdApi = useSelector(
    //     (state) => state.UsersStatusReducer.getUserDetail
    // );

    //console.lo("selectedOccupaion>>>>>>",selectedOccupation);

    const getOccupationData = useSelector(
        (state) => state?.OccupationMasterReducer?.OccupationMaster?.data?.list
    );
    const getBloodGroupData = useSelector(
        (state) => state?.BloodGroupReducer?.bloodGroup?.data?.list
    );
    const DataOfMaritalStatus = useSelector(
        (state) => state?.MaritalStatusReducer?.maritalStatus?.data?.list
    );
    const dataGotra = useSelector(
        (state) => state?.GotraReducer?.gotraData?.data?.list
    );

    const getDataEducationList = useSelector(
        (state) => state.EducationReducer.educationData
    );
    const getdataEducation =
        getDataEducationList && getDataEducationList?.data?.list;

    // const usersRelations = GetDetailByIdApi && GetDetailByIdApi;
    const relationMasterData = GetDetailByIdApi && GetDetailByIdApi.userRelationData;

    //console.lo("relationMasterData",relationMasterData);
    const educationQualification =
        GetDetailByIdApi && GetDetailByIdApi.hn && GetDetailByIdApi.hn.name === ""
            ? GetDetailByIdApi &&
            GetDetailByIdApi.en &&
            GetDetailByIdApi.en.education_qualification
            : GetDetailByIdApi &&
            GetDetailByIdApi.hn &&
            GetDetailByIdApi.hn.education_qualification;

    const en_qualification =
        GetDetailByIdApi &&
        GetDetailByIdApi.en &&
        GetDetailByIdApi.en.education_qualification;
    const hn_qualification =
        GetDetailByIdApi &&
        GetDetailByIdApi.hn &&
        GetDetailByIdApi.hn.education_qualification;

    const getDataGenderList = useSelector(
        (state) => state.GenderReducer.genderData.data
    );
    const getdataGender =
        getDataGenderList && getDataGenderList && getDataGenderList.list;


    useEffect(() => {
        setLoading(true);
        updateUsersStatusData();
        UserGetDetailsList(prop?.target._id);
        filedvaluesModal(prop?.target);

        setSelectedId(
            prop && prop.target && prop.target._id !== undefined
                ? prop.target._id
                : ""
        );
        // for left side form
        // if (prop.target.hn.name !== '') {
        // filedvaluesModal(prop?.target);

    }, [prop?.target._id]);

    useEffect(() => {
        getBloodGroup_data();
        OccupationMasterData();
        getMaritalStatus_data();
        GotraMasterData();
        getGenderdata();
        getEducationData();
        // filedvaluesModal(prop?.target);
    }, []);
    // useEffect(() => {
    //     filedvaluesModal(prop?.target);
    // }, [prop?.target]);
    const filedvaluesModal = (value) => {
        //console.log("🚀 ~ file: verifiedModal.js:175 ~ filedvaluesModal ~ value:", value)
        form.setFieldsValue({
            profile_photo: GetDetailByIdApi && GetDetailByIdApi.profile_photo,
            // value && value.lang_code == "" ? "-" : value && value.lang_code == "en" ? "English" : "Hindi"
            // lang_code: usersRelations && usersRelations.lang_code == "" ? "-" : usersRelations && usersRelations.lang_code == "en" ? t("English") : t("Hindi"),
            en_lang: t("English"),
            hn_lang: t("Hindi"),
            en_name: value && value.en && value.en.name,
            hn_name: value && value.hn && value.hn.name,
            en_surname: value && value.en && value.en.surname,
            hn_surname: value && value.hn && value.hn.surname,
            gender: value && value.gender_id && value.gender_id?._id,
            birthdate: moment(value && value.date_of_birth).format("DD/MM/YYYY"),
            marital_status: value && value?.marital_status_id && value.marital_status_id?._id,
            blood_group: value && value?.blood_group_id && value.blood_group_id?._id,
            education_master: value && value?.education_id && value.education_id?._id,
            gotra_master: value && value?.gotra_master_id && value?.gotra_master_id?._id,
            en_current_working_at: value?.en?.current_working_at !== '' ? value && value.en && value.en.current_working_at : '-',
            hn_current_working_at: value?.hn?.current_working_at !== '' ? value && value.hn && value.hn.current_working_at : '-',
            en_hobbies: value && value.en && value.en.hobbies,
            hn_hobbies: value && value.hn && value.hn.hobbies,
            phone_number: value && value.phone_number,
            email: value && value.email,
            en_currentAddress: value && value.en && value.en.currentAddress,
            hn_currentAddress: value && value.hn && value.hn.currentAddress,
            en_currentLocation: value && value.en && value.en.currentLocation,

            en_nanihalAddress: value && value.en && value.en.nanihalAddress,
            en_sasuralAddress: value && value.en && value.en.sasuralAddress,

            hn_nanihalAddress: value && value.hn && value.hn.nanihalAddress,
            hn_sasuralAddress: value && value.hn && value.hn.sasuralAddress,

            hn_currentLocation: value && value.hn && value?.hn?.currentLocation,
            en_nativeLocation: value && value.en && value?.en?.nativeLocation,
            hn_nativeLocation: value && value.hn && value?.hn?.nativeLocation,
            en_postal_address: value && value.en && value?.en?.postal_address,
            hn_postalAddress: value && value.hn && value?.hn?.postal_address,
            en_about_me: value && value.en && value.en.about_me,
            hn_about_me: value && value.hn && value.hn.about_me,

            // occupation: value && value?.occupation && value?.occupation[i18n.language] && value?.occupation[i18n.language].occupation
        });

        let occupationArray = [];
        if (
            value &&
            value.occupation &&
            value.occupation.length > 0
        ) {
            value?.occupation?.map((ocu) => {
                occupationArray.push(ocu._id);
            });
        }
        //console.lo("occupationArray",occupationArray);
        setSelectedOccupation(occupationArray);
        //console.log("value?.userRelationData", value?.userRelationData);
        if (
            Array.isArray(value?.userRelationData) &&
            value.userRelationData.length > 0
        ) {
            for (var value of value.userRelationData) {
                let relationFieldValue = {};

                relationFieldValue = {
                    [value &&
                        value.relation_master_id &&
                        value.relation_master_id &&
                        `static_info_en${value._id}`]:
                        (value &&
                            value.static_info &&
                            value.static_info.en &&
                            value.static_info.en.name !== "") ||
                            null
                            ? value &&
                            value.static_info &&
                            value.static_info.en &&
                            value.static_info.en.name
                            : "-",

                    [value &&
                        value.relation_master_id &&
                        value.relation_master_id &&
                        `static_info_hn${value._id}`]:
                        (value &&
                            value.static_info &&
                            value.static_info.hn &&
                            value.static_info.hn.name !== "") ||
                            null
                            ? value &&
                            value.static_info &&
                            value.static_info.hn &&
                            value.static_info.hn.name
                            : "-",
                };
                form.setFieldsValue(relationFieldValue);
                // } else {

                //   relationFieldValue = {
                //     ["static_info_en" + value && value._id]:
                //       value &&
                //       value.static_info &&
                //       value.static_info.en &&
                //       value.static_info.en.name,
                //   };
                // }
            }

        }
    };
    const getEducationData = () => {
        let params = {
            search: "",
            page: "",
            limit: "",
        };
        dispatch(Get_Education_Data(params));
    };

    const getGenderdata = () => {
        dispatch(Get_Gender_Data());
    };

    const updateUsersStatusData = async () => {
        let params = {
            // (search_Val.match(/^[a-zA-Z]+$/) && userStatus) ? search_Val : ''
            english: prop.english.match(/^[a-zA-Z]+$/) ? prop.english : "",
            hindi: prop.hindi.match(/^[\u0900-\u097F]+$/) ? prop.hindi : "",
            page: prop.currentPage,
            limit: prop.pageLimit,
            status: prop.target.status,
        };
        await dispatch(getUserStatusData(params));
    };
    const UserGetDetailsList = async (id) => {
        const data = await dispatch(get_users_details(prop?.target._id));
        ////console.lo("🚀 ~ file: verifiedModal.js:293 ~ UserGetDetailsList ~ data:", data)
        if (data && data?.data) {
            setGetDetailByIdApi(data?.data);
            setLoading(false)
        }
    };
    // ////console.lo("GetDetailByIdApi******************",GetDetailByIdApi);
    const updatedDetailsTrack = async () => {
        dispatch(updateUserDetailTrack());
    };

    const getBloodGroup_data = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(bloodGroup(params));
    };
    const OccupationMasterData = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(getOccupationList(params));
    };

    const getMaritalStatus_data = () => {
        let params = {
            search: "",
            page: "",
            limit: "",
        };
        dispatch(maritalstatus(params));
    };
    const GotraMasterData = () => {
        let params = {
            page: "",
            limit: "",
        };
        dispatch(Get_Gotra_Data(params));
    };

    const formItemLayout = {
        labelCol: {
            xl: {
                span: 9,
            },
            lg: {
                span: 9,
            },
        },
        wrapperCol: {
            xl: {
                span: 15,
            },
            lg: {
                span: 15,
            },
        },
    };

    const handleCancel = () => {
        setShowVerificationModal(false);
        form.resetFields();
    };

    const handleSubmit = async (val) => {
        //console.log("valueofhandleSubmit88888888888888888", val);

        delete val.profile_photo;
        delete val.occupation;

        const form_data = new FormData();
        for (let key in val) {
            form_data.append(key, val[key]);
        }
        for (const pair of form_data.entries()) {
        }

        for (let data of Object.keys(val)) {
            //console.log("data", data);
            if (data.includes("relation_id_")) {
                let setupId = data.includes("relation_id_en")
                    ? `${data.split("_")[3]}_${data.split("_")[4]}`
                    : "";
                let payload = {
                    static_info: {
                        en: {
                            name: val[`relation_id_en_${setupId}`],
                        },
                        hn: {
                            name: val[`relation_id_hn_${setupId}`],
                        },
                    },
                };
                //console.log("payload>>>>>", setupId);
                //console.log("staticInfo>>>>", static_info);

                if (data.includes("relation_id_en") && setupId.split("_")[0]) {
                    const updateUserStaticRelInfo = dispatch(
                        update_User_details_Relationship(payload, setupId.split("_")[0])
                    );
                }
            }
        }

        var z = Object.keys(val)
            .filter(function (k) {
                return k.indexOf("static_info") == 0;
            })
            .reduce(function (newData, k) {
                newData[k] = val[k];
                return newData;
            }, {});
        // const filteredObject = Object.fromEntries(
        //     Object.entries().filter(([key, value]) => value !== undefined)
        // );
        // //console.log("🚀 ~ file: VerifiedUserEditModal.js:424 ~ handleSubmit ~ filteredObject:", filteredObject)

        let enName = []
        let hnName = []
        let payloadId = []
        //console.log("zzzzzzzzzzzzz", z);
        Object.entries(z).map(([key, value], index) => {
            // //console.log("🚀 ~ file: VerifiedUserEditModal.js:429 ~ Object.entries ~ key:", key.split("_").pop().slice(0, 2))
            if (!payloadId.includes(key?.split("_").pop().slice(2))) {
                payloadId.push(key?.split("_").pop().slice(2))
            }

            if (key?.split("_").pop().slice(0, 2) === "en") {
                if (value == undefined || value == "undefined") {
                    let findExistingEn = prop?.target?.userRelationData.find((e) => e._id == key?.split("_").pop().slice(2))
                    enName.push(findExistingEn['static_info']['en']['name'])
                } else {
                    enName.push(value)
                }
            }
            if (key?.split("_").pop().slice(0, 2) === "hn") {
                if (value == undefined || value == "undefined") {
                    let findExistingHn = prop?.target?.userRelationData.find((e) => e._id == key?.split("_").pop().slice(2))
                    hnName.push(findExistingHn['static_info']['hn']['name'])
                } else {
                    hnName.push(value)
                }
            }

        });
        //console.log("🚀 ~ file: VerifiedUserEditModal.js:429 ~ handleSubmit ~ payloadId:", payloadId, enName, hnName)
        payloadId.map((val, index) => {
            let payload = {
                static_info: {
                    en: { name: enName[index] ? enName[index] : "" },
                    hn: { name: hnName[index] ? hnName[index] : "" }
                }
            }
            //console.log("🚀 ~ file: VerifiedUserEditModal.js:447 ~ enName.map ~ payload:", payload)
            if (selectedIdRef.current !== "") {
                const updateUserStaticRelInfo = dispatch(
                    update_User_Relationship(payload, val)
                );
            }
        })

        const obj = {
            en: {},
            hn: {},
        };

        obj.en.name = val.en_name;
        obj.hn.name = val.hn_name;

        obj.en.surname = val.en_surname;
        obj.hn.surname = val.hn_surname;
        obj.daughter = val.Daughter;
        obj.en.current_working_at = val.en_current_working_at;
        obj.hn.current_working_at = val.hn_current_working_at;
        obj.en.currentAddress = val.en_currentAddress;
        obj.hn.currentAddress = val.hn_currentAddress;
        obj.en.currentLocation = val.en_currentLocation;
        obj.hn.currentLocation = val.hn_currentLocation;

        obj.hn.nanihalAddress = val.hn_nanihalAddress;
        obj.hn.sasuralAddress = val.hn_sasuralAddress;

        obj.en.nanihalAddress = val.en_nanihalAddress;
        obj.en.sasuralAddress = val.en_sasuralAddress;

        obj.en.postal_address = val.en_postal_address;
        obj.hn.postal_address = val.hn_postalAddress;
        obj.en.nativeLocation = val.en_nativeLocation;
        obj.hn.nativeLocation = val.hn_nativeLocation;
        obj.en.about_me = val.en_about_me;
        obj.hn.about_me = val.hn_about_me;
        obj.en.hobbies = val.en_hobbies;
        obj.hn.hobbies = val.hn_hobbies;
        obj.gender_id = val.gender;
        obj.marital_status_id = val.marital_status;
        obj.blood_group_id = val.blood_group;
        obj.gotra_master_id = val.gotra_master;
        obj.education_id = val.education_master;
        // obj.occupation = selectedOccupation;
        obj.email = val.email;
        obj.phone_number = val.phone_number;
        obj.date_of_birth = val.birthdate;
        obj.status = "verified";

        form_data.append("occupation", JSON.stringify(selectedOccupation))

        form_data.append("en", JSON.stringify(obj.en))
        form_data.append("hn", JSON.stringify(obj.hn))

        for (var pair of form_data.entries()) {
            //console.log("**************************", pair[0] + ', ' + pair[1]);
        }

        //console.log("payloadObject", obj);

        if (selectedIdRef.current !== "") {
            const updateUserStatusToVerify = await dispatch(
                putUserDetailsUpdate(form_data, selectedIdRef.current)
            );

            if (updateUserStatusToVerify) {
                updateUsersStatusData();
                setSelectedId("");
                setShowVerificationModal(false);
                form.resetFields();
            }
        }
    };

    return (
        <Modal
            open={showVerificationModal}
            bodyStyle={{ paddingTop: 0 }}
            title={t("Update_User_Details")}
            okText={t("Submit")}
            onOk={form.submit}
            cancelText={t("Cancel")}
            onCancel={handleCancel}
            width={900}
        >
            {loading ? (
                <div style={{ height: '50px' }}>
                    <Spin />
                </div>

            ) :
                <div
                //  className="Verification_form_left"
                >
                    <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
                        <Row>
                            <Col xxl={12} xs={12} sm={12} md={12} lg={12}>
                                <div className="left_form">
                                    <Form
                                        className="Vform"
                                        {...formItemLayout}
                                        form={form}
                                        onFinish={handleSubmit}
                                    >
                                        <Form.Item label={t("Language")} name="en_lang">
                                            <Input size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("User_Profile")}
                                            valuePropName="fileList"
                                            name="profile_photo"
                                        >
                                            {prop.target.profile_photo !== "" ? (
                                                <img
                                                    src={`${prop.target.profile_photo}`}
                                                    width="60"
                                                    height="60"
                                                    className="addImg"
                                                    style={{ border: "1px solid black" }}
                                                    crossOrigin="anonymous"
                                                />
                                            ) : (
                                                <Avatar
                                                    icon={<UserOutlined />}
                                                    shape="square"
                                                    style={{ backgroundColor: getRandomColor() }}
                                                    size={64}
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            label={t("UserName")}
                                            name="en_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter User Name!",
                                                },
                                            ]}
                                        >
                                            <Input size="small" ref={myRef} />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("Surname")}
                                            name="en_surname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter Surname!",
                                                },
                                            ]}
                                        >
                                            <Input size="small" />
                                        </Form.Item>

                                        {relationMasterData && relationMasterData.length > 0 ?
                                            relationMasterData && relationMasterData.map((relation, index) => {
                                                // //console.log("relationInFormFieldValue",relation);
                                                if ("relation_user_id" in relation) {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[
                                                                i18n.language
                                                                ] &&
                                                                relation.relation_master_id[
                                                                    i18n.language
                                                                ].name
                                                                //  +  " " +t("Name")
                                                            }
                                                            name={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[
                                                                i18n.language
                                                                ] &&
                                                                `relation_id_en_${relation._id}_${relation.relation_master_id[
                                                                    i18n.language
                                                                ].name
                                                                }`
                                                            }
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.en &&
                                                                    relation.prefix_id.en.name
                                                                }
                                                                defaultValue={
                                                                    (relation &&
                                                                        relation.relation_user_id &&
                                                                        relation.relation_user_id.en &&
                                                                        relation.relation_user_id.en
                                                                            .name !== "") || null
                                                                        ? (relation &&
                                                                            relation.relation_user_id &&
                                                                            relation.relation_user_id.en &&
                                                                            relation.relation_user_id.en.name
                                                                            + " " +
                                                                            relation.relation_user_id.en.surname
                                                                        )
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                } else {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[
                                                                i18n.language
                                                                ] &&
                                                                relation.relation_master_id[
                                                                    i18n.language
                                                                ].name
                                                                // + " " +   t("Name")
                                                            }
                                                            name={
                                                                "static_info_en" +
                                                                `${relation && relation._id}`
                                                            }
                                                            rules={[
                                                                {
                                                                    pattern: new RegExp(
                                                                        /^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/
                                                                    ),
                                                                    message:
                                                                        "field only accept string value(should be More than 1 character)!",
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.en &&
                                                                    relation.prefix_id.en.name
                                                                }
                                                                defaultValue={relation?.static_info?.en?.name !== '' || null ?
                                                                    relation?.static_info?.en?.name : '-'
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                }
                                                // } else {
                                                //     if ("relation_user_id" in relation) {
                                                //         return <Form.Item label={relation && relation.relation_master_id && relation.relation_master_id[i18n.language] && relation.relation_master_id[i18n.language].name + " " + t("Name")}
                                                //             name={relation && relation.relation_master_id && relation.relation_master_id[i18n.language] && relation.relation_master_id[i18n.language].name}
                                                //         >
                                                //             <Input size='small'
                                                //                 addonBefore={relation && relation.prefix_id && relation.prefix_id.en && relation.prefix_id.en.name}
                                                //                 defaultValue={(relation && relation.relation_user_id && relation.relation_user_id.hn && relation.relation_user_id.hn.name !== "" || null) ?
                                                //                     relation && relation.relation_user_id && relation.relation_user_id.hn && relation.relation_user_id.hn.name + " " + relation.relation_user_id.hn.surname
                                                //                     : '-'}
                                                //             />
                                                //         </Form.Item>
                                                //     } else {
                                                //         return (
                                                //             <Form.Item
                                                //                 label={relation && relation.relation_master_id && relation.relation_master_id[i18n.language] && relation.relation_master_id[i18n.language].name + " " + t("Name")}
                                                //                 name={relation && relation.relation_master_id && relation.relation_master_id[i18n.language] && relation.relation_master_id[i18n.language].name}
                                                //                 rules={[
                                                //                     {
                                                //                         pattern: new RegExp(/^[^\s].*([\u0900-\u097FA-Za-z]+\s)*[\u0900-\u097FA-Za-z]+$/),
                                                //                         message: "field only accept string value(should be More than 1 character)!",
                                                //                     },
                                                //                 ]}
                                                //             >
                                                //                 <Input size='small'
                                                //                     addonBefore={relation && relation.prefix_id && relation.prefix_id.en && relation.prefix_id.en.name}
                                                //                     defaultValue={relation && relation.static_info && relation.static_info.hn && relation.static_info.hn.name}
                                                //                 />
                                                //             </Form.Item>
                                                //         )
                                                //     }
                                                // }
                                            })
                                            : " "}

                                        <Form.Item
                                            label={t("Working_At")}
                                            name="en_current_working_at"
                                        >
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("Hobbies")}
                                            name="en_hobbies"
                                        >
                                            <Input size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="en_currentAddress"
                                            label={t("Current_Address")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="en_currentLocation"
                                            label={t("Current_location")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="en_nanihalAddress"
                                            label={t("nanihal_Address")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="en_sasuralAddress"
                                            label={t("sasural_Address")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="en_nativeLocation"
                                            label={t("Native_location")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            name="en_postal_address"
                                            label={t("Postal_Address")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>

                                        <Form.Item label={t("About_Me")} name="en_about_me">
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>

                            <Col xxl={12} xs={12} sm={12} md={12} lg={12}>
                                <div className="Verification_form">
                                    <div className="right_form">
                                        <Form.Item label={t("Language")} name="hn_lang">
                                            <Input size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("User_Profile")}
                                            valuePropName="fileList"
                                            name="profile_photo"
                                        >
                                            {prop.target.profile_photo !== "" ? (
                                                <img
                                                    src={`${prop.target.profile_photo}`}
                                                    width="60"
                                                    height="60"
                                                    className="addImg"
                                                    style={{ border: "1px solid black" }}
                                                    crossOrigin="anonymous"
                                                />
                                            ) : (
                                                <Avatar
                                                    icon={<UserOutlined />}
                                                    shape="square"
                                                    style={{ backgroundColor: getRandomColor() }}
                                                    size={64}
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            label={t("UserName")}
                                            name="hn_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter User Name!",
                                                },
                                            ]}
                                        >
                                            <Input size="small" ref={myRef} />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("Surname")}
                                            name="hn_surname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Enter Surname!",
                                                },
                                            ]}
                                        >
                                            <Input size="small" />
                                        </Form.Item>

                                        {relationMasterData && relationMasterData.length > 0
                                            ? relationMasterData &&
                                            relationMasterData.map((relation, index) => {
                                                if (
                                                    Object.keys(relation).includes(
                                                        "relation_user_id"
                                                    ) === true
                                                ) {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[
                                                                i18n.language
                                                                ] &&
                                                                relation.relation_master_id[
                                                                    i18n.language
                                                                ].name
                                                                // + " " +t("Name")
                                                            }
                                                            name={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[
                                                                i18n.language
                                                                ] &&
                                                                `relation_id_hn_${relation._id}_${relation.relation_master_id[
                                                                    i18n.language
                                                                ].name
                                                                }`
                                                            }
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.hn &&
                                                                    relation.prefix_id.hn.name
                                                                }
                                                                defaultValue={relation?.relation_user_id?.hn?.name !== "" || null
                                                                    ? (relation?.relation_user_id?.hn?.name
                                                                        + " " +
                                                                        relation?.relation_user_id?.hn?.surname
                                                                    ) :
                                                                    '-'
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                } else {
                                                    return (
                                                        <Form.Item
                                                            label={
                                                                relation &&
                                                                relation.relation_master_id &&
                                                                relation.relation_master_id[
                                                                i18n.language
                                                                ] &&
                                                                relation.relation_master_id[
                                                                    i18n.language
                                                                ].name
                                                                //  + " " +t("Name")
                                                            }
                                                            name={
                                                                "static_info_hn" +
                                                                `${relation && relation._id}`
                                                            }
                                                        >
                                                            <Input
                                                                size="small"
                                                                addonBefore={
                                                                    relation &&
                                                                    relation.prefix_id &&
                                                                    relation.prefix_id.hn &&
                                                                    relation.prefix_id.hn.name
                                                                }
                                                                defaultValue={
                                                                    relation?.static_info?.hn?.name
                                                                        ? relation?.static_info?.hn?.name
                                                                        : "-"
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                }
                                            })
                                            : " "}

                                        <Form.Item
                                            label={t("Working_At")}
                                            name="hn_current_working_at"
                                        >
                                            <Input size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("Hobbies")}
                                            name="hn_hobbies"
                                        >
                                            <Input size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            name="hn_currentAddress"
                                            label={t("Current_Address")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="hn_currentLocation"
                                            label={t("Current_location")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="hn_nanihalAddress"
                                            label={t("nanihal_Address")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="hn_sasuralAddress"
                                            label={t("sasural_Address")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                        <Form.Item
                                            name="hn_nativeLocation"
                                            label={t("Native_location")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>

                                        <Form.Item
                                            name="hn_postalAddress"
                                            label={t("Postal_Address")}
                                        >
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>

                                        <Form.Item label={t("About_Me")} name="hn_about_me">
                                            <TextArea rows={1} size="small" />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            className="bottom_fields"
                            style={{ justifyContent: "center" }}
                        >
                            <Col xs={24} sm={20} md={12} lg={12}>
                                <Form.Item
                                    label={t("Email")}
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Email ID*!",
                                        },
                                    ]}
                                >
                                    <Input size="small" />
                                </Form.Item>

                                <Form.Item
                                    label={t("Marital_Status")}
                                    name="marital_status"
                                >
                                    <Select>
                                        {new Array(
                                            DataOfMaritalStatus && DataOfMaritalStatus.length
                                        )
                                            .fill(DataOfMaritalStatus && DataOfMaritalStatus)
                                            .map((val, index) => {
                                                return prop.target.en.marital_status_id !== "" ||
                                                    null ||
                                                    undefined ? (
                                                    <Select.Option
                                                        value={val && val[index] && val[index]._id}
                                                    >
                                                        {val &&
                                                            val[index] &&
                                                            val[index][i18n.language] &&
                                                            val[index][i18n.language].marital_status}
                                                    </Select.Option>
                                                ) : (
                                                    "-"
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                                {/* 
                                        <Form.Item label={t('Birth_Date')} name='birthdate'>
                                            <Input size='small'
                                                // defaultValue={usersRelations && usersRelations.date_of_birth && getDateFormate(usersRelations.date_of_birth)} 
                                                // defaultValue={moment(usersRelations && usersRelations.date_of_birth).format("DD/MM/YYYY")} 
                                                />
                                        </Form.Item> */}

                                <Form.Item
                                    name="phone_number"
                                    label={t("Contact_Number")}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter contact number*!",
                                        },
                                        {
                                            pattern: new RegExp(/^[0-9]+$/),
                                            message:
                                                "field only accept numbers (No space allowed!!*)",
                                        },
                                        {
                                            max: 11,
                                            message: "Maximum 10 Numbers are allowed!",
                                        },
                                    ]}
                                >
                                    <Input size="small" />
                                </Form.Item>

                                <Form.Item label={t("Gender")} name="gender">
                                    <Select
                                        placeholder={t("Select_Gender")}
                                        showSearch
                                        allowClear
                                        placement="bottomRight"
                                        style={{ width: "100%" }}
                                        notFoundContent={t("This_Gender_is_Not_Exist")}
                                        filterOption={(input, option) => {
                                            return (
                                                option.props.children[1]
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                    >
                                        {getdataGender && getdataGender.length > 0
                                            ? getdataGender.map((data) => {
                                                return (
                                                    <Select.Option key={data?._id} value={data?._id}>
                                                        {`${data[i18n.language].name}`}
                                                    </Select.Option>
                                                );
                                            })
                                            : "-"}
                                    </Select>
                                </Form.Item>

                                <Form.Item label={t("Blood_Group")} name="blood_group">
                                    <Select
                                        mode="single"
                                        placeholder={t("Select_BloodGroup")}
                                        showSearch
                                        allowClear
                                        placement="bottomRight"
                                        style={{ width: "100%" }}
                                        notFoundContent={t("This_BloodGroup_is_Not_Exist")}
                                        optionFilterProp="children"
                                    >
                                        {new Array(
                                            getBloodGroupData && getBloodGroupData.length
                                        )
                                            .fill(getBloodGroupData && getBloodGroupData)
                                            .map((val, index) => {
                                                return (
                                                    <Select.Option
                                                        value={val && val[index] && val[index]._id}
                                                    >
                                                        {val &&
                                                            val[index] &&
                                                            val[index][i18n.language] &&
                                                            val[index][i18n.language].blood_group}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>

                                <Form.Item label={t("Gotras")} name="gotra_master">
                                    <Select
                                        mode="single"
                                        placeholder={t("Select_Gotra")}
                                        showSearch
                                        allowClear
                                        placement="bottomRight"
                                        style={{ width: "100%" }}
                                        notFoundContent={t("This_Gotra_is_Not_Exist")}
                                        optionFilterProp="children"
                                    >
                                        {new Array(dataGotra && dataGotra.length)
                                            .fill(dataGotra && dataGotra)
                                            .map((val, index) => {
                                                return (
                                                    <Select.Option
                                                        value={val && val[index] && val[index]._id}
                                                    >
                                                        {(val &&
                                                            val[index] &&
                                                            val[index][i18n.language] &&
                                                            val[index][i18n.language].name) == "" ||
                                                            undefined
                                                            ? ""
                                                            : val &&
                                                            val[index] &&
                                                            val[index][i18n.language] &&
                                                            val[index][i18n.language].name}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={t("Education")} name="education_master">
                                    <Select
                                        mode="single"
                                        placeholder={t("Select_Education")}
                                        showSearch
                                        allowClear
                                        placement="bottomRight"
                                        style={{ width: "100%" }}
                                        notFoundContent={t("This_Education_is_Not_Exist")}
                                        optionFilterProp="children"
                                    >
                                        {new Array(getdataEducation && getdataEducation.length)
                                            .fill(getdataEducation && getdataEducation)
                                            .map((val, index) => {
                                                return (
                                                    <Select.Option
                                                        value={val && val[index] && val[index]._id}
                                                    >
                                                        {(val &&
                                                            val[index] &&
                                                            val[index][i18n.language] &&
                                                            val[index][i18n.language].education) == "" ||
                                                            undefined
                                                            ? ""
                                                            : val &&
                                                            val[index] &&
                                                            val[index][i18n.language] &&
                                                            val[index][i18n.language].education}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={t("Occupation")}
                                    // initialValue={selectedOccupation}
                                    name="occupation"
                                >

                                    <Select
                                        mode="multiple"
                                        placeholder={t("Select_Occupation")}
                                        showSearch
                                        allowClear
                                        placement="bottomRight"
                                        style={{ width: "100%" }}
                                        notFoundContent={t("This_Occupation_is_Not_Exist")}
                                        optionFilterProp="children"
                                        defaultValue={selectedOccupation}
                                        value={selectedOccupation}
                                        onChange={setSelectedOccupation}
                                    >
                                        {getOccupationData && getOccupationData.length > 0
                                            ? getOccupationData.map((data) => {
                                                return (
                                                    <Select.Option key={data._id} value={data._id}>
                                                        {(data &&
                                                            data &&
                                                            data[i18n.language]?.occupation !== "") ||
                                                            undefined ||
                                                            null
                                                            ? data &&
                                                            data[i18n.language] &&
                                                            data[i18n.language].occupation
                                                            : "-"}
                                                    </Select.Option>
                                                );
                                            })
                                            : "-"}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
        </Modal >
    )
}

export default VerifiedUserEditModal;