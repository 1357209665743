import actions from './actions';
import { message } from 'antd';
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const { getEducation, addEducation, updateEducation } = actions;


export const Get_Education_Data = (params) => {
    let queMark = "?";
    let equalString = "=";
    let ampersand = "&";
    Object.keys(params).map((a) => {
        queMark += a + equalString + params[a] + ampersand
    })

    return async (dispatch) => {
        const getData = await DataService.get(API.Education.Get_Education + queMark);
        if (!getData.data.error) {
            dispatch(getEducation(getData?.data))
            return true
        } else {
            message.error(getData.data.message);
            return false;
        }
    };
};

export const Add_Education_Data = (payload) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.Education.Add_Education, payload);
        if (!resp.data.error) {
            dispatch(addEducation(resp.data.data))
            return true

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

export const Update_Education_data = (payload, id) => {
    return async (dispatch) => {
        const resp = await DataService.put(API.Education.Update_Education + id, payload);
        if (!resp.data.error) {
            dispatch(updateEducation(resp.data.data))
            return true

        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

