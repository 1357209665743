import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { PageHeader } from "antd";
import { Table, Row, Tooltip, Col, Modal, Form, Input, Pagination, Empty, Button, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./style.css";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { bloodGroup, Add_Blood_Group, Update_Blood_Group, Delete_Blood_Group } from "../../redux/bloodGroup/actionCreator";
import { useDispatch, useSelector } from "react-redux";

const BloodGroups = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState(t("Add_Blood_Group"));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateID, setupdateID, updateIdRef] = useStateRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const dispatch = useDispatch();

    const getBloodGroupListData = useSelector((state) => state.BloodGroupReducer.bloodGroup);
    const getBloodGroupData = getBloodGroupListData && getBloodGroupListData?.data?.list;
    const BloodGroupPagination = getBloodGroupListData && getBloodGroupListData?.data;

    useEffect(
        () => {
            getBloodGroupdata(currentPage);
        },
        [dataPerPage],
        []
    );

    const getBloodGroupdata = (currentPage, pageNumber) => {
        let params = {
            search: "",
            page: currentPage,
            limit: dataPerPage,
        };
        dispatch(bloodGroup(params));
    };

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        getBloodGroupdata(page);
        setDataPerPage(pageNumber);
    };

    const showModal = () => {
        setIsModalOpen(true);
        setupdateID("");
        setModalTitle(t("Add_Blood_Group"));
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };
    const showEditModal = (e) => {
        setupdateID(e._id);
        setIsModalOpen(true);
        setModalTitle(t("Edit_Blood_Group"));
        form.setFieldsValue({
            en_Blood_Group: e.en.blood_group,
            hn_Blood_Group: e.hn.blood_group,
        });
    };

    const deleteBloodGroupData = async (value) => {
        onDelete(value);
    };

    const onDelete = async (values) => {
        let a = await dispatch(Delete_Blood_Group(values));
        if (a) {
            getBloodGroupdata();
        }
    };

    const handleSubmit = async (value) => {
        setIsModalOpen(false);
        form.resetFields();

        const obj = {
            en: {},
            hn: {},
        };
        obj.en.blood_group = value.en_Blood_Group
        obj.hn.blood_group = value.hn_Blood_Group
        if (updateIdRef.current === "") {
            const AddBloodGroupData = await dispatch(Add_Blood_Group(obj));
            if (AddBloodGroupData) {
                getBloodGroupdata();
            }
        } else {
            const obj = {
                en: {},
                hn: {},
            };
            (obj.en.blood_group = value.en_Blood_Group), (obj.hn.blood_group = value.hn_Blood_Group);
            const updateBloodGroupData = await dispatch(Update_Blood_Group(obj, updateIdRef.current));
            if (updateBloodGroupData) {
                getBloodGroupdata(currentPage, dataPerPage);
                setupdateID("");
            }
        }
    };

    const columns = [
        {
            title: t("Blood_Group"),
            dataIndex: "blood_group",
            align: "center",
            sorter: (a, b) => {
                return a.en.blood_group.localeCompare(b.en.blood_group);
            },
            render: (icon, value) => {
                return <>{value && value[i18n.language] && value[i18n.language].blood_group}</>;
            },
        },
        {
            title: t("Actions"),
            dataIndex: "Action",
            align: "center",
            render: (r, t) => {
                return (
                    <>
                        <Tooltip placement="right">
                            <FeatherIcon
                                icon="edit"
                                size="20px"
                                color="#5e6ff8"
                                onClick={() => {
                                    showEditModal(t), setIsModalOpen(true);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    let locale = {
        emptyText: (
            <>
                <Empty />
            </>
        ),
    };

    return (
        <>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    className="site-page-header"
                    title={t("Blood_Group")}
                    extra={[
                        <Button key="1" type="primary" onClick={showModal}>
                            <PlusOutlined /> {t("Add_Blood_Group")}
                        </Button>,
                    ]}
                />
            </div>

            <div>
                <Modal
                    open={isModalOpen}
                    okText={t("Submit")}
                    //  onOk={handleOk}
                    cancelText={t("Cancel")}
                    onCancel={handleCancel}
                    title={modalTitle}
                    onOk={form.submit}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form layout="vertical" onFinish={handleSubmit} form={form} size="small">
                                <Form.Item
                                    label={t("Enter_Blood_Group_In_English")}
                                    name="en_Blood_Group"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[a-zA-Z ./{}()-]+$/i),
                                            message: "Only Eng string value*! eg : A-Positive",
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Blood_Group")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("Enter_Blood_Group_In_Hindi")}
                                    name="hn_Blood_Group"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("This_Field_Is_Required"),
                                        },
                                        {
                                            pattern: new RegExp(/^(?!\s)[\u0900-\u097F ./{}()-]+$/i),
                                            message: "Only Hindi String value*! eg : ए-पॉजिटिव",
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("Enter_Blood_Group")} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <Row>
                    <Col xs={24} sm={24} md={24}>
                        {getBloodGroupData && getBloodGroupData.length > 0 ? (
                            <>
                                <div className="master_table">
                                    <Table
                                        showSorterTooltip={false}
                                        scroll={{
                                            x: "3vh",
                                        }}
                                        columns={columns}
                                        dataSource={getBloodGroupData}
                                        pagination={false}
                                    />
                                </div>
                                <Row className="pagination_Layout" justify="center">
                                    <Col xs={24} sm={24} md={24} className="usersList_pagination">
                                        <Pagination
                                            total={BloodGroupPagination.total_counts}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                            pageSize={dataPerPage}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            showSizeChanger
                                            currentPage={currentPage}
                                            onChange={handlePagination}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : Object.keys(getBloodGroupListData)?.length == 0 || getBloodGroupData?.length === 0 ? (
                            <div className="master_table">
                                <Table
                                    showSorterTooltip={false}
                                    scroll={{
                                        x: "3vh",
                                    }}
                                    locale={locale}
                                    columns={columns}
                                    dataSource={getBloodGroupData}
                                    pagination={false}
                                />
                            </div>
                        ) : (
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="spinnerName">
                                        <Spin size="large"></Spin>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default BloodGroups;
