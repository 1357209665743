import actions from './actions';
import { message } from 'antd';
// import { object } from 'prop-types';
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getRelationshipCombination, addRelationshipCombination, updateRelationshipCombination } = actions;


export const relationshipCombination = (params) => {
    let queMark = "?";
    let equalString = "=";
    let ampersand = "&"
    Object.keys(params).map((a) => {
        queMark += a + equalString + params[a] + ampersand
    })
    return async (dispatch) => {
        const getRelation = await DataService.get(API.relationShip_Combination.getRelationship_Combination + queMark);
        if (!getRelation.data.error) {
            dispatch(getRelationshipCombination(getRelation?.data))
            return true
        } else {
            message.error(getRelation.data.message);
            return false;
        }
    };
};
export const add_Relationship_Combination = (payload) => {
    return async dispatch => {
        const addRelationResp = await DataService.post(API.relationShip_Combination.addRelationship_combination, payload);
        if (!addRelationResp.data.error) {
            dispatch(addRelationshipCombination(addRelationResp.data.data))
            return true
        } else {
            message.error(addRelationResp.data.message);
            return false;
        }
    };
};

export const UPDATE_RELATIONSHIP_COMBINATION = (payload, id) => {
    return async dispatch => {
        const UpdateRelationResp = await DataService.put(API.relationShip_Combination.updateRelationship_Combination + id, payload);
        if (!UpdateRelationResp.data.error) {
            dispatch(updateRelationshipCombination(UpdateRelationResp.data.data))
            return true
        } else {
            message.error(UpdateRelationResp.data.message);
            return false;
        }
    };
};

// export const Delete_User_Relationship = (id) => {
//     return async dispatch => {
//         console.log("delete payload & Id", id)
//         const del_userRelation_resp = await DataService.delete(API. + id);
//         console.log("del_userRelation_resp::: ", del_userRelation_resp);
//         if (!del_userRelation_resp.data.error) {
//             return dispatch(deleteUserRelationship(true))

//         } else {
//             message.error(del_userRelation_resp.data.data);
//             return false;
//         }

//     };
// };