import Cookies from 'js-cookie';
import actions from './actions';
const { GET_RELATIONSHIP,ADD_RELATIONSHIP,UPDATE_RELATIONSHIP,DELETE_RELATIONSHIP} = actions;

const initState = {
 relationship:{},
 addRelationship:{}
};
const RelationShipMasterReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) { 
    case GET_RELATIONSHIP:
      return {
        ...state,
        relationship:data,
      };
    case ADD_RELATIONSHIP:
      return {
        ...state,
        addRelationship:data,
      };
    case UPDATE_RELATIONSHIP:
      return {
        ...state,
        addRelationship:data,
      };
    case DELETE_RELATIONSHIP:
      return {
        ...state,
        relationship:data,
      };
   
    default:
      return state;
  }
};
export default RelationShipMasterReducer;
